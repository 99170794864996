// solution from - https://codereview.stackexchange.com/questions/173978/javascript-of-property-path-in-object
export const findPropPaths = (
  obj: Record<string, any>,
  predicate: (key: string, path?: string[], obj?: Record<string, any>) => boolean
) => {
  const discoveredObjects: Record<string, any>[] = [];
  const path = [];
  const results = [];

  if (!obj && (typeof obj !== 'object' || Array.isArray(obj))) {
    throw new TypeError('First argument of finPropPath is not the correct type Object');
  }
  if (typeof predicate !== 'function') {
    throw new TypeError('Predicate is not a function');
  }

  (function find(obj) {
    for (const key of Object.keys(obj)) {
      if (predicate(key, path, obj) === true) {
        path.push(key);
        results.push(path.join('.'));
        path.pop();
      }

      const o = obj[key];

      if (o && typeof o === 'object' && ! Array.isArray(o)) {
        if (! discoveredObjects.find(obj => obj === o)) {
          path.push(key);
          discoveredObjects.push(o);
          find(o);
          path.pop();
        }
      }
    }
  }(obj));

  return results[0];
};
