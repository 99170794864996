import {
  TAddress,
  TCustomerContact,
  TError,
  TParticipantAdd,
  TParticipantAddress,
  TQuoteParticipantRole
} from 'models';
import { DEFAULT_RESIDENT_COUNTRY_CODE } from 'config/api';

export const handleChangeParticipant = (
  form: TParticipantAdd,
  updateErrors: (errors: TError[]) => void,
  updateIsTouched: (value: boolean) => void,
  updateForm: (form: TParticipantAdd) => void,
  key?: string,
  value?: string | number | boolean | string[] | TCustomerContact[] | TAddress['props'][] | TParticipantAddress | TQuoteParticipantRole[]
) => {
  if (key) {
    updateErrors([]);
    updateIsTouched(true);

    if (key === 'pkdCode' && typeof value === 'string') {
      value = value.split('.').join('');
    }

    const touchedForm = {
      ...form,
      [key]: value,
    };

    if (key === 'resident') {
      if (value) {
        touchedForm.countryCode = DEFAULT_RESIDENT_COUNTRY_CODE;
      } else {
        touchedForm.countryCode = undefined;
      }
    }

    updateForm(touchedForm);
  }
};
