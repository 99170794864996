import { TCustomer, TCustomerType, TFetchStatus, TParticipantType, TQuotePolicyHolder } from 'models';
import { useApp } from 'context/App';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { createTempId, errorNotification, getCustomerName, makeRequestQuery, preflightAPICall, translate } from 'utils';
import axios from 'axios';
import { API_CUSTOMER_URL } from 'config/api';
import {
  CloseIcon,
  ParticipantsSearchResultsDrop,
  SearchIcon,
  SearchInput,
  SearchResultsFailedItem,
  SearchResultsIcon,
  SearchResultsItemData,
  SearchResultsItemDataAdditional,
  SearchResultsItemDataTitle,
  SearchResultsItemGroupHeaderTitle,
  SearchSpinner,
  SearchWrapper
} from './styles';
import { DropItem } from 'styles/common';
import { highlight } from 'helpers/highlight';
import { QuoteContext } from 'context/Quote';
import { RESIDENT_INSURANCE_PRODUCTS } from 'config/consts';

const RESULTS_SIZE = 5;

const TIMEOUT_DELAY = 400;
let timeout: ReturnType<typeof setTimeout>;

export const ParticipantsSearch = ({ type, commonSearchPlaceholder, handleAddCustomer, customerType, disabled }: {type: TParticipantType, commonSearchPlaceholder?: string, handleAddCustomer: (participant: TQuotePolicyHolder) => void, customerType?: TCustomerType, disabled?: boolean }) => {
  const { showNotification } = useApp();
  const { quoteType } = useContext(QuoteContext);

  const textInput = useRef<HTMLInputElement>(null);
  const searchWrapper = useRef<HTMLDivElement>(null);
  const [inputValue, onChangeInput] = useState('');
  const [dataStatus, onChangeDataStatus] = useState<TFetchStatus>(null);
  const [searchResults, updateSearchResults] = useState([]);

  useEffect(() => {
    window.addEventListener('click', closeDrop);

    return () => {
      window.removeEventListener('click', closeDrop);
    };
  });

  const closeDrop = (event: MouseEvent) => {
    if (searchWrapper) {
      let node = event.target as Element;
      // eslint-disable-next-line eqeqeq
      while (node != null && node.tagName != 'HTML') {
        if (node === searchWrapper.current) {
          return true;
        }
        node = node.parentNode as Element;
      }
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        onChangeDataStatus(null);
        onChangeInput('');
      }, 100);
    }
  };

  const onChangeInputValue = (value: string): void => {
    onChangeInput(value);
    clearTimeout(timeout);
    if (value && value !== '') {
      timeout = setTimeout(() => { handleSearchCall(value); }, TIMEOUT_DELAY);
    } else {
      onChangeDataStatus(null);
    }
  };

  const handleSearchCall = (value: string) => {
    onChangeDataStatus('loading');
    let query: Record<string, string | number> = {};

    if (quoteType && RESIDENT_INSURANCE_PRODUCTS.includes(quoteType)) {
      query['fields[props.resident]'] = 'true';
    }

    query.page_size = RESULTS_SIZE;
    query.search = value;

    if (customerType) {
      query['fields[type]'] = customerType;
    }

    preflightAPICall(() => {
      axios.get(`${API_CUSTOMER_URL}${makeRequestQuery(query)}`).then(response => {
        const results = response.data?.results;
        updateSearchResults(results || []);
        onChangeDataStatus('success');
      }).catch(error => {
        updateSearchResults([]);
        onChangeDataStatus('failed');
        errorNotification(error, showNotification);
      });
    });

  };

  const handleInputClick = () => {
    if (textInput.current !== null) {
      textInput.current.focus();
    }
  };

  const onResultClick = (customer: TCustomer) => {
    onChangeDataStatus(null);
    onChangeInput('');
    handleAddCustomer({ id: createTempId(), roles: [], customer, type });
  };

  return (
    <SearchWrapper ref={searchWrapper}>
      <SearchIcon icon="search_spaced" onClick={handleInputClick} />
      <SearchInput
        disabled={disabled}
        placeholder={commonSearchPlaceholder || translate({ key: 'quote_motor.participants.search_profile' })}
        type="search"
        value={inputValue}
        onChange={(event) => onChangeInputValue(event.target.value)}
        ref={textInput}
      />
      {(inputValue?.length && dataStatus !== 'loading') ? <CloseIcon
        onClick={() => onChangeInputValue('')}
        icon="close"
      /> : null}
      {dataStatus === 'loading' && <SearchSpinner />}
      {dataStatus === 'success' && <SearchResults items={searchResults} inputValue={inputValue} onResultClick={onResultClick} />}
    </SearchWrapper>
  );
};

const SearchResults = ({
  items,
  inputValue,
  onResultClick
}: {
  items: TCustomer[],
  inputValue: string,
  onResultClick: (customer: TCustomer) => void,
}) => (
  <ParticipantsSearchResultsDrop>

    {!!items?.length ? items.map((item) => (
      <DropItem key={item.id} onClick={() => onResultClick(item)}>
        {item.type === 'individual' ? renderIndividualCustomer(item, inputValue) : renderCompanyCustomer(item, inputValue)}
      </DropItem>
    )) : (
      <SearchResultsFailedItem>
        <SearchResultsIcon icon="placeholder" margin="right" />
        <SearchResultsItemGroupHeaderTitle>{translate({ key: 'common.no_results' })}</SearchResultsItemGroupHeaderTitle>
      </SearchResultsFailedItem>
    )}

  </ParticipantsSearchResultsDrop>
);


const renderIndividualCustomer = (item: TCustomer, inputValue: string) => (
  <SearchResultsItemData>
    <SearchResultsItemDataTitle>
      {item.props.first_name} {item.props.last_name}
    </SearchResultsItemDataTitle>
    <SearchResultsItemDataAdditional key={`data-string-pesel`}>
      {typeof item.props.resident === 'boolean' && !item.props.resident ? `${translate({ key: 'customer.idCode' })}:` : `${translate({ key: 'customer.pesel' })}:`}
      &nbsp;
      <span
        dangerouslySetInnerHTML={{
          __html: highlight(typeof item.props.resident === 'boolean' && !item.props.resident ? item.props.idCode : item.props.pesel, inputValue)
        }}
      />
    </SearchResultsItemDataAdditional>
    {item.contacts?.length ? item.contacts.map((item) => (
      <SearchResultsItemDataAdditional key={`data-string-${item.id}`}>
        <span
          dangerouslySetInnerHTML={{
            __html: highlight(item.value, inputValue)
          }}
        />
      </SearchResultsItemDataAdditional>
    )) : null}
  </SearchResultsItemData>
);

const renderCompanyCustomer = (item: TCustomer, inputValue: string) => (
  <SearchResultsItemData>
    <SearchResultsItemDataTitle>
      {getCustomerName(item)}
    </SearchResultsItemDataTitle>
    {item.props.pesel ? (
      <SearchResultsItemDataAdditional key={`data-string-pesel`}>
        PESEL:&nbsp;
        <span
          dangerouslySetInnerHTML={{
            __html: highlight(item.props.pesel, inputValue)
          }}
        />
      </SearchResultsItemDataAdditional>
    ) : null}
    {item.props.regon ? (
      <SearchResultsItemDataAdditional key={`data-string-regon`}>
        REGON:&nbsp;
        <span
          dangerouslySetInnerHTML={{
            __html: highlight(item.props.regon, inputValue)
          }}
        />
      </SearchResultsItemDataAdditional>
    ) : null}
    {item.props.nip ? (
      <SearchResultsItemDataAdditional key={`data-string-nip`}>
        NIP:&nbsp;
        <span
          dangerouslySetInnerHTML={{
            __html: highlight(item.props.nip, inputValue)
          }}
        />
      </SearchResultsItemDataAdditional>
    ) : null}
    {item.contacts?.length ? item.contacts.map((item) => (
      <SearchResultsItemDataAdditional key={`data-string-${item.id}`}>
        <span
          dangerouslySetInnerHTML={{
            __html: highlight(item.value, inputValue)
          }}
        />
      </SearchResultsItemDataAdditional>
    )) : null}
  </SearchResultsItemData>
);
