import React from 'react';
import { useApp } from 'context/App';
import { Dialog, Notification } from '@insly/qmt-reactjs-ui-lib';

const Misc = () => {
  let app = useApp();

  return <>
    <Dialog
      {...app.dialogProps}
      onAccept={() => {
        app.hideDialog(app.dialogProps.onAccept ? app.dialogProps.onAccept : () => false);
      }}
      onDecline={() => {
        app.hideDialog(app.dialogProps.onDecline ? app.dialogProps.onDecline : () => false);
      }}
    />
    <Notification
      {...app.notificationProps}
      icon={setNotificationIcon(app.notificationProps.preset)}
      onHide={app.hideNotification}
      styles={{
        zIndex: 1
      }}
    />
  </>;
};

const setNotificationIcon = (preset: string) => {
  switch (preset) {
    case 'success':
      return 'notification_success';
    case 'error':
      return 'notification_error';
  }
};

export default Misc;
