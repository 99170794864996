import styled from 'styled-components';
import { Button } from '@insly/qmt-reactjs-ui-lib';

export const DocumentsStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  width: 100%;
  max-width: 840px;
  padding: 60px 20px;
  gap: 16px;
  margin: 0 auto;
`;

export const UploaderDocumentsString = styled.div`
  ${({ theme }) => theme.text.normal};
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 24px;
  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.strokeB};
  }
`;

export const UploaderDocuments = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px dashed ${({ theme }) => theme.colors.strokeB};
  cursor: pointer;
  &.drag-over,
  &:hover {
    border-color: ${({ theme }) => theme.colors.textSecondary};
  }
`;

export const UploaderInput = styled.input`
  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 0;
  display: block;
  font-size: 0;
  line-height: 0;
`;

export const ButtonBack = styled(Button)`
  width: fit-content;
`;
