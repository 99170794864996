//https://en.wikipedia.org/wiki/PESEL
//http://jsfiddle.net/artpi/Ltb8h/

import { ymdToDbDate } from './dateTime';

export const isPeselValid = (pesel: string) => {
  if (pesel?.length !== 11) {
    return false;
  }

  const controlNumbers = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];

  let sum = controlNumbers.reduce((sum, current, index) => sum + (+(pesel.substring(index, index+1)) * current), 0);

  sum = sum % 10;

  return (sum === +(pesel.substring(10, 11)));

};

export const getGenderFromPesel = (pesel: string) => {
  if (!isPeselValid(pesel)) {
    return;
  }

  if (parseInt(pesel.substring(9, 10), 10) % 2 === 1) {
    return 'male';
  } else {
    return 'female';
  }

};

export const getDOBFromPesel = (pesel: string) => {
  if (!isPeselValid(pesel)) {
    return;
  }

  let year = +(pesel.substring(0, 2));
  let month = +(pesel.substring(2, 4));
  const date = +(pesel.substring(4, 6));

  if (isNaN(year) || isNaN(month) || isNaN(date)) {
    return;
  }

  switch (true) {
    case month > 80:
      year += 1800;
      month = month - 80;
      break;
    case month > 60:
      year += 2200;
      month = month - 60;
      break;
    case month > 40:
      year += 2100;
      month = month - 40;
      break;
    case month > 20:
      year += 2000;
      month = month - 20;
      break;
    default:
      year += 1900;
  }

  const dobString = ymdToDbDate(year, month, date);

  if (!dobString) {
    return;
  }

  const dobDate = new Date(dobString);

  if (dobDate.getFullYear() === year && dobDate.getDate() === date && dobDate.getMonth() === (month - 1)) {
    return dobString;
  } else {
    return;
  }


};
