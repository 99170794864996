import React from 'react';
import { JSONSchemaProp } from 'models';
import {
  DocumentsLinkText,
  RequiredMark,
  DocumentsSectionHeadline, DocumentsSectionList
} from 'utils/JSONSchemaFormBuilder/styles';
import { translate } from 'utils';
import { Checkbox } from '@insly/qmt-reactjs-ui-lib';
import { DOCUMENTS_STATEMENTS_NAME } from 'app/Pages/Quote/Profile/body/Issuing/consts';

const EUROINS_AGREEMENTS_DOCUMENTS_LINKS = [
  {
    key: 'quote_motor.issuing.agreements.documents.link_title.information_card',
    href: 'https://eins.pl/download/1420/',
  },
  {
    key: 'quote_motor.issuing.agreements.documents.link_title.product_card',
    href: 'https://eins.pl/download/1313/',
  },
  {
    key: 'quote_motor.issuing.agreements.documents.link_title.terms_conditions',
    href: 'https://eins.pl/download/1401/',
  },
];

const EUROINS_AGREEMENTS_DOCUMENTS_LINKS_ADDITIONAL = [
  {
    key: 'quote_motor.issuing.agreements.documents.link_title.voluntarily',
    href: 'https://eins.pl/download/449/',
  },
  {
    key: 'quote_motor.issuing.agreements.documents.link_title.general',
    href: 'https://eins.pl/download/1011/',
  },
];

interface IDocuments {
  prop: JSONSchemaProp,
  parentName?: string,
  handleFormChange: (name: string, value: any, parentName?: string) => void,
  form: Record<string, any>,
  otherThanMtplProducts?: boolean,
}

export const Documents = ({ prop, parentName, handleFormChange, form, otherThanMtplProducts }: IDocuments) => {
  let documents = [...EUROINS_AGREEMENTS_DOCUMENTS_LINKS];

  if (otherThanMtplProducts) {
    documents.push(...EUROINS_AGREEMENTS_DOCUMENTS_LINKS_ADDITIONAL);
  }

  return (
    <>
      <DocumentsSectionHeadline>
        {translate({ key: prop.title_translation_key, fallback: parentName })} {<RequiredMark />}
      </DocumentsSectionHeadline>
      <DocumentsSectionList>
        {documents.map(link => (
          <li>
            <DocumentsLinkText key={link.key}>
              <a href={link.href} target="_blank" rel="noreferrer">
                {translate({ key: link.key })}
              </a>
            </DocumentsLinkText>
          </li>
        ))}
      </DocumentsSectionList>
      {prop.enum?.map(item => (
        <Checkbox
          key={item}
          name={DOCUMENTS_STATEMENTS_NAME}
          label={translate({ key: prop.enum_translation_keys?.[item], fallback: item })}
          checked={form[DOCUMENTS_STATEMENTS_NAME] === item}
          handleChange={(key, value) => handleFormChange(key as string, item, parentName)}
        />
      ))}
    </>
  );
};
