import React, { createContext, useContext, useEffect, useState } from 'react';
import { clearAuthUserData, getQueryParam, isIframe, preflightAPICall } from 'utils';
import { TUserLocale } from '../models';
import { API_MASTER_URL_USER, DEFAULT_LOCALE } from '../config/api';
import axios from 'axios';

export type TUserProductGroup = {
  id: string,
  tag: string,
  title: string,
};

export interface IUserRole {
  id: string,
  name: string,
  group: {
    id: string,
    parent_id: string,
    name: string,
    title: string,
    domain_tag: string,
  },
  domain: {
    props: {
      agency: string,
      tenant: string,
    },
    meta: {
      agency: string,
      tenant: string,
    },
  },
}

type TUserProps = {
  communication_language: string[],
  email: string,
  name: string,
  role: string,
  payment_methods: Record<string, string[]>,
  idd_required?: Record<string, boolean>,
  custom_list_filters?: Record<string, string[]>,
  sorted_insurer_list?: string[],
  all_roles?: IUserRole[],
  gdpr_enabled?: boolean,
  gdpr_email_edit_content_preview?: boolean,
  idd_email_edit_content_preview?: boolean,
  default_packages?: Record<string, string>,
};

export interface IUserData {
  id: string,
  broker_id: string,
  group_id: string,
  props: TUserProps,
  product_groups?: TUserProductGroup[],
  insly3_oid: string,
  calcly_customer: {
    id: string,
    secret: string,
    token: string,
  },
  broker?: {
    id: string,
    tag: string,
    name: string,
    tenant: string,
    // "props": {},
    calcly_customer: {
      id: string,
      secret: string,
      token: string,
    },
  },
  masquerade: {
    masquerade_user_id: string,
    masquerade_user_props: TUserProps,
    all_roles?: IUserRole[],
  },
  role: IUserRole,
  all_roles?: IUserRole[],
}

export type TUserData = IUserData | null;

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
const AuthContext = createContext<{
  user: TUserData | null,
  isAuthenticated: number,
  setUser:(data: TUserData) => void,
  signin: (cb?: () => void) => void,
  signout: (cb?: () => void) => void,
  setLocale: (locale: string) => void,
  locale: TUserLocale,
}>({
      user: null,
      isAuthenticated: -1,
      setUser: (data: TUserData) => {},
      signin: (cb?: () => void) => {cb && cb();},
      signout: (cb?: () => void) => {cb && cb();},
      setLocale: (locale: string) => locale,
      locale: DEFAULT_LOCALE as TUserLocale,
    });

export const ProvideAuth = ({ children }: {children: JSX.Element}) => {
  const auth = useProvideAuth();
  return (
    // @ts-ignore
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

const useProvideAuth = () => {
  useEffect(() => {

    if (getQueryParam('resetPassword') || getQueryParam('logout')) {
      clearAuthUserData();
    }

    const userString = sessionStorage.getItem('user');

    if (userString) {
      preflightAPICall(() => {
        axios.get(`${API_MASTER_URL_USER}/info`).then(response => {

          if (response.data) {
            const user = response.data;

            if (!isIframe() && user.props.sync) {
              window.location.replace(user.props.sync);
              return;
            }
            setUser(user);
            setLoggedIn(1);
          } else {
            console.error(response);
          }

        }).catch(error => {
          console.error(error);
        });

      });

    } else {
      setLoggedIn(0);
    }

    //Check language
    if (localStorage.getItem('locale')) {
      updateLocale(localStorage.getItem('locale') as string);
    }

    // eslint-disable-next-line
  }, []);

  const [isAuthenticated, setLoggedIn] = useState(-1);
  const [locale, updateLocale] = useState(window._env_.DEFAULT_LOCALE);
  const [user, updateUser] = useState<TUserData>(null);

  const setUser = (data: TUserData) => {
    updateUser(data);
    sessionStorage.setItem('user', JSON.stringify(data));
  };

  const signin = (cb?: () => void) => {
    setLoggedIn(1);
    cb && cb();
  };

  const signout = (cb?: () => void) => {
    updateUser(null);
    setLoggedIn(0);
    clearAuthUserData();
    cb && cb();
  };

  const setLocale = (locale: string) => {
    localStorage.setItem('locale', locale);
    updateLocale(locale);
  };

  return {
    user,
    locale,
    signin,
    setUser,
    signout,
    setLocale,
    isAuthenticated,
  };
};
