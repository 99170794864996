import React, { useEffect, useState } from 'react';
import { SvgIcon, } from '@insly/qmt-reactjs-ui-lib';
import Search from './partials/Search';
import logoDefault from 'assets/images/logo.png';
import {
  ButtonPicker,
  HeaderDropdown,
  DropdownItemIcon,
  UserData,
  UserDataString,
  UserDataStringSecondary,
  UserDropDown,
  UserDropdownItemWrapper,
  UserDropdownItemLabel,
  ButtonAddNew,
  Tools,
  Logo,
  Header,
  Nav,
  UserDropdownItemSpan,
  Loader,
  NavLinkStyled,
  NavLinkIcon,
  NavLinkTitle,
  IconRemoveMasking
} from './styles';
import {
  TypeNotification,
} from 'models';
import { useHistory } from 'react-router-dom';
import { IUserData, TUserData, useAuth } from 'context/Auth';
import {
  errorNotification,
  logout,
  preflightAPICall,
  serverDateTimeFormatter,
  setDefaultQuoteStartDate,
  translate
} from 'utils';
import axios, { AxiosError } from 'axios';
import apiConfig, {
  getRequestStatus,
  API_QUOTE_FILTERED_BY_TYPE
} from 'config/api';
import { useApp } from 'context/App';
import { IDD_REQUIRED_STATUS, TQuoteType } from 'app/Pages/Quote/models';
import { INSURANCE_PRODUCT_MOTOR, INSURANCE_PRODUCTS_SORTED } from 'config/consts';
import { vsprintf } from 'sprintf-js';

const API_REMOVE_MASKING = `${apiConfig.MASTER}/admin/masquerade`;

const DEFAULT_CLASS_NAME = 'header';
const INDEX_PAGE = '/quotes/list';
const DEFAULT_COMPANY_NAME = 'Insly';
const INSLY3_NAV_KEY = 'insly3';

const NAV = [
  // {
  //   icon: 'nav_home',
  //   title: { key: 'nav.dashboard', fallback: 'Dashboard' },
  //   key: 'dashboard',
  // },
  {
    icon: 'file_text',
    title: { key: 'nav.idd', fallback: 'IDD' },
    key: 'idd',
    href: 'idd/list'
  },
  {
    icon: 'nav_clients',
    title: { key: 'nav.clients', fallback: 'Clients' },
    key: 'clients',
    href: 'clients/list'
  },
  {
    icon: 'nav_quote',
    title: { key: 'nav.quotes', fallback: 'Quotes' },
    key: 'quotes',
    href: 'quotes/list'
  },
  {
    icon: 'nav_quote',
    title: { key: 'nav.policies', fallback: 'Policies' },
    key: 'policy',
    href: 'policy/list'
  },
  {
    icon: 'file_text',
    title: { key: 'nav.insly3', fallback: 'CRM' },
    key: INSLY3_NAV_KEY,
    href: window._env_.INSLY3_HOMEPAGE
  },

];

export interface ITopBar {
  logo?: string,
  notifications?: TypeNotification[],
  user?: IUserData,
  companyName?: string,
  textNewQuote?: string,
}

export const HeaderAuthed = ({ logo = logoDefault, companyName = DEFAULT_COMPANY_NAME, textNewQuote, notifications = [], user }: ITopBar) => {
  const checkUnreadNotifications = (notifications: TypeNotification[]) => notifications.reduce((sum, { unread }) => (sum + (unread ? +unread : 0)), 0);
  const [localNotifications, updateLocalNotifications] = useState(notifications);
  const [unreadNotificationQuantity, updateUnreadNotificationQuantity] = useState(checkUnreadNotifications(notifications));

  useEffect(() => {
    updateUnreadNotificationQuantity(checkUnreadNotifications(notifications));
  }, [notifications]);

  //@TODO: Move notifications functionality to the Context API Scope
  const markAllNotificationsAsRead = () => {

    updateLocalNotifications(localNotifications.map(item => ({
      ...item,
      unread: false
    })));

    updateUnreadNotificationQuantity(0);
  };


  return (
    <Header className={DEFAULT_CLASS_NAME}>
      <Logo to={INDEX_PAGE} className={`${DEFAULT_CLASS_NAME}__logo`}>
        <img src={logo} alt={companyName} />
      </Logo>
      {renderNav(createNav(NAV, user?.broker?.tag))}
      {renderTools(user as TUserData, textNewQuote, localNotifications, unreadNotificationQuantity, markAllNotificationsAsRead)}
    </Header>
  );

};

const renderNav = (nav: JSX.Element[]): JSX.Element => (
  <Nav className={`${DEFAULT_CLASS_NAME}__nav`}>
    {nav && nav.map((item) => item)}
  </Nav>
);

const renderTools = (
  user: TUserData,
  textNewQuote: string | undefined,
  notifications: TypeNotification[],
  unreadNotificationQuantity: number,
  markAllAsRead: () => void
): JSX.Element => (
  <Tools className={`${DEFAULT_CLASS_NAME}__tools`}>
    <Search
      className={DEFAULT_CLASS_NAME}
    />
    <ButtonAddNewQuote
      textNewQuote={textNewQuote}
      iddRequired={user?.props?.idd_required}
    />
    {user && <UserDropdown user={user} />}
    {/*https://insly.atlassian.net/browse/INSLYPROD-6144*/}
    {/*<Notifications*/}
    {/*  notifications={notifications}*/}
    {/*  unreadNotificationQuantity={unreadNotificationQuantity}*/}
    {/*  markAllAsRead={markAllAsRead}*/}
    {/*/>*/}
  </Tools>
);

const ButtonAddNewQuote = ({
  textNewQuote,
  iddRequired
} : {
  textNewQuote: string | undefined,
  iddRequired?: Record<string, boolean>,
}) => {
  const [inProgress, setInProgress] = useState(false);
  let history = useHistory();
  const { showNotification } = useApp();
  const { user } = useAuth();

  const onError = (error: AxiosError) => {
    setInProgress(false);
    errorNotification(error, showNotification, false, 'quote');
  };

  const onButtonClick = (type: TQuoteType) => {
    setInProgress(true);

    const requestObject: Record<string, any> = {
      start_date: serverDateTimeFormatter(setDefaultQuoteStartDate(type)),
    };

    if (type === INSURANCE_PRODUCT_MOTOR) {
      requestObject.installments_no = 1;
    }

    if (iddRequired?.[type]) {
      requestObject.status = IDD_REQUIRED_STATUS;
    }

    preflightAPICall(() => {
      axios.post(setQuoteAPIUrl(type), requestObject).then(response => {
        getRequestStatus('quote', response.data.request_id, (data) => {
          const response: Record<string, never> = data.response;

          setInProgress(false);

          history.push(`/quotes/profile/${type}/${response.id}`, response);

        }, onError);
      }).catch(onError);
    });

  };

  const filteredInsuranceProducts = filterInsuranceProducts(user).map(type => (
    <CreateNewQuoteItem
      key={type}
      type={type}
      onButtonClick={onButtonClick}
    />
  ));

  return (
    <HeaderDropdown
      disabled={!filteredInsuranceProducts.length}
      picker={
        <ButtonAddNew
          className={`${DEFAULT_CLASS_NAME}__button-add-new testing__button-add-new-quote`}
          disabled={!filteredInsuranceProducts.length}
        >
          <>
            <span>{textNewQuote}</span>
            {inProgress ? (
              <Loader />
            ) : (
              <SvgIcon icon="add_new_calculation" />
            )}
          </>
        </ButtonAddNew>
      }
    >
      <UserDropDown>
        {filterInsuranceProducts(user).map(type => (
          <CreateNewQuoteItem
            key={type}
            type={type}
            onButtonClick={onButtonClick}
          />
        ))}
      </UserDropDown>
    </HeaderDropdown>
  );
};

const filterInsuranceProducts = (user: TUserData) => {
  const allowedProducts = user?.product_groups?.map(group => group.tag) || [];

  if (allowedProducts.length) {
    return INSURANCE_PRODUCTS_SORTED.filter(product => allowedProducts.includes(product));
  }

  return [];
};

const UserDropdown = ({
  user
} : {
  user: TUserData,
}) => {
  let history = useHistory();
  let auth = useAuth();

  return (
    <UserData className={`${DEFAULT_CLASS_NAME}__user`}>
      <div className="testing__user-data">
        <UserDataString>{user?.props.name} {user?.masquerade ? <Masquerade masquerade={user.masquerade} /> : null}</UserDataString>
        <UserDataStringSecondary>{user?.props.email}</UserDataStringSecondary>
      </div>
      <HeaderDropdown
        picker={
          <ButtonPicker className={`${DEFAULT_CLASS_NAME}__user-button testing__button-user-button`}>
            <SvgIcon icon="user" />
          </ButtonPicker>
        }
      >
        <UserDropDown>
          <UserDropdownItemWrapper>
            <UserDropdownItemSpan onClick={() => {
              auth.signout(() => {
                history.replace('/auth/login?logout=true');
              });
            }}>
              <DropdownItemIcon icon="signout" />
              <UserDropdownItemLabel>
                {translate({ key: 'auth.log_out' })}
              </UserDropdownItemLabel>
            </UserDropdownItemSpan>
          </UserDropdownItemWrapper>
        </UserDropDown>
      </HeaderDropdown>
    </UserData>
  );
};

const createNav = (items: { icon: string, title: { key: string, fallback?: string }, key: string, href: string }[], brokerTag?: string) => items.map(item => (
  <NavLinkStyled
    //@ts-ignore
    to={item.key !== INSLY3_NAV_KEY ? `/${item.href}` : undefined}
    as={item.key === INSLY3_NAV_KEY ? 'a' : undefined}
    href={item.key === INSLY3_NAV_KEY ? vsprintf(item.href, [brokerTag]): undefined}
    target={item.key === INSLY3_NAV_KEY ? '_blank' : undefined}
    key={`site-nav-${item.key}`}
    className={`nav-link testing__header-nav-item--${item.key}`}
    activeClassName="selected"
    isActive={(match, location) => location.pathname.indexOf(item.key) === 1}
  >
    <NavLinkIcon icon={item.icon} className="nav-link__icon" />
    <NavLinkTitle className="nav-link__title">{translate(item.title)}</NavLinkTitle>
  </NavLinkStyled>
));

const Masquerade = ({ masquerade } : { masquerade: IUserData['masquerade'] }) => (
  <>
    <div>
    (Masking: {masquerade.masquerade_user_props.name})
      <IconRemoveMasking
        icon="close"
        onClick={() => {
          preflightAPICall(() => {
            axios.delete(API_REMOVE_MASKING).then(() => {
              logout();
            }).catch(error => {
              console.error(error);
            });
          });
        }}
      />
    </div>
  </>
);

const setQuoteAPIUrl = (type: TQuoteType) => API_QUOTE_FILTERED_BY_TYPE.replace('%QUOTE_TYPE%', type);

const CreateNewQuoteItem = ({
  type,
  onButtonClick
} : {
  type: TQuoteType,
  onButtonClick: (type: TQuoteType) => void,
}) => (
  <UserDropdownItemWrapper>
    <UserDropdownItemSpan onClick={() => onButtonClick(type)}>
      <DropdownItemIcon icon={type} />
      <UserDropdownItemLabel>
        {translate({ key: `quote.create_${type}_quote` })}
      </UserDropdownItemLabel>
    </UserDropdownItemSpan>
  </UserDropdownItemWrapper>
);

export default HeaderAuthed;
