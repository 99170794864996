import styled from 'styled-components';

export const NotificationTypeIconWrapper = styled.div<{unread: boolean}>`
  position: relative;
  &:before {
    position: absolute;
    top: -1px;
    left: -1px;
    display: ${({ unread }) => unread ? 'block' : 'none'};
    content: '';
    width: 8px;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.white};
    transition: border-color 0.3s ease;
  }
`;

export const Badge = styled.div`
  position: absolute;
  top: 3px;
  right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  font: ${({ theme }) => theme.font.medium};
  font-size: 10px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  border: 1px solid #EDEDED;
  border-radius: 4px;
`;

export const NotificationsHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeB};
`;

export const NotificationsHeaderTitle = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.font.bold};
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-right: 16px;
`;

export const NotificationsMarkAsRead = styled.div<{ hasUnread?: boolean }>`
  display: flex;
  align-items: center;
  margin-left: auto;
  ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.colors.subtle};
  cursor: ${({ hasUnread }) => hasUnread ? 'pointer' : null};
`;

export const NotificationsHolder = styled.ul`
  margin-top: 8px;
`;

export const NotificationDropDown = styled.div`
  width: 360px;
`;

export const NotificationItem = styled.a`
  ${({ theme }) => theme.text.normal};
  color: ${({ theme }) => theme.colors.text};
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    ${NotificationTypeIconWrapper} {
      &:before {
        border-color: ${({ theme }) => theme.colors.hoverGray};
      }  
    }
  }
`;

export const NotificationItemHead = styled.div`
  display: flex;
  align-items: center;
`;

export const NotificationItemTitle = styled.span<{ unread: boolean }>`
  flex: 1;
  font-family: ${({ theme, unread }) => unread ? theme.font.bold : theme.font.medium};
  font-size: 14px;
  line-height: 20px;
`;

export const NotificationItemBody = styled.p<{ unread: boolean }>`
  ${({ theme }) => theme.text.small};
  padding: 0 40px;
  color: ${({ unread, theme }) => unread ? theme.colors.text : theme.colors.textSecondary}
`;
