import styled, { keyframes } from 'styled-components';
import { SvgIcon, Button, PersonOPT } from '@insly/qmt-reactjs-ui-lib';
import { ContentSubheadline } from 'app/Pages/Quote/Profile/body/styles';
import { SMALL_SCREEN_WIDTH } from 'styles/common';

const participantsVerticalPadding = '10px';

const blurOverlayHide = keyframes`
  0% {
    visibility: visible;
    opacity: 0.8;
    z-index: 1;
  }
  99% {
    visibility: visible;
    z-index: 1;
  }
  100% {
    visibility: hidden;
    z-index: -1;
    opacity: 0;
  }
`;

const blurOverlayShow = keyframes`
  0% {
    z-index: 1;
    visibility: visible;
    opacity: 0;
  }
  100% {
    z-index: 1;
    visibility: visible;
    opacity: 0.8;
  }
`;

export const wiggle = keyframes`
  0% {
    box-shadow: 0 2px 4px -2px rgba(0,0,0,0.2), inset 0 0 0 0 rgb(255, 166, 147);
  }
  25% {
    box-shadow: rgb(0 0 0 / 80%) 7px 7px 10px 0, inset 0 0 0 3px rgb(255, 166, 147);
    transform: scale(1.01);
  }
  100% {
    box-shadow: 0 2px 4px -2px rgba(0,0,0,0.3), inset 0 0 0 0 rgb(255, 166, 147);
  }
`;

export const ParticipantsBlockHead = styled.div<{ disabled?: boolean }>`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.strokeA};
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeA};
  padding: ${participantsVerticalPadding} 0;
  align-items: center;
`;

export const ParticipantsOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #FFF;
  opacity: 0.9;
`;

export const ControlsRight = styled.div<{ bordered?: boolean }>`
  ${({ bordered, theme }) => bordered ? `
    border-left: 1px solid ${theme.colors.strokeA};
    margin: -${participantsVerticalPadding} 0;
    padding: ${participantsVerticalPadding} 0;
  ` : null}
`;

export const ParticipantsBlock = styled.div<{ isPaddingBottom?: boolean }>`
  position: relative;
  padding-bottom: ${({ isPaddingBottom = true }) => isPaddingBottom ? '36px' : 0};
`;

export const ParticipantsSubheadline = styled(ContentSubheadline)`
  ${({ theme }) => theme.text.headerH2};
  padding: 36px 0;
  margin-bottom: 0;
  @media (max-width: ${SMALL_SCREEN_WIDTH}) {
    font-size: 95%;
    padding: 18px 0;
  }
`;

export const ParticipantRoleTitle = styled.div`
  ${({ theme }) => theme.text.normal};
  display: flex;
  flex: 1;
  flex-shrink: 0;
  align-items: center;
  padding: 0 8px;
  font-size: 12px;
  justify-content: center;
  hyphens: auto;
  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.strokeA};
  }
`;

export const AddParticipantsText = styled.div`
  ${({ theme }) => theme.text.normal};
  color: ${({ theme }) => theme.colors.textSecondary};
  padding: 60px 0;
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeA};
`;

export const ParticipantRemoveIcon = styled(SvgIcon)`
  position: absolute;
  left: calc(100%);
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin: 20px 0 0 10px;
`;

export const ParticipantWrapper = styled.div<{ policyHolder?: boolean }>`
  position: relative;
  display: flex;
  margin-top: 32px;
`;

export const ParticipantPersonOPT = styled(PersonOPT)`
  border-radius: ${({ theme }) => `${theme.borderRadius} 0 0 ${theme.borderRadius}`};
  flex: 1;
  min-width: auto;
  min-height: 62px;
  &:hover {
    border-color: ${({ theme }) => theme.colors.strokeA};
  }

  .qmt-ui-person-opt__body {
    margin-left: -20px;
    margin-right: -14px;
  }
  
  .qmt-ui-person-opt__body--inner {
    padding: 20px;
  }
`;

export const ParticipantRolesWrapper = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.strokeA};
  border-left: none;
  border-radius: ${({ theme }) => `0 ${theme.borderRadius} ${theme.borderRadius} 0`};
  flex-basis: 40%;
  overflow: hidden;
`;

export const ParticipantRole = styled(ParticipantRoleTitle)`
  background-color: #F8F8F8;
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeA};
  height: 61px;
  margin-bottom: -1px;
`;

export const OverlayBlur = styled.div<{ isShown: 'show' | 'hide' | null }>`
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: 0;
  z-index: -1;
  animation-name: ${({ isShown }) => {
    if (isShown === 'show') {
      return blurOverlayShow;
    } else if (isShown === 'hide') {
      return blurOverlayHide;
    } else return null;
  }};
  animation-duration: 500ms;
  animation-fill-mode: forwards;
`;

export const TravellerStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const TravellerInputWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const IDDWrapperStyled = styled.div`
  
`;

export const ButtonTransparent = styled(Button)<{ highlighted?: boolean }>`
  border: none;
  animation-duration: 1000ms;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: ${({ highlighted }) => highlighted && wiggle};
`;
