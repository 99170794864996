import { JSONSchema, JSONSchemaProp } from 'models';
import { ContentBodyRow } from 'app/Pages/Quote/Profile/body/styles';
import { ControlsWrapper, RequiredMark, SectionHeadline } from '../styles';
import { renderArray, renderObject, checkShowIf } from '../index';
import { InputDate } from '../components';
import { Input, Select } from '@insly/qmt-reactjs-ui-lib';
import { getOptionsFromEnum, sortSchemaPropertiesByPriority, showIf, translate } from 'utils';
import { DefaultIcon } from 'styles/common';
import { DefaultEmptyBlock, FormControlCheckbox, FormControlIcon } from './styles';

const IDD_COMMENTS = ['otherIddRequirenment_comment', 'nonAcceptableExclusions_comment', 'searchingEsgProduct_comment'];

export const renderIDDAdditionalProperties = ({
  form,
  formChange,
  schemaProps,
  fullForm,
  renderCustomField,
  customAdditionalProperties,
  customRequiredFields,
  insurerTag,
}: {
  form: Record<string, any>,
  formChange: (data: Record<string, any>) => void,
  schemaProps: JSONSchema,
  fullForm: Record<string, any>,
  renderCustomField?: (form: Record<string, any>, formChange: (data: Record<string, any>) => void, schemaPropKey: string, prop: JSONSchemaProp, insurerTag?: string) => JSX.Element,
  customAdditionalProperties?: JSONSchema,
  customRequiredFields?: string[],
  insurerTag?: string,
}) => {
  const formProperties: JSX.Element[] = [];

  const handleFormChange = (name: string, value: any, parentName?: string) => {
    const touchedForm = { ...form };

    if (parentName) {
      if (touchedForm[parentName]) {
        touchedForm[parentName][name] = value;
      } else {
        touchedForm[parentName] = {
          [name]: value
        };
      }
    } else {
      touchedForm[name] = value;
    }

    formChange(touchedForm);
  };

  if (customAdditionalProperties || schemaProps.additionalOptions?.properties) {
    let additionalProperties = customAdditionalProperties || schemaProps.additionalOptions.properties || {};
    const requiredFields = customRequiredFields || schemaProps.additionalOptions?.required || [];

    if (Object.keys(additionalProperties)?.length) {
      additionalProperties = sortSchemaPropertiesByPriority(additionalProperties);
    }

    Object.keys(additionalProperties).forEach(schemaPropKey => {
      const prop = additionalProperties[schemaPropKey];

      if (!checkShowIf(prop, fullForm, form)) {
        return;
      }

      if (prop.custom_ui_field && renderCustomField) {
        formProperties.push(
          <ContentBodyRow key={schemaPropKey}>
            <SectionHeadline>{translate({ key: prop.title_translation_key, fallback: schemaPropKey })} {(requiredFields?.includes(schemaPropKey) || prop.required_if ? showIf(prop.required_if, form, fullForm) : false)? <RequiredMark /> : null}</SectionHeadline>
            {renderCustomField(form, formChange, schemaPropKey, prop, insurerTag)}
          </ContentBodyRow>
        );
      } else {
        switch (prop.type) {
          case 'object':
            formProperties.push(
              renderObject(schemaPropKey, prop, (requiredFields?.includes(schemaPropKey) || prop.required_if ? showIf(prop.required_if, form, fullForm) : false), form, fullForm, handleFormChange)
            );
            break;
          case 'array':
            if (schemaPropKey === 'agreementforemail' || !form.refusal) {
              formProperties.push(
                <ContentBodyRow key={schemaPropKey} className={`form-section--${schemaPropKey}`}>
                  <SectionHeadline>{translate({ key: prop.title_translation_key, fallback: schemaPropKey })} {(requiredFields?.includes(schemaPropKey) || prop.required_if ? showIf(prop.required_if, form, fullForm) : false) ? <RequiredMark /> : null}</SectionHeadline>
                  <ControlsWrapper>
                    {renderArray(form, fullForm, schemaPropKey, prop.items as JSONSchemaProp, handleFormChange)}
                  </ControlsWrapper>
                </ContentBodyRow>
              );
            }
            break;
          default:
            formProperties.push(
              <ContentBodyRow key={schemaPropKey} noMargin={form.refusal && schemaPropKey !== 'refusal'}>
                {renderFormControl(form, fullForm, schemaPropKey, prop, handleFormChange, (requiredFields?.includes(schemaPropKey) || prop.required_if ? showIf(prop.required_if, form, fullForm) : false))}
              </ContentBodyRow>
            );
        }
      }

    });
  }

  return formProperties;
};


const renderFormControl = (
  form: Record<string, any>,
  fullForm: Record<string, any>,
  name: string,
  prop: JSONSchemaProp,
  handleFormChange: (name: string, value: any, parentName?: string) => void,
  required?: boolean,
  parentName?: string,
) => {

  if (!checkShowIf(prop, fullForm, form)) {
    return <></>;
  }

  if (prop.enum) {
    return <Select
      key={name}
      name={name}
      label={translate({ key: prop.title_translation_key, fallback: name })}
      value={form ? form[name] : ''}
      options={getOptionsFromEnum(prop as JSONSchemaProp) || []}
      required={required}
      placeholder={translate({ key: 'common.select' })}
      handleChange={(key, value) => handleFormChange(key as string, value, parentName)}
      searchable={true}
    />;
  }

  switch (prop.type) {
    case 'boolean':
      return <FormControlCheckbox
        key={name}
        name={name}
        //Need ability to process HTML from translations as label
        label={<div dangerouslySetInnerHTML={{ __html: translate({ key: prop.title_translation_key, fallback: name }) }} />}
        checked={form ? !!form[name] : false}
        handleChange={(key, value) => handleFormChange(key as string, value, parentName)}
        disabled={prop.is_statement}
        required={required}
        tooltip={name === 'refusal' ? { message: translate({ key: `idd.agreement.description` }) } : undefined}
        icon={name === 'refusal' ? <DefaultIcon icon="info" /> : undefined}
      />;
    case 'object':
      return renderObject(name, prop, !!required, form, fullForm, handleFormChange, parentName);
    case 'array':
      return (
        <ContentBodyRow key={name}>
          <SectionHeadline>{translate({ key: prop.title_translation_key, fallback: name })} {required ? <RequiredMark /> : null}</SectionHeadline>
          <ControlsWrapper>
            {renderArray(form, fullForm, name, prop.items as JSONSchemaProp, handleFormChange, parentName)}
          </ControlsWrapper>
        </ContentBodyRow>
      );
    case 'string':
    default:
      return form.refusal ? (
        <DefaultEmptyBlock />
      ) : prop.format === 'date' ? (
        <InputDate
          key={name}
          required={required}
          name={name}
          prop={prop}
          value={form ? form[name] : ''}
          handleFormChange={handleFormChange}
          parentName={parentName}
        />
      ) : (
        <Input
          textarea={prop.format === 'textarea'}
          key={name}
          name={name}
          label={translate({ key: prop.title_translation_key, fallback: name })}
          value={form ? form[name] : ''}
          required={required}
          disabled={prop.read_only}
          handleChange={(key, value: string | number | undefined) => {
            let processedValue = value;
            if (value && prop.type && ['number', 'integer'].includes(prop.type) && Number.isInteger(+(value))) {
              processedValue = +(value);
            }
            handleFormChange(key as string, processedValue, parentName);
          }}
          tooltip={IDD_COMMENTS.includes(name) ? renderTooltip(name) : undefined}
        />
      );
  }
};

const renderTooltip = (name: string) => {
  let message = translate({ key: name });

  switch (name) {
    case 'nonAcceptableExclusions_comment':
      message = translate({ key: 'idd.comment.other' });
      break;
    case 'otherIddRequirenment_comment':
      message = translate({ key: 'idd.comment.other' });
      break;
    case 'searchingEsgProduct_comment':
      message = translate({ key: 'idd.comment.esg' });
      break;
  }

  return { children: <FormControlIcon icon="info" />, message };
};
