import { TQuoteAssistanceForm, TypeQuoteBorderForm, TypeQuoteMotorForm, TypeQuoteTravelForm } from '../models';

type TypeQuoteForm = TypeQuoteMotorForm | TypeQuoteBorderForm | TQuoteAssistanceForm | TypeQuoteTravelForm;

export const regonValidation = (form: TypeQuoteForm) => {
  let isValid = true;

  if ('participants' in form) {
    const participant = form.participants?.find(participant => participant.customer.customerType === 'company');

    if (participant) {
      isValid = !!participant.customer.props.regon;
    }
  }

  if (form.policy_holder?.customer.type === 'company') {
    isValid = !!form.policy_holder?.customer.props.regon;
  }

  return isValid;
};
