import { translate } from 'utils';

export const regexpValidation = (
  item: string,
  firstNameField: string,
  lastNameField: string
) => {
  let pattern, regExpErrorMessage;

  if (['pesel', 'regon', 'nip'].includes(item)) {
    if (item === 'pesel') {
      pattern = new RegExp(/^\d{11}$/);
      regExpErrorMessage = translate({ key: 'error.regexp_pesel' });
    } else {
      pattern = new RegExp(/^\d+$/);
      regExpErrorMessage = translate({ key: 'error.regexp_numbers_only' });
    }
  } else if (item === firstNameField) {
    pattern = new RegExp(/^\S+$/);
    regExpErrorMessage = translate({ key: 'error.no_space_in_names' });
  } else if (item === lastNameField) {
    pattern = new RegExp(/^\S$|^\S[ \S]*\S$/);
    regExpErrorMessage = translate({ key: 'error.no_space_in_names' });
  }

  return { pattern, regExpErrorMessage };
};
