import styled from 'styled-components';
import { Tabs } from '@insly/qmt-reactjs-ui-lib';

export const TabsStyled = styled(Tabs)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeB};
  .qmt-ui-tabs__tab {
    color: ${({ theme }) => theme.colors.textOff};
    transform: translateY(1px);
    padding-bottom: 60px;
    font-size: 20px;
    
    &:not(:last-child) {
      margin-right: 32px;
    }
    
    &--active,
    &:hover {
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const ProfileBody = styled.div<{ isPaddingTop?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ isPaddingTop = true }) => `${isPaddingTop && '32px'} 60px 32px 0`};
  margin-right: -60px;
  flex: 1;
  overflow-y: auto;
`;
