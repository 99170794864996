import styled from 'styled-components';
import { TIDD } from '../index';
import { ControlsButton, List, TableStyled, TitleWrapper } from 'app/Pages/common/List/styles';

export const ColStatus = styled.div<{ status?: TIDD['status'] }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
  background-color: ${({ theme, status }) =>
    status && theme.colors.idd_status[status] ?
      theme.colors.idd_status[status] :
      theme.colors.idd_status['unknown']};
`;

export const ColStatusData = styled.div`
  padding-top: 2px;
  display: flex;
  align-items: center;
`;

export const IDDListButton = styled(ControlsButton)`
  height: 38px;
`;

export const TableIDD = styled(TableStyled)`
  overflow: visible;
  .qmt-ui-table__head {
    padding: 6px;
    ${IDDListButton} {
      align-self: center;
    }
  } 
  .qmt-ui-table__body {
    overflow: visible;
  }
`;

export const IDDList = styled(List)<{ customerPage?: boolean }>`
  padding-top: ${({ customerPage }) => customerPage && 0};
`;

export const IDDListTitleWrapper = styled(TitleWrapper)<{ customerPage?: boolean }>`
  margin-top: ${({ customerPage }) => customerPage ? 0 : '-7px'};
  margin-bottom: ${({ customerPage }) => customerPage && '20px'};
  padding-right: 6px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;
