import styled from 'styled-components';
import { Spinner, SvgIcon } from '@insly/qmt-reactjs-ui-lib';
import { DefaultIcon, Drop, Input } from 'styles/common';

export const CloseIcon = styled(SvgIcon)`
  position: absolute;
  right: 4px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  stroke: ${({ theme }) => theme.colors.black };
`;

export const SearchInput = styled(Input)`
  height: 20px;
`;

export const SearchSpinner = styled(Spinner)`
  position: absolute;
  right: 4px;
  border-color: ${({ theme }) => theme.colors.primary}20;
  border-left-color: ${({ theme }) => theme.colors.primary};
`;

export const SearchIcon = styled(SvgIcon)`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  flex-shrink: 0;
  transition: stroke 0.3s ease;
  stroke: ${({ theme }) => theme.colors.black};
`;

export const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 16px;
`;

export const SearchResultsFailedItem = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
`;

export const SearchResultsIcon = styled(DefaultIcon)<{ margin?: 'left' | 'right' }>`
  margin-right: ${({ margin }) => margin === 'right' ? '16px' : null};
  margin-left: ${({ margin }) => margin === 'left' ? '16px' : null};
`;

export const SearchResultsItemGroupHeaderTitle = styled.div`
  flex: 1;
  font-family: ${({ theme }) => theme.font.bold};
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
`;

export const ParticipantsSearchResultsDrop = styled(Drop)`
  z-index: 1;
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.borderHighlighted};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: -6px 6px 0 rgba(148, 119, 90, 0.24);
`;

export const SearchResultsItemData = styled.div`
  flex: 1;
  padding: 0 16px;
`;

export const SearchResultsItemDataTitle = styled.div`
  ${({ theme }) => theme.text.normal};
  margin-bottom: 4px;
`;

export const SearchResultsItemDataAdditional = styled.div`
  ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.colors.subtle};
  margin-bottom: 2px;
  .highlight {
    color: ${({ theme }) => theme.colors.black};
  }
`;
