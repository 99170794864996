import { JSONSchemaProp, TError, TQuoteParticipantRole } from '../models';
import { translate } from './translate';
import { SOLE_TRADER_CUSTOMER_TYPE } from '../config/consts';
import { CO_OWNER, PARTICIPANT_MAIN_ROLE } from '../app/Pages/Quote/Profile/body/Participants/common/consts';

type ruleNumberObject = {
  value: number,
  message?: string,
};

type ruleTypeObject = {
  value: string,
  message?: string,
};

type ruleRegExpObject = {
  value: RegExp,
  message?: string,
};

type validationRules = {
  minimum?: ruleNumberObject,
  maximum?: ruleNumberObject,
  minLength?: ruleNumberObject,
  maxLength?: ruleNumberObject,
  regExp?: ruleRegExpObject,
  type?: ruleTypeObject,
};

interface IProps {
  field: string,
  parentField?: string,
  listIndex?: number,
  fieldName?: string,
  value: unknown,
  rules: validationRules,
  uncommonError?: boolean,
}

export const EMAIL_REGEXP = /^[^\s@]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const PHONE_REGEXP = `^(?:\\${window._env_.DEFAULT_COUNTRY_CODE})?[1-9][0-9]{8}$`;

export const validate = ({
  field,
  parentField,
  listIndex,
  fieldName,
  value,
  rules,
  uncommonError,
}: IProps) => {
  const errors: TError[] = [];

  const standardFields = {
    code: fieldName || field,
    field: field,
    parentField: parentField || undefined,
    listIndex: typeof listIndex === 'number' ? listIndex : undefined,
    uncommonError: uncommonError
  };

  if (typeof value === 'undefined' || value === '' || (typeof value === 'string' && !value.trim()?.length) || (Array.isArray(value) && !value?.length)) {
    errors.push({
      ...standardFields,
      message: `${translate({ key: 'error.field_empty' })}`,
    });
    // If field is empty no need to check other rules
    return errors;
  }

  if (rules.minLength && rules.minLength.value) {
    if (Array.isArray(value)) {
      if (value.some(item => item.trim().length < rules.minLength!.value)) {
        errors.push({
          ...standardFields,
          message: rules.minLength.message || `${translate({ key: 'error.min_length' })} ${rules.minLength.value}`,
        });
      }
    } else {
      if (typeof value !== 'string' || value.trim().length < rules.minLength.value) {
        errors.push({
          ...standardFields,
          message: rules.minLength.message || `${translate({ key: 'error.min_length' })} ${rules.minLength.value}`,
        });
      }
    }
  }

  if (rules.maxLength && rules.maxLength.value) {
    if (Array.isArray(value)) {
      if (value.some(item => item.trim().length > rules.maxLength!.value)) {
        errors.push({
          ...standardFields,
          message: rules.maxLength.message || `${translate({ key: 'error.max_length' })} ${rules.maxLength.value}`,
        });
      }
    } else {
      if (typeof value !== 'string' || value.trim().length > rules.maxLength.value) {
        errors.push({
          ...standardFields,
          message: rules.maxLength.message || `${translate({ key: 'error.max_length' })} ${rules.maxLength.value}`,
        });
      }
    }
  }

  if (rules.regExp) {
    if (typeof value !== 'string' || !rules.regExp.value.test(value)) {
      errors.push({
        ...standardFields,
        message: rules.regExp.message || `${fieldName ? `${fieldName} ` : ''} ${translate({ key: 'error.regexp' })} ${rules.regExp.value.source}`
      });
    }
  }

  if (rules.type && rules.type.value) {
    if (rules.type.value === 'array') {
      if (!Array.isArray(value)) {
        errors.push({
          ...standardFields,
          message: rules.type.message || `${translate({ key: `error.type.${rules.type.value}` })}`
        });
      }
    } else if (typeof value !== rules.type.value) {
      errors.push({
        ...standardFields,
        message: rules.type.message || `${translate({ key: `error.type.${rules.type.value}` })}`
      });
    }
  }

  if (rules.maximum) {
    if (typeof value === 'number' && value > rules.maximum.value) {
      errors.push({
        ...standardFields,
        message: rules.maximum.message || `${translate({ key: 'error.more_than_maximum' })} ${rules.maximum.value}`,
      });
    }
  }

  if (rules.minimum) {
    if (typeof value === 'number' && value < rules.minimum.value) {
      errors.push({
        ...standardFields,
        message: rules.minimum.message || `${translate({ key: 'error.less_than_minimum' })} ${rules.minimum.value}`,
      });
    }
  }

  return errors;
};

export const setRules = ({
  props,
  item,
  pattern,
  regExpErrorMessage,
} : {
  props?: Record<string, JSONSchemaProp>,
  item: string,
  pattern?: RegExp,
  regExpErrorMessage?: string,
}) => ({
  minLength: setLengthRules(item, 'minLength', props),
  maxLength: setLengthRules(item, 'maxLength', props),
  regExp: pattern ? {
    value: pattern,
    message: regExpErrorMessage
  } : undefined,
  type: props && props[item] ? { value: props[item].type === 'integer' ? 'number' : props[item].type as string } : undefined,
  maximum: props && props[item] && props[item].maximum ? { value: props[item].maximum as number } : undefined,
  minimum: props && props[item] && typeof props[item].minimum !== 'undefined' ? { value: props[item].minimum as number } : undefined,
});

const setLengthRules = (item: string, checkingLength: 'minLength' | 'maxLength', props?: Record<string, JSONSchemaProp>) => {
  let lengthRules: { value: number } | undefined;

  if (props && props[item]) {
    const propsObj = props[item];

    if ('items' in propsObj && propsObj['items'] && propsObj.items[checkingLength]) {
      lengthRules = { value: propsObj.items[checkingLength] as number };
    } else if (propsObj[checkingLength]) {
      lengthRules = { value: propsObj[checkingLength] as number };
    }
  }

  return lengthRules;
};

export const getErrorsByFieldName = (key: string, errors: TError[]) => {
  let errorMessages: string[] = [];
  errors.forEach(error => {
    if (error.field === key) {
      errorMessages.push(error.message);
    }
  });

  return errorMessages;
};

export const IsRequiredForSoleTraderAndIndividual = (companyType: string | undefined, roles: TQuoteParticipantRole[] = []) => {
  if (companyType === SOLE_TRADER_CUSTOMER_TYPE) {
    if (roles) {
      if (roles.includes(PARTICIPANT_MAIN_ROLE) || roles.includes(CO_OWNER)) return true;
    }
    return false;
  }
  return true;
};

export const customerEmailValidation = () => ({
  pattern: new RegExp(EMAIL_REGEXP),
  regExpErrorMessage: translate({ key: 'error.regexp_email' })
});

export const customerPhoneValidation = () => ({
  pattern: new RegExp(PHONE_REGEXP),
  regExpErrorMessage: translate({ key: 'error.regexp_phone' })
});
