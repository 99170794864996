export const highlight = (text= '', searchTerm = '') => {

  const split = text.toLowerCase().split(searchTerm.toLowerCase());

  let ttt = '';

  for (let i = 0; i < split.length; i++) {
    if (i === split.length - 1) {
      ttt += split[i];
    } else {
      ttt += `${split[i]}<span class="highlight">${searchTerm}</span>`;
    }
  }

  return ttt;
};
