import IDD from 'app/Pages/Clients/profile/body/IDD';
import React, { useEffect, useState } from 'react';
import { TCustomer } from 'app/Pages/Clients/common';
import { preflightAPICall, processAPIError } from 'utils';
import axios from 'axios';
import { API_CUSTOMER_PROFILE_URL } from 'config/api';
import { TError } from 'models';
import { useParams } from 'react-router-dom';
import { renderErrors } from 'app/Components/common/Error';
import { Loader } from 'styles/common';
import { CustomerIDDListContainer, CustomerIDDListWrapper } from './styles';

const CustomerIDDList = () => {
  const [customer, updateCustomer] = useState<TCustomer | null>(null);
  const [customerDataStatus, updateCustomerDataStatus] = useState('success');
  const [customerErrors, updateCustomerErrors] = useState<TError[]>([]);

  const { customerId } = useParams<{ customerId?: string }>();

  useEffect(() => {
    if (customerId) {
      getCustomer(customerId);
    }
  }, [customerId]);

  const getCustomer = (customerId: string) => {
    updateCustomerDataStatus('loading');

    preflightAPICall(() => {
      axios.get(API_CUSTOMER_PROFILE_URL.replace('%USER_ID%', customerId)).then(response => {
        const data = response.data;
        updateCustomer(data);
        updateCustomerDataStatus('success');
      }).catch(error => {
        updateCustomerDataStatus('error');
        updateCustomerErrors(() => processAPIError(error, true) as TError[]);
      });
    });
  };

  switch (customerDataStatus) {
    case 'error':
      return (
        <>
          {renderErrors(customerErrors)}
        </>
      );
    case 'success':
      if (customer) {
        return (
          <CustomerIDDListContainer>
            <CustomerIDDListWrapper>
              <IDD profile={customer} />
            </CustomerIDDListWrapper>
          </CustomerIDDListContainer>
        );
      }
      break;
    case 'loading':
    default:
      return <Loader />;
  }

  return <div></div>;
};

export default CustomerIDDList;
