import { TEnumMoreLess } from '../models';

export const enumMoreLess = (type: TEnumMoreLess['type'], controlFieldValue: any, options?: {key: any, value: string | number}[]) => {

  if (controlFieldValue && options) {
    let dependedFieldOptions = [
      ...options
    ];

    if (isNaN(controlFieldValue)) {
      controlFieldValue = +(removeLettersFromString(controlFieldValue));
    }

    switch (type) {
      case 'less':
        dependedFieldOptions = dependedFieldOptions.filter(item => (isNaN(item.key) ? +(removeLettersFromString(item.key)) : item.key) < controlFieldValue);
        break;
      case 'more':
        dependedFieldOptions = dependedFieldOptions.filter(item => isNaN(item.key) ? +(removeLettersFromString(item.key)) : item.key > controlFieldValue);
        break;
      case 'equal':
        dependedFieldOptions = dependedFieldOptions.filter(item => (isNaN(item.key) ? +(removeLettersFromString(item.key)) : item.key) === controlFieldValue);
        break;
      case 'equalMore':
        dependedFieldOptions = dependedFieldOptions.filter(item => (isNaN(item.key) ? +(removeLettersFromString(item.key)) : item.key) >= controlFieldValue);
        break;
      case 'equalLess':
        dependedFieldOptions = dependedFieldOptions.filter(item => (isNaN(item.key) ? +(removeLettersFromString(item.key)) : item.key) <= controlFieldValue);
        break;
    }

    return dependedFieldOptions;
  }

  return options;
};

const removeLettersFromString = (inputString: string) => inputString.replace(/[a-zA-Z]/g, '');
