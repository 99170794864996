import { useAuth } from '../context/Auth';
import { Redirect, Route } from 'react-router-dom';
import React from 'react';

export const PrivateRoute = ({ children, ...rest }: {children: JSX.Element, path: string}) => {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};
