import { TCustomer } from 'app/Pages/Clients/common';
import { SOLE_TRADER_CUSTOMER_TYPE } from 'config/consts';
import { TParticipantProps } from '../models';

export const getCustomerName = (userData: TCustomer) => {
  switch (userData.type) {
    case 'individual':
      return `${userData.props?.first_name} ${userData.props.last_name}`;
    case 'company':
      if (userData.props?.company_type === SOLE_TRADER_CUSTOMER_TYPE) {
        return `${userData.props.first_name} ${userData.props.last_name}`;
      } else {
        return userData.props?.company_name as string;
      }
    default:
      return '';
  }
};

export const getParticipantName = (userData: TParticipantProps) => {

  switch (userData.customerType) {
    case 'individual':
      return `${userData.props.firstName} ${userData.props.lastName}`;
    case 'company':
      if (userData.props.companyType === SOLE_TRADER_CUSTOMER_TYPE) {
        return `${userData.props.firstName} ${userData.props.lastName}`;
      } else {
        return userData.props.name as string;
      }
    default:
      return '';
  }
};
