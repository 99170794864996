export const pkdCodeMatcher = (value: string = ''): string => {
  let initValue = value.replace(/\./g, '');
  let processedValue = '';

  for (let i = 0; i < initValue.length; i++) {
    if (i > 0 && (i % 2 === 0)) {
      processedValue += '.';
    }

    processedValue += initValue[i];
  }

  if (processedValue.length > 7) {
    return processedValue.slice(0, 7);
  }

  return processedValue;
};
