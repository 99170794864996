import React from 'react';
import { Doc, DocData, DocIcon, Docs, DocTitle } from './styles';
import { DefaultIcon } from 'styles/common';

export type TEmailDoc = {
  id: string,
  filename?: string,
  ref_id: string,
};

export const EmailDocList = ({
  docs,
  handleRemoveDoc,
  handleDownloadDoc
}: {
  docs: TEmailDoc[],
  handleRemoveDoc: (id: string) => void,
  handleDownloadDoc: (doc: TEmailDoc) => void,
}) => (
  <>
    <Docs>
      {docs.map(doc => (
        <Doc key={doc.id}>
          <DocIcon icon="file_text" />
          <DocData>
            <DocTitle>{doc.filename}</DocTitle>
          </DocData>
          <DefaultIcon icon="download" onClick={() => handleDownloadDoc(doc)} />
          <DefaultIcon icon="trash" onClick={() => handleRemoveDoc(doc.id)} />
        </Doc>
      ))}
    </Docs>
  </>
);
