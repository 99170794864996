import styled from 'styled-components';
import { ProfileBody } from 'app/Pages/common/Profile/styles';

export const CustomerIDDListWrapper = styled(ProfileBody)`
  width: 100%;
  max-width: 720px;
  padding: 0 0 32px;
  margin: 0 auto;
`;

export const CustomerIDDListContainer = styled.div`
  width: 100%;
  overflow-y: auto;
`;
