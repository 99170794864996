import { TCustomerContact } from 'models';
import { IFormGroupItem } from '@insly/qmt-reactjs-ui-lib/dist/components/FormGroup';
import { translate, translateOptionsList } from 'utils';
import { CONTACT_SUBTYPES } from 'config/itemLists';

export const createContactFormFields = (
  item: TCustomerContact,
  index: number,
  handleChange: (name: keyof TCustomerContact, value: string | boolean, index: number) => void
) => {
  const fields: IFormGroupItem[] = [];

  fields.push(
    {
      type: 'input',
      options: {
        placeholder: item.type === 'phone' ? translate({ key: 'common.contact.phone_placeholder' }) : '',
        countryCode: item.type === 'phone' ? window._env_.DEFAULT_COUNTRY_CODE : '',
        verticalAlignCenter: true,
        icon: item.type,
        name: 'value',
        label: null,
        value: item.value,
        type: item.type === 'email' ? 'email' : 'text',
        styles: {
          flexBasis: item.type === 'email' ? '100%' : '66.6%'
        },
        handleChange: (name: keyof TCustomerContact, value: string) => handleChange(name as keyof TCustomerContact, value, index),
      }
    }
  );

  if (item.type === 'phone') {
    fields.push(
      {
        type: 'select',
        options: {
          name: 'subtype',
          label: undefined,
          value: item.subtype,
          options: translateOptionsList(CONTACT_SUBTYPES),
          styles: {
            flexBasis: '33.3%'
          },
          placeholder: translate({ key: 'common.select' }),
          notFoundText: translate({ key: 'common.nothing_found' }),
          handleChange: (name, value) => handleChange(name as keyof TCustomerContact, value as string | boolean, index),
        }
      }
    );
  }

  return fields;
};
