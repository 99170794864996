import { translations } from 'config/translation';
import { vsprintf } from 'sprintf-js';

interface IProps {
  key?: string,
  fallback?: string,
  replace?: string[],
}

export const translate = ({ key, fallback, replace }: IProps) => {

  if (translations) {

    if (key && typeof translations[key] === 'string') {
      let translationString = translations[key] as string;

      if (replace?.length) {
        translationString = vsprintf(translationString, replace);
      }

      return translationString;

    }

  }

  if (typeof fallback !== 'undefined') {
    return fallback;
  }

  return key || '';

};
