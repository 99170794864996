import React, { useRef, useState } from 'react';
import {
  ButtonBack,
  DocumentsStyled, UploaderDocuments, UploaderDocumentsString, UploaderInput
} from './styles';
import { checkFileRestrictions, preflightAPICall, processAPIError, translate } from 'utils';
import axios from 'axios';
import { TError } from 'models';
import { useHistory } from 'react-router-dom';
import { renderErrors } from 'app/Components/common/Error';
import { renderProgress } from 'app/Components/DocumentsList';
import { useApp } from 'context/App';
import { renderMessages } from 'app/Components/common/Message';
import { MAX_FILE_NUMBER_IN_BULK_UPLOAD } from 'config/consts';

const DRAG_STYLE_TIMEOUT_DELAY = 800;

let removeDragStyleTimeout: ReturnType<typeof setTimeout>;

const BulkUpload = ({
  apiURL,
  goBackButtonTranslation,
  successTranslation,
  uploaderTranslation,
}: {
  apiURL: string,
  goBackButtonTranslation: string,
  successTranslation: string,
  uploaderTranslation: string,
}) => {
  const [isUploading, updateIsUploading] = useState(false);
  const [errors, updateErrors] = useState<TError[]>([]);
  const [succeed, updateSucceed] = useState<string[]>([]);

  const history = useHistory();

  return (
    <DocumentsStyled>
      <Uploader
        isUploading={isUploading}
        updateErrors={updateErrors}
        updateSucceed={updateSucceed}
        updateIsUploading={updateIsUploading}
        apiURL={apiURL}
        successTranslation={successTranslation}
        uploaderTranslation={uploaderTranslation}
      />
      {errors ? renderErrors(errors) : null}
      {succeed ? renderMessages(succeed) : null}
      <ButtonBack disabled={isUploading} onClick={() => history.goBack()}>{translate({ key: goBackButtonTranslation })}</ButtonBack>
    </DocumentsStyled>
  );

};

const Uploader = ({
  isUploading,
  updateErrors,
  updateSucceed,
  updateIsUploading,
  apiURL,
  successTranslation,
  uploaderTranslation,
} : {
  isUploading: boolean,
  updateErrors: (errors: TError[]) => void,
  updateSucceed: (messages: string[]) => void,
  updateIsUploading: (isUploading: boolean) => void,
  apiURL: string,
  successTranslation: string,
  uploaderTranslation: string,
}) => {

  const [progress, updateProgress] = useState(0);

  const { showNotification } = useApp();

  const uploadInput = useRef<HTMLInputElement>(null);

  const onDragOver = (event: React.MouseEvent) => {
    event.preventDefault();
    if (uploadInput.current !== null && uploadInput.current.parentNode) {
      const element = uploadInput.current.parentNode as HTMLElement;
      element.classList.add('drag-over');
    }
  };

  const onDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    clearTimeout(removeDragStyleTimeout);
    removeDragStyleTimeout = setTimeout(() => {
      if (uploadInput.current !== null && uploadInput.current.parentNode) {
        const element = uploadInput.current.parentNode as HTMLElement;
        element.classList.remove('drag-over');
      }
    }, DRAG_STYLE_TIMEOUT_DELAY);
  };

  const onDrop = (event: React.DragEvent) => {
    event.preventDefault();
    onUploadDrop(event);
    if (uploadInput.current !== null && uploadInput.current.parentNode) {
      const element = uploadInput.current.parentNode as HTMLElement;
      element.classList.remove('drag-over');
    }
  };

  const onUploadDrop = (event: React.DragEvent) => {
    if (event.dataTransfer && event.dataTransfer.files.length !== 0) {
      onUpload(event.dataTransfer.files);
    }
  };

  const onUpload = (files: FileList) => {
    updateErrors([]);
    updateSucceed([]);
    updateIsUploading(true);
    updateProgress(0);

    let errors: TError[] = [];
    let filesProcessed = 0;

    if (files.length > MAX_FILE_NUMBER_IN_BULK_UPLOAD) {
      errors.push({ code: '', message: translate({ key: 'upload.bulk.max_file_number' }) });
      onValidationComplete(files, errors);
    }

    for (let i = 0; i < files.length; i++) {
      checkFileRestrictions({
        maxFileSize: 4194304,
        allowedFileTypes: ['pdf', 'jpeg', 'bmp', 'png', 'webp'],
        allowedExoticFileTypes: ['svg'],
        file: files[i],
        onError: (message) => {
          errors.push({ code: '', message: `${files[i].name} - ${message}` });
          filesProcessed +=1;

          if (filesProcessed === files.length) {
            onValidationComplete(files, errors);
          }
        },
        onSuccess: () => {
          filesProcessed +=1;

          if (filesProcessed === files.length) {
            onValidationComplete(files, errors);
          }
        }
      });
    }
  };

  const onValidationComplete = (files: FileList, errors: TError[]) => {
    if (errors.length === 0) {
      processFiles(files);
    } else {
      updateIsUploading(false);
      updateErrors(errors);
    }
  };


  const processFiles = (files: FileList) => {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    preflightAPICall(() => {
      axios.post(apiURL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          updateProgress(progressEvent.loaded / progressEvent.total * 100);
        },
      }).then(response => {
        switch (response.status) {
          case 207:
            processIDDBulkUploadMessages(response.data, updateErrors, updateSucceed);
            break;
          case 204:
          default:
            showNotification({
              preset: 'success',
              message: translate({ key: successTranslation })
            });
            break;
        }

        updateIsUploading(false);
        updateProgress(0);
      }).catch(error => {
        updateErrors(processAPIError(error, true, true) as TError[]);
        updateIsUploading(true);
        updateProgress(0);
      });
    });

  };

  return (
    <UploaderDocuments
      onDragOver={isUploading ? undefined : onDragOver}
      onDragLeave={isUploading ? undefined : onDragLeave}
      onDrop={isUploading ? undefined : onDrop}
      onClick={() => {
        if (uploadInput.current !== null) {
          uploadInput.current.click();
        }
      }}
    >
      {isUploading ? renderProgress(progress) : <UploaderInput
        type="file"
        ref={uploadInput}
        name="file"
        multiple={true}
        onChange={() => {
          if (uploadInput.current !== null && uploadInput.current.files) {
            onUpload(uploadInput.current.files);
          }
        }}
      />}
      {!isUploading ? (
        <UploaderDocumentsString>
          {translate({ key: uploaderTranslation })}
        </UploaderDocumentsString>
      ) : null}
    </UploaderDocuments>
  );
};

const processIDDBulkUploadMessages = (data: {
  errors: {
    code: string,
    message: string,
    params: Record<string, any>,
  }[],
  succeed: string[],
},
updateErrors: (errors: TError[]) => void,
updateSucceed: (messages: string[]) => void,
) => {
  const { errors, succeed } = data;

  let processedErrors: TError[] = [];
  let succeedMessages: string[] = [];

  if (errors?.length) {
    processedErrors = errors.map(error => ({
      code: error.code,
      message: error.code === '422'
        ? translate({ key: 'idd.mass_upload_qr_docs.error.no_qr', replace: [error.params['fileName']] })
        : translate({ key: 'idd.documents.mass_upload_qr_docs.error.common', replace: error.params['fileName'] ? [error.params['fileName']] : [] })
    }));
  }

  if (succeed.length) {
    succeedMessages = succeed.map(message => translate({ key: 'idd.mass_upload_qr_docs.message.file_success', replace: [message] }));
  }

  updateErrors(processedErrors);
  updateSucceed(succeedMessages);
};

export default BulkUpload;
