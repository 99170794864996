import React, { CSSProperties } from 'react';
import { ShowMoreStyled, ShowMoreText, ShowMoreIcon, SHOW_MORE_EL_HEIGHT } from './styles';
import { translate } from 'utils';

export const shouldShowMoreShow = (element: HTMLDivElement, showMore: boolean, updateShowMore: (showMore: boolean) => void) => {
  const showShowMore = element.scrollHeight - element.scrollTop > (element.clientHeight + SHOW_MORE_EL_HEIGHT);
  if (showMore !== showShowMore) {
    updateShowMore(showShowMore);
  }
};

export const ShowMore = ({ show, onClick, style, ...props }: {show: boolean, onClick: () => void, style?: CSSProperties }) => (
  <ShowMoreStyled show={show} onClick={onClick} style={style} {...props}>
    <ShowMoreText>{translate({ key: 'common.show_more' })}</ShowMoreText>
    <ShowMoreIcon icon="arrow_down" />
  </ShowMoreStyled>
);
