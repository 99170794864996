import styled, { css } from 'styled-components';
import { FormGroup, LoaderAnimated, SvgIcon } from '@insly/qmt-reactjs-ui-lib';
import React from 'react';

const CLIENTS_STYLES = css`
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid ${({ theme }) => theme.colors.strokeB};
`;

export const SMALL_SCREEN_WIDTH = '1600px';

export const Loader = styled(LoaderAnimated)`
  width: 32px;
  height: 32px;
  margin: auto;
`;

export const Input = styled.input`
  border: 0;
  outline: none;
  padding: 0;
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  ${({ theme }) => theme.text.inputValue};
  /* clears the ‘X’ from Internet Explorer */
  &[type=search]::-ms-clear { display: none; width : 0; height: 0; }
  &[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
  /* clears the ‘X’ from Chrome */
  &[type="search"]::-webkit-search-decoration,
  &[type="search"]::-webkit-search-cancel-button,
  &[type="search"]::-webkit-search-results-button,
  &[type="search"]::-webkit-search-results-decoration { display: none; }
`;

export const inputReset = () => css`
  ${({ theme }) => theme.text.inputValue};
  border: 0;
  outline: none;
  padding: 0;
  width: 100%;
  max-width: 100%;
  background: transparent;
  &::placeholder {
    color: ${({ theme }) => theme.colors.textSecondary};
    font-style: italic;
  }
  &:disabled {
    background: transparent;
  }
`;

export const Required = styled.span`
  font-size: 20px;
  position: absolute;
  top: -3px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const Drop = styled.ul`
`;

export const DropItem = styled.li`
  ${({ theme }) => theme.text.normal};
  transition: background-color 0.3s ease;
  padding: 16px;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.strokeB};
  }
  &:first-child {
    border-radius: ${({ theme }) => theme.borderRadius} ${({ theme }) => theme.borderRadius} 0 0;
  }
  &:last-child {
    border-radius: 0 0 ${({ theme }) => theme.borderRadius} ${({ theme }) => theme.borderRadius};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.hoverGray};
  }
`;

export const LoadingOverlayStyled = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

export const DefaultIcon = styled(SvgIcon)<{ onClick?: (event: React.MouseEvent) => void }>`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  cursor: ${({ onClick }) => onClick ? 'pointer' : null};
`;

export const ControlsIcon = styled(DefaultIcon)`
  fill: ${({ theme }) => theme.colors.black};
  stroke: ${({ theme }) => theme.colors.black};
`;

export const ControlsIconDefault = styled(DefaultIcon)`
  &:not(:last-child) {
    margin-right: 16px;
  }
`;

export const ControlsToggleIconDefault = styled(ControlsIconDefault)<{ opened?: boolean }>`
  flex-shrink: 0;
  transform: ${({ opened }) => opened ? 'rotate(180deg)' : null};
`;

export const ContactsItem = styled.div`
  flex: 1;
  .qmt-ui-input.address-house-nr {
    position: relative;
    &:after {
      content: '/';
      position: absolute;
      right: 0;
      bottom: 18px;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.textSecondary};
    }
  }
`;

export const ContactsDataBlockTitle = styled.h3`
  ${({ theme }) => theme.text.headerH3};
  color: #282828;
  margin-bottom: 40px;
`;

export const ContactsDataBlock = styled.div<{ isClients?: boolean }>`
  ${({ isClients }) => isClients ? css`
    &:not(:first-child) {
      ${CLIENTS_STYLES}
    }
  ` : css`
    ${CLIENTS_STYLES}
  `}
`;

export const ContactPersonRow = styled.div<{ gridTemplate: string }>`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate};
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const FormGroupStyled = styled(FormGroup)`
  background-color: transparent;
`;

export const LinkStyled = styled.a`
  ${({ theme }) => theme.text.normal};
  color: ${({ theme }) => theme.colors.link};
  text-decoration: none;
`;

export const Toggler = styled(SvgIcon)<{opened: boolean}>`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  transform: ${({ opened }) => opened ? 'rotate(-90deg)' : 'rotate(90deg)'};
`;

export const UploaderInput = styled.input`
  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 0;
  display: block;
  font-size: 0;
  line-height: 0;
`;
