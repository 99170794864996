import styled from 'styled-components';
import { DefaultIcon } from 'styles/common';

export const ResultsItem = styled.li<{ noData?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #F0F0F0;
  }
`;

export const ResultsItemDataTitle = styled.div`
  ${({ theme }) => theme.text.normal};
  margin-bottom: 4px;
`;

export const ResultsIcon = styled(DefaultIcon)<{ margin?: 'left' | 'right' }>`
  margin-right: ${({ margin }) => margin === 'right' ? '16px' : null};
  margin-left: ${({ margin }) => margin === 'left' ? '16px' : null};
`;

export const ResultsItemData = styled.div`
  flex: 1;
  padding: 0 16px;
`;

