import { useApp } from 'context/App';
import React, { useState } from 'react';
import { DefaultIcon } from 'styles/common';
import { errorNotification, getUserProps, preflightAPICall, translate } from 'utils';
import { IUserData, TUserData, useAuth } from 'context/Auth';
import { LIST_FILTERS } from 'config/consts';
import { FiltersBody, ListFiltersCustomStyled, CheckboxStyled, DialogStyled } from './styles';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { API_MASTER_URL_USER } from 'config/api';

type TProps = {
  listType: string,
};

export const ListFiltersCustom = ({ listType }: TProps) => {
  const [form, updateForm] = useState<string[]>([]);
  const [isDialogShown, updateIsDialogShown] = useState(false);

  const { user, setUser } = useAuth();
  const { showNotification } = useApp();


  const onSuccess = (data: IUserData) => {
    setUser(data);
  };

  const onError = (error: AxiosError | AxiosResponse) => {
    errorNotification(error as AxiosError, showNotification);
  };

  return (
    <ListFiltersCustomStyled>
      <DefaultIcon
        icon="settings"
        onClick={() => {
          updateForm(fillForm(listType, user));
          updateIsDialogShown(true);
        }}
      />
      <DialogStyled
        title={translate({ key: 'user.custom_filters.title' })}
        show={isDialogShown}
        buttonAccept={<>
          {translate({ key: 'dialog.list_filters.accept' })}&nbsp;
        </>}
        buttonDecline={<>
          {translate({ key: 'dialog.list_filters.cancel' })}&nbsp;
        </>}
        onAccept={() => {
          updateIsDialogShown(false);
          updateUserFilters(form, listType, onSuccess, onError);
        }}
        onDecline={() => updateIsDialogShown(false)}
        onHide={() => updateIsDialogShown(false)}
        body={<Body form={form} updateForm={updateForm} listType={listType} />}
      />
    </ListFiltersCustomStyled>
  );
};

const Body = ({ form, updateForm, listType }: { form: string[], updateForm: (items: string[]) => void, listType: string }) => {

  const handleUpdateForm = (filter: string) => {
    const touchedForm = [...form];
    const itemIndex = touchedForm.findIndex(item => item === filter);

    if (itemIndex !== -1) {
      touchedForm.splice(itemIndex, 1);
    } else {
      touchedForm.push(filter);
    }
    updateForm(touchedForm);

  };

  return (
    <FiltersBody>
      {LIST_FILTERS[listType].map(filter => (
        <CheckboxStyled
          key={filter}
          name={filter}
          label={translate({ key: `user.custom_filters.${listType}.${filter}` })}
          checked={form.includes(filter)}
          handleChange={(name) => handleUpdateForm(name)}
        />
      ))}
    </FiltersBody>
  );
};

const fillForm = (listType: string, user?: TUserData) => {
  let filters: string[] = [];
  const userProps = getUserProps(user);

  if (userProps?.custom_list_filters?.[listType]?.length) {
    filters = [...userProps?.custom_list_filters?.[listType].filter(item => LIST_FILTERS[listType]?.includes(item))];
  }

  if (!filters) {
    filters = [...LIST_FILTERS[listType]];
  }
  return filters;
};

const updateUserFilters = (form: string[], listType: string, onSuccess: (data: IUserData) => void, onError: (error: AxiosError | AxiosResponse) => void) => {
  preflightAPICall(() => {
    axios.get(`${API_MASTER_URL_USER}/info`).then(response => {
      if (response.data) {
        const userDataProps = response.data?.props ? { ...response.data?.props } : {};

        if (userDataProps?.custom_list_filters) {
          userDataProps.custom_list_filters[listType] = [...form];
        } else {
          userDataProps.custom_list_filters = {
            [listType]: [...form]
          };
        }

        axios.patch(`${API_MASTER_URL_USER}/update`, {
          props: {
            custom_list_filters: userDataProps.custom_list_filters
          }
        }).then(response => {
          if (response.data) {
            onSuccess(response.data);
          } else {
            onError(response);
          }
        });
      } else {
        onError(response);
      }

    }).catch(error => {
      onError(error);
    });
  });

};
