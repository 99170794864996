import axios, { AxiosError, AxiosPromise } from 'axios';
import {
  concurrentRequestsResolver,
  getCustomerName, getCustomerTypeIconName,
  preflightAPICall,
  processAPIError,
  translate
} from 'utils';
import apiConfig, { DEFAULT_LOCALE } from 'config/api';
import {
  NetworkState,
  TCustomer,
  TError,
  TGeneralSearchItemType,
  TypeSearchResults
} from 'models';
import {
  SearchResultsIcon,
  SearchResultsItem,
  SearchResultsItemControls,
  SearchResultsItemControlsItem,
  SearchResultsItemData, SearchResultsItemDataAdditional,
  SearchResultsItemDataTitle,
  SearchResultsItemGroup,
  SearchResultsItemGroupHeader,
  SearchResultsItemGroupHeaderTitle
} from './styles';
import { TQuoteData, TQuoteType } from 'app/Pages/Quote/models';
import { useHistory } from 'react-router-dom';
import { Search, SvgIcon } from '@insly/qmt-reactjs-ui-lib';
import { highlight } from 'helpers/highlight';
import { motorProducts } from 'config/itemLists';
import React, { useState } from 'react';
import { DefaultIcon } from 'styles/common';
import { getQuoteTypeIconName } from 'utils/getQuoteTypeIconName';

let CancelToken = axios.CancelToken;
let cancelTokenSource = CancelToken.source();

const ADDITIONAL_CUSTOMER_PROPS = ['pesel', 'idCode', 'nip', 'regon', 'email', 'phone'];

const SEARCH_ITEMS_TYPE = {
  'customer': {
    icon: 'user',
    label: 'nav.clients',
  },
  'quote': {
    icon: 'nav_quote',
    label: 'nav.quotes',
  }
};

const RESULTS_TO_SHOW = 4;
const CLOSE_DROP_CLASS = 'close-drop';

const SearchComponent = ({ className }: {className: string}) => {
  const [searchState, onChangeSearchState] = useState<NetworkState>({ state: undefined });

  const onError = (error: AxiosError) => {
    onChangeSearchState({
      state: 'failed',
      errors: processAPIError(error, true) as TError[]
    });
  };

  const handleSearch = (value: string) => {

    if (searchState.state === 'loading') {
      cancelTokenSource.cancel('Operation canceled by the user.');
      CancelToken = axios.CancelToken;
      cancelTokenSource = CancelToken.source();
    }

    if (!value) {
      onChangeSearchState({ state: undefined });
      return;
    }

    onChangeSearchState({ state: 'loading' });

    preflightAPICall(() => {

      const requests: AxiosPromise[] = [
        // search in quotes
        axios.post(apiConfig.QMT_SEARCH, {
          'data': {
            'Search': value,
            'Page': 1,
            'Size': RESULTS_TO_SHOW
          },
          'name': 'quotes-search'
        },
        {
          cancelToken: cancelTokenSource ? cancelTokenSource.token : undefined,
        }),
        // search in customer
        axios.post(apiConfig.QMT_SEARCH, {
          'data': {
            'Search': value,
            'Page': 1,
            'Size': RESULTS_TO_SHOW
          },
          'name': 'customers-search'
        },
        {
          cancelToken: cancelTokenSource ? cancelTokenSource.token : undefined,
        }),
      ];

      const requestsResolved = concurrentRequestsResolver(requests);

      axios.all(requestsResolved).then(axios.spread((quotes, customers) => {
        // @ts-ignore need to investigate how to handle such different types of response
        if (quotes.error) {
          const response = quotes as { error: Error };
          if (onError) {
            onError(response.error as AxiosError);
          }
          // @ts-ignore need to investigate how to handle such different types of response
        } else if (customers.error) {
          const response = customers as { error: Error };
          if (onError) {
            onError(response.error as AxiosError);
          }
          // @ts-ignore need to investigate how to handle such different types of response
        } else {
          const results = {
            // @ts-ignore need to investigate how to handle such different types of response
            'quote': quotes.data?.results || [],
            // @ts-ignore need to investigate how to handle such different types of response
            'customer': customers.data?.results || []
          };
          onChangeSearchState({
            state: 'success',
            data: {
              items: results ? createResults(results, value) : []
            }
          });
        }

      })).catch(onError);
    });

  };

  return (
    <Search
      className={`${className}__search testing__header-search`}
      name="search"
      response={searchState}
      handleChange={(name, value) => handleSearch(value as string)}
    />
  );
};

const createResults = (
  groups: TypeSearchResults,
  searchValue: string
) => {
  const results: JSX.Element[] = [];

  // @ts-ignore
  Object.keys(groups).forEach((groupName: TGeneralSearchItemType) => {
    const group = groups[groupName];
    if (group.length) {
      results.push(
        <SearchResultsItemGroup
          key={`search-result-group-${groupName}`}
        >
          <SearchResultsItemGroupHeader>
            <SearchResultsIcon icon={SEARCH_ITEMS_TYPE[groupName].icon} margin="right" />
            <SearchResultsItemGroupHeaderTitle>
              {translate({ key: SEARCH_ITEMS_TYPE[groupName].label })}
            </SearchResultsItemGroupHeaderTitle>
          </SearchResultsItemGroupHeader>
          {group.map((item, index: number) => {
            if (index < RESULTS_TO_SHOW) {
              switch (groupName) {
                case 'customer':
                  return <ItemCustomer key={`search-result-group-user-${item.id}`} item={item as TCustomer} inputValue={searchValue} />;
                case 'quote':
                  return <ItemQuote key={item.id} item={item as TQuoteData} />;
              }
            }
            return null;
          })}
        </SearchResultsItemGroup>
      );
    }
  });

  return results;
};

const ItemQuote = (
  {
    item,
  } : {
    item: TQuoteData,
  }
) => {

  const history = useHistory();

  return (
    <SearchResultsItem
      onClick={() => history.push(`/quotes/profile/${item.type}/${item.id}`)}
      className={CLOSE_DROP_CLASS}
    >
      <DefaultIcon icon={getQuoteTypeIconName(item.type as TQuoteType)} />
      <SearchResultsItemData>
        <SearchResultsItemDataTitle>
          {item.number}
        </SearchResultsItemDataTitle>
        <QuoteAdditionalData quote={item} />
      </SearchResultsItemData>
      <SearchResultsItemControls>
        <SearchResultsItemControlsItem>
          <SvgIcon icon="arrow_nav_right" />
        </SearchResultsItemControlsItem>
      </SearchResultsItemControls>
    </SearchResultsItem>
  );
};

const ItemCustomer = (
  {
    item,
    inputValue
  } : {
    item: TCustomer,
    inputValue: string,
  }
) => {
  const history = useHistory();

  return (
    <SearchResultsItem
      onClick={() => history.push(`/clients/profile/${item.id}`)}
      className={CLOSE_DROP_CLASS}
    >
      <DefaultIcon icon={getCustomerTypeIconName(item)} />
      <SearchResultsItemData>
        <SearchResultsItemDataTitle>
          {getCustomerName(item)}
        </SearchResultsItemDataTitle>
        <CustomerAdditionalData customer={item} inputValue={inputValue} />
      </SearchResultsItemData>
      <SearchResultsItemControls>
        <SearchResultsItemControlsItem>
          <SvgIcon icon="arrow_nav_right" />
        </SearchResultsItemControlsItem>
      </SearchResultsItemControls>
    </SearchResultsItem>
  );
};

const CustomerAdditionalData = ({ customer, inputValue }: { customer: TCustomer, inputValue?: string }) => {

  const additionalItems: JSX.Element[] = [];

  Object.keys(customer.props).forEach((item) => {
    if (ADDITIONAL_CUSTOMER_PROPS.includes(item)) {
      additionalItems.push(
        <SearchResultsItemDataAdditional key={`data-string-${item}`}>
          {translate({ key: ['email', 'phone'].includes(item) ? `contact.type.${item}` : `customer.${item}` })}&nbsp;
          <span
            dangerouslySetInnerHTML={{
              __html: highlight(customer.props[item as keyof Partial<TCustomer['props']>] as string, inputValue)
            }}
          />
        </SearchResultsItemDataAdditional>
      );
    }
  });


  if (additionalItems?.length) {
    return (
      <>
        {additionalItems}
      </>
    );
  }

  return null;
};

const QuoteAdditionalData = ({ quote }: { quote: TQuoteData }) => {

  const additionalItems: JSX.Element[] = [];

  const products = quote.products ? quote.products.map(product => product.product_tag) : null;
  const sortedProducts = products ? motorProducts.filter(i => products?.indexOf(i) > -1) : null;

  if (sortedProducts) {
    additionalItems.push(
      <SearchResultsItemDataAdditional key={`data-string-products`}>
        {sortedProducts.map(product => (<span>{translate({ key: `product.${product}` })}</span>))}
      </SearchResultsItemDataAdditional>
    );
  }

  if (quote.start_date) {
    additionalItems.push(
      <SearchResultsItemDataAdditional key={`data-string-start_date`}>
        {translate({ key: 'quote_motor.coverage.start_date' })}&nbsp;
        {new Date(quote.start_date).toLocaleString(DEFAULT_LOCALE)}
      </SearchResultsItemDataAdditional>
    );
  }

  if (quote.created_at) {
    additionalItems.push(
      <SearchResultsItemDataAdditional key={`data-string-created_at`}>
        {translate({ key: 'quote_motor.created_at' })}&nbsp;
        {new Date(quote.created_at).toLocaleString(DEFAULT_LOCALE)}
      </SearchResultsItemDataAdditional>
    );
  }

  if (additionalItems?.length) {
    return (
      <>
        {additionalItems}
      </>
    );
  }

  return null;
};

export default SearchComponent;
