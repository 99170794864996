import { translate } from 'utils';
import { INotification } from '@insly/qmt-reactjs-ui-lib/dist/components/Notification';

export const isIDDFailure = (
  showNotification: (props: Partial<INotification>) => void,
  isIDDNotValid: boolean,
  isIddRequired?: boolean,
  isIDDExist?: boolean,
) => {
  if (isIddRequired && isIDDNotValid) {
    if (isIDDExist) {
      showNotification({
        preset: 'error',
        message: translate({ key: 'idd.error.expired' }),
        autoHide: true,
      });
    } else {
      showNotification({
        preset: 'error',
        message: translate({ key: 'idd.error.required' }),
        autoHide: true,
      });
    }

    return true;
  } else {
    return false;
  }
};
