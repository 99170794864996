import { TParticipantAdd, TQuoteParticipantRole } from 'models';
import { SOLE_TRADER_CUSTOMER_TYPE } from 'config/consts';
import { CO_OWNER, PARTICIPANT_MAIN_ROLE, POLICY_HOLDER } from '../consts';
import {
  SOLE_TRADER_PARTICIPANT_ADDITIONAL_VALIDATION_FIELDS,
  SOLE_TRADER_PARTICIPANT_VALIDATION_FIELDS,
  SOLE_TRADER_POLICY_HOLDER_ADDITIONAL_VALIDATION_FIELDS,
  SOLE_TRADER_POLICY_HOLDER_VALIDATION_FIELDS
} from 'config/itemLists';


export const customerTypeValidation = (
  form: TParticipantAdd,
  customerType: 'policy_holder' | 'participant',
  roles?: TQuoteParticipantRole[],
) => {
  let requiredFields = [];
  const type = customerType === POLICY_HOLDER ? form.type : form.customerType;
  const companyType = customerType === POLICY_HOLDER ? form.company_type : form.companyType;
  const SOLE_TRADER_VALIDATION_FIELDS = customerType === POLICY_HOLDER
    ? SOLE_TRADER_POLICY_HOLDER_VALIDATION_FIELDS : SOLE_TRADER_PARTICIPANT_VALIDATION_FIELDS;
  const SOLE_TRADER_ADDITIONAL_VALIDATION_FIELDS = customerType === POLICY_HOLDER
    ? SOLE_TRADER_POLICY_HOLDER_ADDITIONAL_VALIDATION_FIELDS : SOLE_TRADER_PARTICIPANT_ADDITIONAL_VALIDATION_FIELDS;

  switch (type) {
    case 'individual':
      requiredFields.push('countryCode', 'resident');

      if (form.resident === false) {
        requiredFields.push('idCode');
      } else {
        requiredFields.push('pesel');
      }

      break;
    case 'company':
      requiredFields.push('regon');

      if (companyType === SOLE_TRADER_CUSTOMER_TYPE) {
        requiredFields.push(...SOLE_TRADER_VALIDATION_FIELDS);

        if (roles && (roles.includes(PARTICIPANT_MAIN_ROLE) || roles.includes(CO_OWNER))) {
          requiredFields.push(...SOLE_TRADER_ADDITIONAL_VALIDATION_FIELDS);
        }
      } else {
        if (form.nip) {
          requiredFields.push('nip');
        }

        if (customerType === POLICY_HOLDER) {
          requiredFields.push('company_name');
        } else {
          requiredFields.push('name', 'companyForm');
        }
      }

      break;
  }

  return requiredFields;
};
