import { preflightAPICall } from './preflightAPICall';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import fileDownload from 'js-file-download';
import fileTypeChecker from 'file-type-checker';

interface IProps {
  url: string,
  fileName: string,
  onError?: (error: AxiosError) => void,
  customConfig?: AxiosRequestConfig,
  onSuccess?: (data: Blob) => void,
}

export const getFile = ({
  url,
  fileName,
  onError,
  customConfig,
  onSuccess
}: IProps) => {

  const config: AxiosRequestConfig = {
    responseType: 'blob',
    ...customConfig
  };

  return (
    preflightAPICall(() => {
      axios.get(url, config).then(response => {
        const hasExtensionRegExp = /\.\w+$/;

        if (hasExtensionRegExp.test(fileName)) {
          downloadFile(response.data, fileName, onSuccess);
        } else {
          const reader = new FileReader();
          reader.onload = () => {
            const detectedFile = fileTypeChecker.detectFile(reader.result as ArrayBuffer);
            const extension = detectedFile?.extension;
            const filenameFromHeader = getFilename(response.headers['content-disposition']);
            let _fileName = filenameFromHeader || fileName;

            if (!filenameFromHeader && extension) {
              _fileName = `${fileName}.${extension}`;
            }

            downloadFile(response.data, _fileName, onSuccess);

          };

          reader.readAsArrayBuffer(response.data);
        }

      }).catch(error => {
        if (error.response) {
          // GET JSON CONTENT FROM BLOB
          return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
              error.response.data = reader.result;
              onError && onError(error);
              resolve(Promise.reject(error));
            };

            reader.onerror = () => reject(error);
            reader.readAsText(error.response.data);
          });
        }
      });
    })
  );
};

const downloadFile = (file: any, fileName: string, onSuccess: IProps['onSuccess']) => {
  fileDownload(file, fileName);

  if (onSuccess) {
    onSuccess(file);
  }
};

const getFilename = (filename?: string) => {
  if (filename) {
    const regex = /filename=([^;]+)/;
    const match = filename.match(regex);

    if (match) {
      return match[1];
    }
  }
};
