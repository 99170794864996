import React from 'react';
import { Message } from './styles';
import { DefaultIcon } from 'styles/common';

export const renderMessages = (items: string[]) => items.map((item, index) => (
  <Message key={index} className="js-common-error">
    <DefaultIcon icon="notification_success" />
    {item}
  </Message>
));
