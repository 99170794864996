import styled from 'styled-components';
import { DefaultIcon } from 'styles/common';

export const Docs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  border-top: 1px solid ${({ theme }) => theme.colors.strokeB};
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeB};
  padding: 10px 0;
  margin: 16px 0;
`;

export const Doc = styled.div`
  display: flex;
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  gap: 2px;
  align-items: center;
`;

export const DocData = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1
`;

export const DocIcon = styled(DefaultIcon)`
  width: 33px;
  height: 50px;
  stroke: ${({ theme }) => theme.colors.primary};
`;

export const DocTitle = styled.h4`
    ${({ theme }) => theme.text.normal};
    margin: 0;
`;
