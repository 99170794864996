import styled from 'styled-components';

export const DocumentsModal = styled.div`
  padding: 4px;
  height: 82vh;
  overflow: auto;
  .document_list {
    min-height: 100px;
  }
  .qmt-ui-table__head {
    padding: 4px;
  }
`;

export const Controls = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;
`;

export const DocNotice = styled.div`
    padding-top: 8px;
    ${({ theme }) => theme.text.small};
    color: ${({ theme }) => theme.colors.ternary};
`;
