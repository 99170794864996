import styled from 'styled-components';
import { SvgIcon } from '@insly/qmt-reactjs-ui-lib';

export const SHOW_MORE_EL_HEIGHT = 64;

export const ShowMoreStyled = styled.div<{ show?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  opacity: ${({ show }) => show ? 1 : 0};
  right: 67px;
  bottom: ${({ show }) => show ? `${SHOW_MORE_EL_HEIGHT}px` : 0};
  left: 40px;
  height: ${SHOW_MORE_EL_HEIGHT}px;
  background: ${({ theme }) => theme.colors.sidebarBg};
  transition: all 0.5s ease;
  border-top: 1px solid ${({ theme }) => theme.colors.strokeB};
  padding: 22px;
  cursor: pointer;
`;

export const ShowMoreText = styled.div`
  ${({ theme }) => theme.text.normal};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const ShowMoreIcon = styled(SvgIcon)`
  width: 16px;
  height: 16px;
  margin-left: 10px;
`;
