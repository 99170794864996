import { TError, TFetchStatus } from '../models';
import { processResponseErrors } from 'utils';
import axios from 'axios';
import apiConfig from './api';

export const API_TRANSLATION_URL = `${apiConfig.MASTER}/locale/lang`;

export let translations: Record<string, string> = {};

export const getTranslations = (
  {
    locale,
    updateNetworkStatus,
    onError
  } : {
    locale?: string,
    updateNetworkStatus?: (status: TFetchStatus) => void,
    onError?: (errors: TError[]) => void,
  }) => {
  locale = locale || localStorage.getItem('locale') || window._env_.DEFAULT_LOCALE;
  updateNetworkStatus && updateNetworkStatus('loading');
  axios.get(`${API_TRANSLATION_URL}/${locale}?type=0&type=3`).then(response => {
    if (response.data) {
      updateTranslations(response.data.translations);
      updateNetworkStatus && updateNetworkStatus('success');
    }
  }).catch(error => {
    console.error(error);
    onError && onError(processResponseErrors(error));
    updateNetworkStatus && updateNetworkStatus('failed');
  });

};

export const updateTranslations = (data: Record<string, string>) => {
  translations = data;
};

export const FALLBACK_TRANSLATIONS = {
  address_types_short: {
    'legal': 'Legal',
    'postal': 'Postal',
    'registered': 'Registered',
    'other': 'Other'
  },
  // buttons: {
  //   cancel: 'Anuluj',
  // },
  // address_form: {
  //   // type_placeholder: 'Choose Type',
  //   // region_placeholder: 'Choose Region',
  // },
  // dialog_remove_customer: {
  //   // body: 'Czy jesteś pewien, że chcesz usunąć osobę? Tej operacji nie można cofnąć.',
  //   // button_accept_title: 'Usuń',
  // },
  dialog_remove_quote: {
    // body: 'Delete Quote. This operation can\'t be reverted',
    // button_accept_title: 'Delete',
  },
  dialog_reset_password: {
    body: 'Reset user password. This operation can\'t be reverted',
    button_accept_title: 'Reset Password',
  },
  dialog_remove_document: {
    // body: 'Are you sure you want to remove document?',
    button_accept_title: 'Remove',
  },
  quote: {
    vehicle: {
      form_fields: {
        productionYear: 'Manufacture Year',
        fuelType: 'Fuel Type',
        bodyType: 'Bodywork',
        engineCapacity: 'Capacity',
        modification: 'Modification %s',
        make: 'Make',
        model: 'Model %s',
      }
    },
  },
  under_construction: {
    title: 'To miejsce jeszcze jest w budowie.',
    body: 'Systematycznie pracujemy nad nowymi funkcjami. Już niebawem lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  }
};
