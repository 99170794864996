import styled from 'styled-components';

export const IDDAddWrapper = styled.div`
  flex: 1;
  overflow: auto;
  padding: 16px;
`;

export const IDDAddInner = styled.div`
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
`;
