import { JSONSchemaProp } from 'models';
import { translate } from 'utils';

export const translateEnumItemInScheme = (prop: JSONSchemaProp, key: string, replace?: string[]) => {
  if (prop && prop.enum_translation_keys) {
    return translate({ key: prop.enum_translation_keys[key], fallback: typeof prop.enum_translation_keys[key] !== 'undefined' ? prop.enum_translation_keys[key] : key, replace });
  } else {
    return key;
  }
};
