import styled from 'styled-components';
import { Checkbox } from '@insly/qmt-reactjs-ui-lib';
import { DefaultIcon } from 'styles/common';

export const DefaultEmptyBlock = styled.div`
  display: none;
`;

export const FormControlCheckbox = styled(Checkbox)`
  .qmt-ui-tooltip-large {
    padding-left: 16px;
    display: flex;
    
    svg {
      width: 22px;
      height: 22px;
    }
  }
`;

export const FormControlIcon = styled(DefaultIcon)`
  width: 22px;
  height: 22px;
`;
