import axios from 'axios';
import { logout } from './logout';

export interface IOptions {
  authHeader?: string,
  broker?: string,
}

export const configAxios = (options: IOptions) => {
  if (options.authHeader) {
    axios.defaults.headers.common['Authorization'] = options.authHeader;
  }

  if (options.broker) {
    axios.defaults.headers.common['X-Broker'] = options.broker;
  }

  const userString = localStorage.getItem('user');

  if (userString && userString.indexOf('masquerade') >= 0) {
    delete axios.defaults.headers.common['X-Broker'];
  }

  axios.interceptors.response.use(response => response, error => {
    if (error.response && error.response?.status === 401) {
      logout();
    }

    return Promise.reject(error);
  });
};
