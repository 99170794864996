type TTabs = {
  key: string,
  title: string | JSX.Element,
}[];

export const generateTabs = (tabs: TTabs,
  activeTab: string,
  updateActiveTab: (tab: string) => void,
  isGDPREnabled?: boolean
) => tabs.filter(item => item.key === 'gdpr' ? isGDPREnabled : item).map((item) => ({
  name: item.key,
  title: item.title,
  active: activeTab === item.key,
  onChange: () => updateActiveTab(item.key)
}));
