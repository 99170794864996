import { TIDD } from '../app/Pages/IDD';

const IDD_VALIDITY_TIME = 86400000; //24h

export const isIddValid = (idd: TIDD | undefined, quoteCreatedAt?: string) => {
  if (idd && quoteCreatedAt) {
    const iddDate = idd.updated_at || idd.created_at;

    return {
      isIDDValid: (new Date(quoteCreatedAt).getTime() - new Date(iddDate).getTime()) <= IDD_VALIDITY_TIME,
      isIDDExist: true
    };
  }

  return {
    isIDDValid: false,
    isIDDExist: false
  };
};
