import { TUserData } from 'context/Auth';
import React, { useEffect, useState } from 'react';
import { useApp } from 'context/App';
import { getCookie, getUserRoles, logout, setCookie, translate, prepareAuthData } from 'utils';
import axios from 'axios';
import { DEFAULT_ERROR_MESSAGE } from 'config/api';
import { SelectBrokerContainer, SelectBrokerTitle } from 'app/Pages/Auth/styles';
import { Select } from '@insly/qmt-reactjs-ui-lib';
import { ControlsButton } from 'app/Pages/Quote/Profile/body/partials/ControlsNextStep/styles';

export const SelectBroker = ({ user, getUserFromMasterService }: { user?: TUserData, getUserFromMasterService: () => void }) => {
  const [broker, updateBroker] = useState<string | undefined>();

  const { showNotification } = useApp();
  const userRoles = getUserRoles(user);

  const getBrokerOptions = () => userRoles?.map(option => ({
    key: option.domain.props.agency || '',
    value: option.domain.meta.agency || option.domain.meta.tenant
  })) || [];

  useEffect(() => {
    if (userRoles?.length) {
      updateBroker(userRoles[0].domain.props.agency);
    }
  }, []);

  const handleSubmit = () => {
    const authenticationResult = getCookie('authentication_result');

    if (authenticationResult) {
      const requestObject = { ...JSON.parse(authenticationResult), broker };

      setCookie('authentication_result', JSON.stringify(prepareAuthData(requestObject)), 365, window._env_.COOKIE_DOMAIN);

      axios.defaults.headers.common['X-Broker'] = broker;

      getUserFromMasterService();
    } else {
      showNotification({
        message: translate({ key: DEFAULT_ERROR_MESSAGE }),
        preset: 'error',
        autoHide: false
      });
    }
  };

  return (
    <div>
      <SelectBrokerTitle>
        {translate({ key: 'sso.select_broker.title' })}
      </SelectBrokerTitle>
      <SelectBrokerContainer>
        <Select
          label={translate({ key: 'sso.select_broker.label' })}
          value={broker}
          options={getBrokerOptions() || []}
          handleChange={(key, value) => updateBroker(value as string)}
        />
        <ControlsButton
          size="l"
          onClick={handleSubmit}
        >
          {translate({ key: 'sso.select_broker.submit' })}
        </ControlsButton>
        <ControlsButton
          preset="secondary"
          size="l"
          onClick={logout}
        >
          Log out
        </ControlsButton>
      </SelectBrokerContainer>
    </div>
  );
};
