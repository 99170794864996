import { JSONSchema, JSONSchemaProp } from '../models';
import {
  getAgreementTag,
  getDescriptionTranslationKeyFromGDPRSchema,
  TAgreement
} from '../app/Pages/Clients/profile/body/GDPR';
import { sortSchemaPropertiesArrayByPriority } from './sortSchemaPropertiesByPriority';

export interface IGDPRAgreement {
  properties: JSONSchema,
}

export const processGDPRAgreementsFromSchema = (schema: JSONSchemaProp) => {
  const agreements = sortSchemaPropertiesArrayByPriority(schema.properties?.agreements.items as IGDPRAgreement[]);

  return agreements?.map(agreement => ({
    id: '',
    tag: getAgreementTag(agreement),
    status: 0,
    description: getDescriptionTranslationKeyFromGDPRSchema(agreements, getAgreementTag(agreement))
    // details: agreement.properties.details ? {
    //   email: {
    //     title: translate({ key: agreement.properties.details.properties?.email.title_translation_key }),
    //     checked: false,
    //   },
    //   phone: {
    //     title: translate({ key: agreement.properties.details.properties?.phone.title_translation_key }),
    //     checked: false,
    //   },
    //   sms: {
    //     title: translate({ key: agreement.properties.details.properties?.sms.title_translation_key }),
    //     checked: false,
    //   },
    // } : null
  } as TAgreement));
};
