import { TCustomerType, TError, TFetchStatus } from 'models';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { PageStyled } from '../common/styles';
import IDDList from './List';
import IDDProfile from './Profile';
import {
  errorNotification,
  getQueryParam,
  getUserProps,
  makeRequestQuery,
  preflightAPICall,
  processAPIError,
  setListPage,
  translate
} from 'utils';
import axios from 'axios';
import { API_IDD_QUESTIONNAIRE_URL } from 'app/Components/IDD';
import { setListPageSize } from '../common/List';
import { getFile } from 'utils/getFile';
import { useApp } from 'context/App';
import apiConfig from 'config/api';
import BulkUpload from '../common/QRBulkUpload';
import { TIDDSectionStep } from './models';
import { useAuth } from 'context/Auth';

export const LIST_IDD_NAME = 'idd';

export type TIDD = {
  customer: {
    type: TCustomerType,
    props: {
      company_type?: string,
    },
  },
  comment?: string,
  policy_numbers?: string[],
  schema_path: string,
  number: string,
  id: string,
  quote?: {
    id: string,
    number: string,
  },
  created_by_identity?: {
    name: string,
  },
  customer_data: {
    id: string,
    revision_id: string,
    name: string,
    pesel?: string,
    idcode?: string,
    regon?: string,
    phone?: string,
    email?: string,
  },
  quote_id?: string | null,
  answers: Record<string, any>,
  status: number,
  sent_at?: string | null,
  sent_by?: string | null,
  approved_at?: string | null,
  verification_code?: string | null,
  created_at: string,
  created_by?: string | null,
  updated_by?: string | null,
  updated_at?: string | null,
};

export interface IPageIDD {
  title: string,
  listData: {
    count?: number,
    total_results?: number,
    total_pages?: number,
    last_page?: number,
    current_page?: number,
    page?: number,
    results?: TIDD[],
    total?: number,
    errors?: TError[],
  },
}

const PageIDD = () => {
  const [dataFetchStatus, updateDataFetchStatus] = useState<TFetchStatus>('loading');
  const [iddErrorText, updateIddErrorText] = useState('');
  const [iddList, updateIddList] = useState<IPageIDD['listData']>({});
  const [searchQuery, updateSearchQuery] = useState<string>();
  const [page, updatePage] = useState<number>();
  const [status, updateStatus] = useState<string>();
  const [createdFrom, updateCreatedFrom] = useState<string>();
  const [createdTo, updateCreatedTo] = useState<string>();
  const [selectedIDD, updateSelectedIDD] = useState<TIDD | undefined>(undefined);
  const [domain] = useState<string | null>(getQueryParam('domain'));

  let history = useHistory();
  let location = useLocation();
  const { user } = useAuth();

  const { showNotification } = useApp();

  const {
    section,
    iddId,
  } = useParams<{ section: TIDDSectionStep, iddId: string }>();

  useEffect(() => {
    setListPage(page, updatePage);
  });

  useEffect(() => {
    updateDataFetchStatus(null);

    if (section === 'list' && page) {
      updateSelectedIDD(undefined);
      updateIddList({});
      getList();
    }

    if (section === 'profile') {
      if (!selectedIDD) {
        getItem();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section, page]);

  useEffect(() => {
    if (section === 'list' && (
      typeof searchQuery !== 'undefined'
      || typeof status !== 'undefined'
      || typeof createdFrom !== 'undefined'
      || typeof createdTo !== 'undefined'
    )) {
      history.replace(`${location.pathname}`);

      if (!page || page === 1) {
        getList();
      } else {
        updatePage(1);
      }

    } else {
      setListPage(page, updatePage);
    }
  }, [searchQuery, status, createdFrom, createdTo]);


  const handleSearchChange = (value: string) => {
    updateSearchQuery(value);
  };

  const getList = () => {
    updateDataFetchStatus('loading');
    let query: Record<string, string | number> = {};

    query.Size = setListPageSize();

    if (page) {
      query.Page = page;
    }

    if (searchQuery) {
      query.Search = searchQuery.trim();
    }

    if (status) {
      query.Status = status;
    }

    if (createdFrom) {
      query.CreatedFrom = createdFrom;
    }

    if (createdTo) {
      query.CreatedTo = createdTo;
    }

    if (domain) {
      query.Domain = domain;
    }

    preflightAPICall(() => {
      axios.post(apiConfig.QMT_SEARCH, {
        'data': query,
        'name': 'idd-search'
      }).then(response => {
        const data = response.data;
        onDataLoaded(data);

      }).catch(error => {

        onDataLoaded({
          results: [],
        });

        updateIddErrorText(() => processAPIError(error) as string);

      });
    });

  };

  const getItem = () => {
    updateDataFetchStatus('loading');
    preflightAPICall(() => {
      axios.get(`${API_IDD_QUESTIONNAIRE_URL}/${iddId}`).then(response => {
        const data = response.data;
        updateSelectedIDD(data);
        updateDataFetchStatus('success');
      }).catch(error => {
        updateIddErrorText(() => processAPIError(error) as string);
        updateDataFetchStatus('failed');
      });
    });
  };

  const onDataLoaded = (data: IPageIDD['listData']) => {
    updateIddList(data);
    updateDataFetchStatus('success');
  };

  const handleAddIDDClick = () => history.push(`/idd/add`);

  const handleSaveIdd = (idd?: TIDD) => {
    if (idd) {
      updateSelectedIDD(idd);
      history.replace(`/idd/profile/${idd.id}`);
    }
  };

  const handleCSVDownload = () => {
    if (dataFetchStatus === 'loading') {
      return;
    }

    updateDataFetchStatus('loading');
    let query: Record<string, string | number> = {};

    if (searchQuery) {
      query.search = searchQuery;
    }

    const queryString = makeRequestQuery(query);

    preflightAPICall(() => {
      getFile({
        url: `${API_IDD_QUESTIONNAIRE_URL}/download/csv${queryString}`,
        fileName: 'APK-report.csv',
        onError: (error) => {
          errorNotification(error, showNotification);
          updateDataFetchStatus('success');
        },
        onSuccess: () => {
          updateDataFetchStatus('success');
        }
      });
    });
  };

  const updateFilters = (name?: string, value?: string) => {
    switch (name) {
      case 'status':
        updateStatus(value ? value : '');
        break;
      case 'createdFrom':
        // bug in DatePicker it returns '' on month change and it calls getList by useEffect
        if (value === '' && (typeof createdFrom === 'undefined' || createdFrom === '')) {
          return;
        }
        updateCreatedFrom(value);
        break;
      case 'createdTo':
        // bug in DatePicker it returns '' on month change and it calls getList by useEffect
        if (value === '' && (typeof createdTo === 'undefined' || createdTo === '')) {
          return;
        }
        updateCreatedTo(value);
        break;
    }
  };

  const handleListChangePage = (page: number) => {
    history.replace(`${location.pathname}?page=${page}`);

    updatePage(page);
  };

  const handleScanIDDClick = () => {
    history.push(`/idd/bulk_upload`);
  };

  switch (section) {
    case 'add':
      return (
        <IDDProfile
          handleIddSave={(idd) => handleSaveIdd(idd)}
          initialIDDState="idd_requested"
        />
      );
    case 'profile':
      if (selectedIDD) {
        return (
          <IDDProfile
            customerData={selectedIDD.customer_data}
            idd={selectedIDD}
            showOnly={true}
            initialIDDState="exist"
            handleIddSave={(idd) => handleSaveIdd(idd)}
            handleIddCancel={() => history.goBack()}
          />
        );
      } else {
        return null;
      }
    case 'bulk_upload':
      return (
        <BulkUpload
          apiURL={`${apiConfig.IDD}/file/answers/signed`}
          goBackButtonTranslation="idd.mass_upload_qr_docs.button.back"
          successTranslation="idd.documents.mass_upload_qr_docs.success"
          uploaderTranslation="idd.documents.mass_upload_qr_docs.uploader"
        />
      );
    case 'list':
    default:
      return (
        <PageStyled>
          <IDDList
            title={translate({ key: 'idd.list.title' })}
            errorText={iddErrorText}
            step={section}
            dataStatus={dataFetchStatus}
            itemList={iddList}
            handleSearchChange={handleSearchChange}
            handleListChangePage={handleListChangePage}
            handleAddIDDClick={handleAddIDDClick}
            handleCSVDownload={handleCSVDownload}
            filters={{ status, createdFrom: createdFrom, createdTo: createdTo }}
            updateFilter={updateFilters}
            userFilters={getUserProps(user)?.custom_list_filters?.[LIST_IDD_NAME] || []}
            handleScanIDDClick={handleScanIDDClick}
            updateSelectedIDD={updateSelectedIDD}
            updateDataFetchStatus={updateDataFetchStatus}
            updateIddErrorText={updateIddErrorText}
            updateIddList={updateIddList}
          />
        </PageStyled>
      );
  }

};

export default PageIDD;
