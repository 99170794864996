import IDD, { setCustomerData, TIDDState } from 'app/Components/IDD';
import { TCustomer, TError, TOPTUserData, TQuoteParticipant } from 'models';
import React, { useEffect, useMemo, useState } from 'react';
import { generateTabs, preflightAPICall, scrollToFirstError, translate } from 'utils';
import {
  ParticipantRemoveIcon,
  ParticipantsBlockHead,
  ParticipantsSubheadline,
  ParticipantWrapper
} from 'app/Pages/Quote/Profile/body/Participants/styles';
import { ParticipantsSearch } from 'app/Components/ParticipantsSearch';
import { IDDAddInner, IDDAddWrapper } from './styles';
import { NavButtons, Button, Tabs } from '@insly/qmt-reactjs-ui-lib';
import { TIDD } from '../index';
import { PolicyHolderPersonOPT } from 'app/Components/PolicyHolderCard/styles';
import Drawer from 'app/Pages/Clients/drawer';
import axios, { AxiosError } from 'axios';
import { API_CUSTOMER_URL, getRequestStatus } from 'config/api';
import { PageStyled } from 'app/Pages/common/styles';
import { useHistory } from 'react-router-dom';
import { renderErrors } from 'app/Components/common/Error';
import Documents from './Documents';

const TABS = [
  {
    key: 'profile',
    title: 'idd.profile.tabs.profile',
  },
  {
    key: 'documents',
    title: 'policy.documents.title',
  },
];

interface IPageIddAdd {
  idd?: TIDD,
  customerData?: TIDD['customer_data'],
  handleIddCancel?: () => void,
  initialIDDState?: TIDDState,
  showOnly?: boolean,
  handleIddSave: (data?: TIDD) => void,
}

type TBody = IPageIddAdd & {
  currentTab?: string,
  customer: TIDD['customer_data'] | undefined,
  handleAddUserClick: () => void,
  errors: TError[],
  updateErrors: (errors: TError[]) => void,
  handleAddCustomer: (participant: TQuoteParticipant) => void,
  handleRemoveCustomer: () => void,
};

const PageIddAdd = ({
  handleIddSave,
  initialIDDState,
  customerData,
  idd,
  showOnly,
  handleIddCancel,
}: IPageIddAdd) => {

  const [currentTab, updateCurrentTab] = useState('profile');
  const [customer, updateCustomer] = useState<TIDD['customer_data'] | undefined>(customerData);
  const [drawerType, updateDrawerType] = useState('profile');
  const [errors, updateErrors] = useState<TError[]>([]);

  let history = useHistory();

  const updateActiveTab = (tab: string) => {
    if (tab !== currentTab) {
      updateCurrentTab(tab);
    }
  };

  useEffect(() => {
    if (errors?.length) {
      scrollToFirstError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(errors)]);

  const handleAddCustomer = (participant: TQuoteParticipant) => {
    if (errors?.length) {
      updateErrors([]);
    }
    updateCustomer(setCustomerData(participant.customer as TCustomer));
  };

  const handleAddUserClick = () => {
    if (errors?.length) {
      updateErrors([]);
    }
    updateDrawerType('add_user');
  };

  const handleRemoveCustomer = () => {
    updateCustomer(undefined);
  };

  const handleDrawerClose = () => {
    updateDrawerType('profile');
  };

  const handlePostCustomer = (requestObject: TCustomer, onError?: (error: AxiosError) => void) => {
    preflightAPICall(() => {
      axios.post(API_CUSTOMER_URL, requestObject).then(response => {
        getRequestStatus('customer', response.data.request_id, (data) => {
          updateCustomer(setCustomerData(data.response as TCustomer));
          handleDrawerClose();
        }, onError);
      }).catch(error => {
        if (onError) {
          onError(error);
        } else {
          console.error(error);
        }
      });
    });
  };

  const processedTabs = useMemo(() => TABS.map(tab => ({
    title: translate({ key: tab.title }),
    key: tab.key
  })), []);

  return (
    <PageStyled>
      <IDDAddWrapper id="js-idd-add-wrapper">
        <IDDAddInner>
          <NavButtons
            type="back"
            onClickBack={() => history.goBack()}
          />
          <ParticipantsSubheadline>{customerData ? translate({ key: 'idd.show_saved_idd' }) : translate({ key: 'idd.create_new_idd' })}</ParticipantsSubheadline>
          {idd?.id && (
            <Tabs
              items={generateTabs(processedTabs, currentTab, updateActiveTab)}
            />
          )}
          <Body
            handleIddSave={handleIddSave}
            initialIDDState={initialIDDState}
            customerData={customerData}
            idd={idd}
            showOnly={showOnly}
            handleIddCancel={handleIddCancel}
            currentTab={currentTab}
            customer={customer}
            handleAddUserClick={handleAddUserClick}
            errors={errors}
            updateErrors={updateErrors}
            handleAddCustomer={handleAddCustomer}
            handleRemoveCustomer={handleRemoveCustomer}
          />
        </IDDAddInner>
      </IDDAddWrapper>
      <Drawer
        type={drawerType}
        selectedUser={null}
        errors={[]}
        dataStatus=""
        handleDrawerClose={handleDrawerClose}
        updateStep={() => {}}
        step="list"
        handleSaveCustomer={() => {}}
        handleDeleteCustomer={() => {}}
        handlePostCustomer={handlePostCustomer}
      />
    </PageStyled>
  );
};

const Body = ({
  handleIddSave,
  initialIDDState,
  customerData,
  idd,
  showOnly,
  handleIddCancel,
  currentTab,
  customer,
  handleAddUserClick,
  errors,
  updateErrors,
  handleAddCustomer,
  handleRemoveCustomer,
}: TBody) => {

  switch (currentTab) {
    case 'documents':
      return <Documents refId={idd?.id as string} />;
    case 'profile':
    default:
      return (
        <Profile
          handleIddSave={handleIddSave}
          initialIDDState={initialIDDState}
          customerData={customerData}
          idd={idd}
          showOnly={showOnly}
          handleIddCancel={handleIddCancel}
          customer={customer}
          handleAddUserClick={handleAddUserClick}
          errors={errors}
          updateErrors={updateErrors}
          handleAddCustomer={handleAddCustomer}
          handleRemoveCustomer={handleRemoveCustomer}
        />
      );
  }


};

const Profile = ({
  handleIddSave,
  initialIDDState,
  customerData,
  idd,
  showOnly,
  handleIddCancel,
  customer,
  handleAddUserClick,
  errors,
  updateErrors,
  handleAddCustomer,
  handleRemoveCustomer,
}: TBody) => (
  <>
    {!customerData ? (
      <>
        <ParticipantsBlockHead>
          <ParticipantsSearch
            commonSearchPlaceholder={customer ? translate({ key: 'quote_motor.participants.change_policy_holder' }) : undefined}
            type="policy_holder" handleAddCustomer={handleAddCustomer} />
          <Button onClick={handleAddUserClick}>{translate({ key: 'customer.add_customer' })}</Button>
        </ParticipantsBlockHead>
        {errors.length ? (
          <>
            {renderErrors(errors)}
          </>
        ) : null}
      </>
    ) : null}
    {customer ? (
      <ParticipantWrapper policyHolder={true}>
        <PolicyHolderPersonOPT data={{
          headline: customer.name,
          additionalData: setAdditionalData(customer)
        } as TOPTUserData} />
        {!customerData ? <ParticipantRemoveIcon icon="trash" onClick={() => handleRemoveCustomer()} /> : null}
      </ParticipantWrapper>
    ) : null}
    <IDD
      customerData={customer}
      initialIDDState={initialIDDState}
      handleIddSave={handleIddSave}
      idd={idd}
      handleIddCancel={handleIddCancel}
      showOnly={showOnly}
      updateOutsideErrors={updateErrors}
    />
  </>
);

const setAdditionalData = (customer: TIDD['customer_data']) => {
  let additionalData = [{
    type: 'primary',
    label: customer.idcode ? `${translate({ key: 'customer.idCode' })}:` : `${translate({ key: 'customer.pesel' })}:`,
    value: customer.idcode ? customer.idcode : customer.pesel,
  }];

  if (customer.phone) {
    additionalData.push({
      type: 'primary',
      label: `${translate({ key: 'contact.type.phone' })}:`,
      value: customer.phone
    });
  }

  if (customer.email) {
    additionalData.push({
      type: 'primary',
      label: `${translate({ key: 'contact.type.email' })}:`,
      value: customer.email
    });
  }

  return [additionalData];
};

export default PageIddAdd;
