import axios, { AxiosError, CancelTokenSource } from 'axios';
import { preflightAPICall } from 'utils';

const apiConfig = {
  IDENTIFIER: window._env_.API_IDENTIFIER_URL,
  CUSTOMER: `${window._env_.API_CUSTOMER_SERVICE_URL}/t/${window._env_.TENANT}`,
  MASTER: `${window._env_.API_MASTER_SERVICE_URL}/t/${window._env_.TENANT}`,
  DOCUMENT: `${window._env_.API_DOC_SERVICE_URL}/t/${window._env_.TENANT}/documents`,
  QMT: `${window._env_.API_QMT_URL}/t/${window._env_.TENANT}`,
  QMT_SCHEMAS: `${window._env_.API_QMT_URL}/t/${window._env_.TENANT}/schemas`,
  QMT_POLICIES: `${window._env_.API_QMT_URL}/t/${window._env_.TENANT}/policies`,
  ZIP_CODE_SERVICE: window._env_.API_ZIP_CODE_SERVICE_URL,
  QMT_SEARCH: window._env_.API_SEARCH_URL,
  GDPR: window._env_.API_GDPR_URL,
  CALCLY: window._env_.API_CALCLY_URL,
  TENANT: window._env_.TENANT,
  VEHICLE_DB: window._env_.API_VEHICLE_DB,
  VEHICLE_DB_COMMON_LISTS: `${window._env_.API_VEHICLE_DB}/common/lists`,
  IDD: `${window._env_.API_IDD_SERVICE_URL}/t/${window._env_.TENANT}`,
  VEHICLE_HISTORY: window._env_.API_VEHICLE_HISTORY,
};

export const SENTRY_DSN = window._env_.SENTRY_DSN;
export const SENTRY_ENV = window._env_.SENTRY_ENV;

export const MASTER_DOC_URL = `${apiConfig.MASTER}/documents`;

export const API_MASTER_URL_USER = `${apiConfig.MASTER}/users`;

export const API_CUSTOMER_URL = `${apiConfig.CUSTOMER}/customers`;
export const API_CUSTOMER_PROFILE_URL = `${apiConfig.CUSTOMER}/customers/%USER_ID%`;
export const API_CUSTOMER_REQUEST_URL = `${apiConfig.CUSTOMER}/requests/%REQUEST_ID%`;

export const API_QMT_REQUEST_URL = `${apiConfig.QMT}/requests/%REQUEST_ID%`;

export const API_QUOTE = `${apiConfig.QMT}/quotes`;
export const API_QUOTE_FILTERED_BY_TYPE = `${apiConfig.QMT}/p/%QUOTE_TYPE%/quotes`;
export const API_QUOTE_CALCULATE = `${apiConfig.QMT}/p/%PRODUCT%/quotes/%QUOTE_ID%/%ACTION%/%INSURER_TAG%`;
export const API_QUOTE_OBJECT = `${apiConfig.QMT}/objects/%OBJECT_TAG%`;
export const API_QUOTE_ACTION = `${apiConfig.QMT}/p/%QUOTE_TYPE%/quotes/actions/%ACTION_TAG%`;
export const API_QUOTE_OBJECT_ACTION = `${apiConfig.QMT}/objects/%OBJECT_TAG%/actions/%ACTION_TAG%`;
export const API_QUOTE_PDF_URL = `${apiConfig.DOCUMENT}/%QUOTE_ID%/%DOC_ID%/file`;

export const API_PRODUCT_GROUP_SCHEME = `${apiConfig.QMT_SCHEMAS}/product-group/%PRODUCT_GROUP%`;
export const API_INSURER_GROUP_SCHEME = `${apiConfig.QMT_SCHEMAS}/insurer-group/%INSURER_GROUP%`;

export const API_MASTER_URL_USER_INSURERS = `${apiConfig.MASTER}/users/insurers`;

export const QMT_API_RESPONSE_OBJECT_INIT = 'qmt:object:init';
export const QMT_API_RESPONSE_OBJECT_UNLINK = 'qmt:object:unlink';
export const QMT_API_RESPONSE_OBJECT_PATCH = 'qmt:object:patch';
export const QMT_API_RESPONSE_QUOTE_UPDATE = 'qmt:quote:update';
export const QMT_API_RESPONSE_QUOTE_DELETE = 'qmt:object:delete';

export const QMT_API_RESPONSE_QUOTE_PRODUCT_UPDATE = 'qmt:quote:product:update';
export const QMT_API_RESPONSE_QUOTE_PRODUCT_ADD = 'qmt:quote:product:add';
export const QMT_API_RESPONSE_QUOTE_PRODUCT_DELETE = 'qmt:quote:product:delete';

export const QMT_API_RESPONSE_QUOTE_INSURER_PRODUCT_UPDATE = 'qmt:quote:insurer-product:update';
export const QMT_API_RESPONSE_QUOTE_INSURER_PRODUCT_DELETE = 'qmt:quote:insurer-product:delete';
export const QMT_API_RESPONSE_QUOTE_INSURER_PRODUCT_ADD = 'qmt:quote:insurer-product:add';

export const QMT_API_RESPONSE_QUOTE_INSURER_PRODUCT_LIST = 'qmt:quote:products:list';

export type TRequestType = 'customer' | 'quote';

// Common
export const DEFAULT_CURRENCY = window._env_.DEFAULT_CURRENCY;
export const DEFAULT_ERROR_MESSAGE = 'error.common';
export const DEFAULT_RESIDENT_COUNTRY_CODE = window._env_.DEFAULT_RESIDENT_COUNTRY_CODE;
export const DEFAULT_RESIDENT_COUNTRY = window._env_.DEFAULT_RESIDENT_COUNTRY;
export const DEFAULT_LOCALE = window._env_.DEFAULT_LOCALE;

// Policy
export const POLICY_DOCUMENT_TYPE_ID = '416b7365-6c20-6973-2062-657374212121';
export const POLICY_RELATED_DOCUMENT_TYPE_ID = '0c510cde-0db9-43d5-89ae-c6563f816b7a';

// GDPR
export const GDPR_DOCUMENT_TYPE_ID = '29425e7c-cfd3-4886-84bb-8eea9d5cdd98';

//Customer
export const CUSTOMER_DOCUMENT_TYPE_ID = 'e997bb23-5e1f-47a1-aabc-d357beab4c42';

const requestURLByType = {
  'customer': API_CUSTOMER_REQUEST_URL,
  'quote': API_QMT_REQUEST_URL
};

export const getRequestStatus = (
  requestType: TRequestType,
  request_id: string,
  onSuccess?: (data: Record<string, never>) => void,
  onError?: (error: AxiosError) => void,
  cancelTokenSource?: CancelTokenSource,
  delay: number = 0,
  onCancel?: () => void,
) => {

  if (delay > 0) {
    setTimeout(() => {
      getRequestStatusCall(requestType, request_id, onSuccess, onError, cancelTokenSource, delay, onCancel);
    }, delay * 100 <= 1000 ? delay * 100 : 1000);
  } else {
    getRequestStatusCall(requestType, request_id, onSuccess, onError, cancelTokenSource, delay, onCancel);
  }

};

const getRequestStatusCall = (
  requestType: TRequestType,
  request_id: string,
  onSuccess?: (data: Record<string, never>) => void,
  onError?: (error: AxiosError) => void,
  cancelTokenSource?: CancelTokenSource,
  delay: number = 0,
  onCancel?: () => void,
) => {
  preflightAPICall(() => {
    axios.get(requestURLByType[requestType].replace('%REQUEST_ID%', request_id), {
      cancelToken: cancelTokenSource ? cancelTokenSource.token : undefined,
    })
      .then(response => {
        if ([200, 201, 204].includes(response.status)) {
          if (onSuccess) {
            onSuccess(response.data);
          }
        } else if (response.status === 202) {
          getRequestStatus(requestType, request_id, onSuccess, onError, cancelTokenSource, delay + 1, onCancel);
        }
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          onCancel && onCancel();
          // console.log('Request canceled', error.message);
        } else if (onError) {
          console.error(error);
          onError(error);
        } else {
          console.error(error);
        }
      });
  });
};

export default apiConfig;
