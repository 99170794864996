import styled from 'styled-components';
import { Select, SvgIcon } from '@insly/qmt-reactjs-ui-lib';
import { TQuoteStatus } from '../models';
import { DefaultIcon } from 'styles/common';
import { ColData, TableStyled, TitleWrapper } from 'app/Pages/common/List/styles';

export const SelectSource = styled(Select)`
  border: none;
  height: auto;
  max-width: 176px;
  .qmt-ui-dropdown__picker {
    padding: 0;
  }
   .qmt-ui-select__placeholder {
     color: inherit;
     font-style: normal;
   }
`;

export const TableQuotes = styled(TableStyled)`
  .qmt-ui-table__head {
    padding: 12px 6px;
    margin-bottom: 20px;
  }
  
  .quote_source--online {
    background-color: ${({ theme }) => theme.colors.hoverGray};
    &:hover {
      background-color: ${({ theme }) => theme.colors.sidebarBg};
    }
  }
  
  .qmt-ui-date-picker {
    input::placeholder {
      color: inherit;
      font-style: normal;
    }
  }
  
  ${SelectSource} {
    max-width: 160px;
  }
  
`;

export const ListQuoteTitle = styled(TitleWrapper)`
  margin-bottom: 6px;
`;

export const ColQuoteType = styled(SvgIcon)`
  width: 24px;
  height: 24px;
  margin-right: 16px;
  fill: ${({ theme }) => theme.colors.black};
  stroke: ${({ theme }) => theme.colors.black};
`;

export const ColQuoteStatus = styled.div<{ status?: TQuoteStatus }>`
  width: 8px;
  height: 8px;
  margin-right: 24px;
  border-radius: 50%;
  background-color: ${({ theme, status }) =>
    status && theme.colors.quote_status[status] ?
      theme.colors.quote_status[status] :
      theme.colors.quote_status['unknown']};
`;

export const ColQuoteSource = styled.div`
  width: 18px;
`;

export const ColVehicle = styled(ColData)`
  padding-left: 6px;
  padding-right: 6px;
  line-height: 1.5;
`;

export const SourceIcon = styled(DefaultIcon)`
  width: 18px;
  height: 18px;
`;

export const ColQuoteProducts = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  padding-right: 10px;
`;

export const ColDeleteStyled = styled.div<{ isPolicyIssued?: boolean }>`
  padding-left: 16px;
  visibility: ${({ isPolicyIssued }) => isPolicyIssued && 'hidden'};
`;
