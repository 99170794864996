import React from 'react';
import {
  ListPagination,
  PaginationControl,
  PaginationData,
  PaginationDataWithControls,
  PaginationDelimiter
} from './styles';

const Pagination = (list: {page: number, lastPage: number, resultsNumber?: number, totalResults?: number}, handlePageChange: (page: number) => void) => (
  <ListPagination className="pagination" data-iframe-height>
    {(list.totalResults && list.resultsNumber) ? (
      <PaginationData>{countResults(list.page, list.lastPage, list.resultsNumber, list.totalResults)} z {list.totalResults}</PaginationData>
    ) : null}
    <PaginationDelimiter />
    <PaginationControl
      disabled={list.page === 1}
      direction="prev"
      icon="arrow_left"
      onClick={list.page && list.page > 1 ? () => handlePageChange(list.page as number - 1) : undefined}
    />
    <PaginationDataWithControls>{list.page} z {list.lastPage}</PaginationDataWithControls>
    <PaginationControl
      disabled={list.page === list.lastPage}
      direction="next"
      icon="arrow_left"
      onClick={list.page && list.page < list.lastPage ? () => handlePageChange(list.page as number + 1) : undefined}
    />
  </ListPagination>
);

const countResults = (page: number, lastPage: number, resultsNumber: number, totalResults: number) => {
  if (page > 1) {
    if (page < lastPage) {
      const showedResults = resultsNumber * page;
      const startFrom = showedResults - resultsNumber + 1; // show only included results

      return `${startFrom}-${showedResults}`;
    } else {
      const startFrom = totalResults - resultsNumber + 1; // show only included results

      return `${startFrom}-${totalResults}`;
    }
  }

  return resultsNumber;
};

export default Pagination;
