import { TError } from '../models';
import { AxiosError } from 'axios';
import { translate } from './translate';
import { DEFAULT_ERROR_MESSAGE } from '../config/api';

type TProcessorType = 'calcly' | 'cepik';

const processCalclyErrorMessage = (item: TError) => `${item.code ? `${translate({ key: `error.${item.code}`, fallback: item.code })}:` : ''} ${item.message || ''}`;

const processCepikErrorMessage = (item: TError) => {
  const errorCodeTranslated = translate({ key: `error.cepik_${item.code}`, fallback: item.code });
  return item.code && item.code !== errorCodeTranslated ? errorCodeTranslated : item.message || item.code;
};

const processErrorByType = (processorType: TProcessorType, item: TError) => {
  switch (processorType) {
    case ('calcly'):
      return processCalclyErrorMessage(item);
    case ('cepik'):
      return processCepikErrorMessage(item);
  }
};

export const processCalclyErrorsFromResponse = (error: AxiosError, isArray: boolean = false, processorType: TProcessorType = 'calcly') => {
  const processedErrors = error.response?.data.errors ? processCalclyError(error.response?.data.errors) : [];

  if (isArray) {
    return processedErrors?.length ? processedErrors : [{ code: '', message: translate({ key: DEFAULT_ERROR_MESSAGE }) }];
  } else {
    if (processedErrors?.length) {
      return processedErrors
        .map(item => processErrorByType(processorType, item))
        .join('\n');
    }

    return translate({ key: DEFAULT_ERROR_MESSAGE });
  }

};

export const processCalclyError = (errors: any) => {
  let processedErrors: TError[] = [];
  if (Array.isArray(errors)) {
    processedErrors = processErrorsArray(errors);
  } else if (typeof errors === 'object') {
    if (errors.error) {
      processedErrors.push({
        code: '',
        message: errors.error
      });
    } else if (errors.errors) {
      processedErrors = processErrorsArray(errors);
    } else if (errors.reasons) {
      processedErrors = Object.keys(errors.reasons).map(errorKey => (
        {
          code: errorKey,
          message: errors.reasons[errorKey]
        }
      ));
    } else if (Object.keys(errors).length) {
      processedErrors = Object.keys(errors).map(errorKey => (
        {
          code: errorKey,
          message: errors[errorKey]
        }
      ));
    }
  }

  return processedErrors;
};

const processErrorsArray = (errors: string[] | Record<string, never>[]) => {
  const processedErrors: TError[] = [];
  errors.forEach(error => {
    if (typeof error === 'string') {
      processedErrors.push({
        code: '',
        message: error
      });
    } else if (typeof error === 'object') {
      Object.keys(error).forEach(key => {
        processedErrors.push({
          code: key,
          message: error.key
        });
      });
    }
  });

  return processedErrors;
};
