import styled from 'styled-components';
import { Checkbox } from '@insly/qmt-reactjs-ui-lib';
import {
  AGREEMENT_PARAMETER_TYPE_POINT,
  AGREEMENT_PARAMETER_TYPE_SUBHEADER,
  AGREEMENT_PARAMETER_TYPE_TEXT
} from './consts';

const FONT_WEIGHT_AGREEMENT_PARAMETER_TYPES = [AGREEMENT_PARAMETER_TYPE_POINT, AGREEMENT_PARAMETER_TYPE_TEXT];
const COLOR_AGREEMENT_PARAMETER_TYPES = [AGREEMENT_PARAMETER_TYPE_POINT, AGREEMENT_PARAMETER_TYPE_TEXT];

export const FormWrapper = styled.div`
  padding-top: 24px;
`;

export const AgreementCheckbox = styled(Checkbox)<{ parameterType?: string }>`
  align-items: flex-start;
  flex: 1;
  .qmt-ui-checkbox-label {
    font-size: 13px;
  }
  .qmt-ui-checkbox-label {
    padding-top: 4px;
  }
  margin-left: ${({ parameterType }) => parameterType === AGREEMENT_PARAMETER_TYPE_POINT && '20px'};
`;

export const AgreementTitle = styled.h3<{ parameterType: string }>`
  line-height: normal;
  align-self: center;
  font-family: ${({ theme }) => theme.font.light };
  font-weight: ${({ parameterType }) => FONT_WEIGHT_AGREEMENT_PARAMETER_TYPES.includes(parameterType) && 'normal' };
  color: ${({ parameterType }) => COLOR_AGREEMENT_PARAMETER_TYPES.includes(parameterType) && 'rgb(119, 119, 119)'};
  font-size: ${({ parameterType }) => {
    switch (parameterType) {
      case AGREEMENT_PARAMETER_TYPE_TEXT:
      case AGREEMENT_PARAMETER_TYPE_POINT:
        return '14px';
      case AGREEMENT_PARAMETER_TYPE_SUBHEADER:
        return '16px';
      default:
        return '18px';
    }
  }};
`;

export const Statement = styled.div`
  position: relative;
  gap: 10px;
  display: flex;
`;

export const StatementDot = styled.div`
  background-color: ${({ theme }) => theme.colors.textSecondary};
  border: 1px solid transparent;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  flex-shrink: 0;
  margin-top: 6px;
`;

export const SecondaryAgreementsWrapper = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: ${({ expanded }) => expanded ? null: 0};
  overflow: hidden;
  padding: ${({ expanded }) => expanded ? '20px 0 10px 0' : '0 0 0 20px'};
`;

export const AgreementWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const InspectionWrapper = styled.div`
  line-height: normal;
  font-family: ${({ theme }) => theme.font.light};
  display: grid;
  gap: 10px;
`;

export const InspectionText = styled.p`
  font-size: 14px;
  a {
    color: ${({ theme }) => theme.colors.primary };
  }
`;

export const InspectionSubTitle = styled.h4`
`;
