import { TUserProfile } from 'app/Pages/Clients/common';
import { getDOBFromPesel, getGenderFromPesel, isPeselValid } from './pesel';
import { TParticipantAdd } from 'models';
import { INotification } from '@insly/qmt-reactjs-ui-lib/dist/components/Notification';
import { translate } from './translate';

export const setCustomerDataFromPesel = (pesel: string, handleChangeMultiple: (form: Partial<TUserProfile>) => void, participant?: boolean, showNotification?: (props: Partial<INotification>) => void) => {
  if (pesel?.length !== 11) return;

  if (isPeselValid(pesel)) {
    const dob = getDOBFromPesel(pesel);
    const gender = getGenderFromPesel(pesel);

    let dataObject: Partial<TParticipantAdd> = {};

    if (dob) {
      if (participant) {
        dataObject.birthDate = dob;
      } else {
        dataObject.birthday = dob;
      }
    }

    if (gender) {
      if (participant) {
        dataObject.gender = gender;
      } else {
        dataObject.sex = gender;
      }
    }

    handleChangeMultiple(dataObject);
  } else {
    if (showNotification) {
      showNotification({
        message: translate({ key: 'error.incorrect_pesel' }),
        preset: 'error',
        autoHide: true
      });
    }
  }
};
