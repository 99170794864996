import styled from 'styled-components';
import { ContactsDataBlockTitle, DefaultIcon, SMALL_SCREEN_WIDTH } from 'styles/common';
import { TableStyled } from 'app/Pages/common/List/styles';
import { ContentControlsRow, IconDefault } from 'app/Pages/Quote/Profile/body/partials/ControlsNextStep/styles';
import { Button } from '@insly/qmt-reactjs-ui-lib';

export const AgreementDataBlock = styled.div`
  max-width: 428px;
`;

export const AgreementItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AgreementWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  position: relative;
`;

export const AgreementsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const AgreementsHeaderTitle = styled(ContactsDataBlockTitle)`
  margin-bottom: 0;
`;

export const AgreementsHeaderControls = styled.div`
  display: flex;
  column-gap: 12px;
`;

export const AgreementUnderliner = styled.div`
  width: 25px;
  height: 36px;
  border-left: ${({ theme }) => `3px solid ${theme.colors.primary}`};
  border-bottom: ${({ theme }) => `3px solid ${theme.colors.primary}`};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
  position: absolute;
  top: 35px;
  left: 11px;
`;

export const AgreementIcon = styled(DefaultIcon)`
  //padding-top: 2.5px;
`;

export const AgreementNameTitle = styled.span<{ isDisabled?: boolean }>`
  color: ${({ theme, isDisabled }) => isDisabled ? theme.colors.strokeB : theme.colors.primary};
  width: 100%;
`;

export const StatusTitle = styled.span`
  
`;

export const EditStatusIcon = styled(DefaultIcon)`
  &:hover {
    stroke: ${({ theme }) => theme.colors.primary};
  }
`;

export const AgreementNameCol = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 16px;
  gap: 16px;
`;

export const StatusCol = styled.div<{ isRelative?: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 16px;
  position: ${({ isRelative }) => isRelative && 'relative'};
`;

export const GDPRTable = styled(TableStyled)`
  overflow: visible;
  flex: unset;
  
  .qmt-ui-table__head {
    border-top: 0;
    border-bottom: 0;
    padding: 0;
  }

  .qmt-ui-table__body {
    overflow: visible;
  }
`;

export const GDPRTitle = styled.h3`
  padding: 36px 0;
  ${({ theme }) => theme.text.h3};
  @media (max-width: ${SMALL_SCREEN_WIDTH}) {
    font-size: 95%;
    padding: 18px 0;
  }
`;

export const GDPRControlsRow = styled(ContentControlsRow)`
  margin-top: 16px;
  padding-bottom: 0;
`;

export const GDPRControlsIcon = styled(IconDefault)`
  stroke: ${({ theme }) => theme.colors.white};
  height: 18px;
  width: 18px;
`;

export const GDPRControlsButton = styled(Button)`
  &.qmt-ui-button--s {
    padding: 6px 16px;
  }
`;

export const StatusList = styled.ul<{ isIDD?: boolean }>`
  position: absolute;
  z-index: 1;
  left: ${({ isIDD }) => isIDD ? '56px' : 0};
  top: ${({ isIDD }) => !isIDD && '-18px'};
  bottom: ${({ isIDD }) => isIDD && '-10px'};
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(0, 0, 0);
  border-radius: 5px;
  box-shadow: rgba(148, 119, 90, 0.24) -6px 6px 0px;
  white-space:nowrap;
`;

export const StatusListItem = styled.li`
  cursor: pointer;
  padding: 8px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.strokeB}`};
  
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
  
  ${StatusTitle} {
    font-size: 12px;
  }
  ${DefaultIcon} {
    height: 18px;
    width: 18px;
  }
  ${StatusCol} {
    column-gap: 8px;
  }
`;
