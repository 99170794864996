import styled from 'styled-components';
import { Button, SvgIcon, Table, Tooltip } from '@insly/qmt-reactjs-ui-lib';
import { TStandardSectionStep } from 'models';
import { NavLink } from 'react-router-dom';

const stepOpacityTime = '0.5s';

export const Col = styled.div``;

export const ColLabel = styled.div`
  ${({ theme }) => theme.text.label};
  color: ${({ theme }) => theme.colors.subtle};
  text-transform: uppercase;
`;

export const ColData = styled.div<{ onClick?: any, centered?: boolean}>`
  ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.colors.textSecondary};
  cursor: ${({ onClick }) => onClick ? 'pointer' : null};
  text-align: ${({ centered }) => centered ? 'center' : null};
  flex: ${({ centered }) => centered ? 1 : null};
  text-decoration: none;
`;

export const IconAddUser = styled(SvgIcon)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  stroke: ${({ theme }) => theme.colors.black};
`;

export const List = styled.div<{ step?: TStandardSectionStep }>`
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 40px 40px 0;
  opacity: ${({ step }) => step === 'list' ? 1 : 0};
  transition: opacity ${stepOpacityTime} ease;
`;

export const ListInner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 1080px;
`;

export const TableStyled = styled(Table)`
  flex: 1;
  overflow: hidden;
  .qmt-ui-table-row {
    height: 60px;
  }
`;

export const Title = styled.h1`
  ${({ theme }) => theme.text.headerH1};
  line-height: 24px;
  flex: 1;
`;

export const SubTitle = styled.h2`
  ${({ theme }) => theme.text.headerH3};
  line-height: 24px;
  flex: 1;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 66px;
`;

export const MultiDateWrapper = styled.div`
  
`;

export const MultiDateWrapperData = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const MultiDateWrapperLabel = styled.div`
  ${({ theme }) => theme.text.label};
  margin-bottom: 2px;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  label {
    height: auto;
    padding: 8px;
    //max-width: 110px;
  }
`;

export const DateWrapperDivider = styled.div`
  width: 10px;
  border: 1px solid ${({ theme }) => theme.colors.textSecondary};
`;

export const PrimaryNumber = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
`;

export const PolicyNumberColData = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

export const ControlsButton = styled(Button)`
  height: 34px;
  width: 150px;
`;

export const CustomerNameCol = styled(ColData)`
  display: flex;
  align-items: center;
  max-width: 100%;
`;

export const CustomerNameColTitle = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const CustomerNameTooltip = styled(Tooltip)`
  max-width: 100%;
  .qmt-ui-tooltip__picker {
    overflow: hidden;
    max-width: 100%;
  }
`;
