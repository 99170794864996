import { useAuth } from 'context/Auth';
import React, { useRef } from 'react';
import {
  checkFileRestrictions,
  createTempId,
  preflightAPICall,
  serverDateTimeFormatter,
  translate
} from 'utils';
import axios from 'axios';
import apiConfig, { MASTER_DOC_URL } from 'config/api';
import { TDoc } from 'models';
import {
  UploaderDocuments,
  UploaderDocumentsIcon,
  UploaderDocumentsString
} from './styles';
import { UploaderInput } from 'styles/common';
import { useApp } from 'context/App';

const DRAG_STYLE_TIMEOUT_DELAY = 800;
let removeDragStyleTimeout: ReturnType<typeof setTimeout>;

export type TPicker = {
  uploadInput: React.RefObject<HTMLInputElement>,
  onUpload: (files: FileList) => void,
};

interface IDocumentUploader {
  refId: string,
  handleAddDoc: (item: TDoc) => void,
  handleUpdateTempDoc: (doc: TDoc) => void,
  typeId: string,
  Picker: React.FunctionComponent<TPicker>,
}

const DocsUploader = ({ refId, handleAddDoc, handleUpdateTempDoc, typeId, Picker }: IDocumentUploader) => {
  const { user } = useAuth();
  const { showNotification } = useApp();

  const uploadInput = useRef<HTMLInputElement>(null);

  const onUpload = (files: FileList, refId: string) => {
    checkFileRestrictions({
      file: files[0],
      onSuccess: () => processFile(files[0], refId),
      onError: (message) => showNotification({
        message: message,
        preset: 'error',
        autoHide: false,
      })
    });
  };

  const processFile = (file: File, refId: string) => {
    const formData = new FormData();
    formData.append('file', file);

    const name = file.name;
    const lastDot = name.lastIndexOf('.');
    const fileName = name.substring(0, lastDot);

    let document: TDoc = {
      //@ts-ignore
      'type_id': typeId,
      'file_name': name,
      'title': fileName,
      'attached_at': serverDateTimeFormatter(new Date()),
      'created_user_name': user?.props.name,
      'props': {
        size: `${file.size}`,
      }
    };

    let tempDocument: TDoc = {
      ...document,
      temp_id: createTempId(),
      progress: 0,
    };

    formData.append('data', JSON.stringify(document));

    handleAddDoc(tempDocument);

    preflightAPICall(() => {
      axios.post(`${MASTER_DOC_URL}/${refId}`, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: progressEvent => {
            handleUpdateTempDoc({
              ...tempDocument,
              progress: progressEvent.loaded / progressEvent.total * 100
            });
          },
        }).then(response => {

        if (response.status === 207) {
          handleUpdateTempDoc({
            ...tempDocument,
            progress: null,
            errors: response.data?.errors
          });
          return;
        }

        document = {
          ...document,
          ...response.data,
          'download_path': `${apiConfig.DOCUMENT}/${refId}/${response.data.id}/file`,
          'download_method': 'GET',
        };

        handleUpdateTempDoc({
          ...tempDocument,
          ...document,
          progress: null
        });

      }).catch(error => {
        if (error.response) {
          handleUpdateTempDoc({
            ...tempDocument,
            progress: null,
            errors: error.response.data ? (error.response.data?.errors ? error.response.data?.errors : [error.response.data]) : [{ code: '', message: 'error.common' }]
          });
        }
      });

    });
  };

  return (
    <Picker
      uploadInput={uploadInput}
      onUpload={(files) => onUpload(files, refId)}
    />
  );
};

export const PickerDefault = ({
  uploadInput,
  onUpload
}: TPicker & {
}) => {
  const onDragOver = (event: React.MouseEvent) => {
    event.preventDefault();
    if (uploadInput.current !== null && uploadInput.current.parentNode) {
      const element = uploadInput.current.parentNode as HTMLElement;
      element.classList.add('drag-over');
    }
  };

  const onDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    clearTimeout(removeDragStyleTimeout);
    removeDragStyleTimeout = setTimeout(() => {
      if (uploadInput.current !== null && uploadInput.current.parentNode) {
        const element = uploadInput.current.parentNode as HTMLElement;
        element.classList.remove('drag-over');
      }
    }, DRAG_STYLE_TIMEOUT_DELAY);
  };

  const onDrop = (event: React.DragEvent) => {
    event.preventDefault();
    onUploadDrop(event);
    if (uploadInput.current !== null && uploadInput.current.parentNode) {
      const element = uploadInput.current.parentNode as HTMLElement;
      element.classList.remove('drag-over');
    }
  };

  const onUploadDrop = (event: React.DragEvent) => {
    if (event.dataTransfer && event.dataTransfer.files.length !== 0) {
      onUpload(event.dataTransfer.files);
    }
  };

  return (
    <UploaderDocuments
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={() => {
        if (uploadInput.current !== null) {
          uploadInput.current.click();
        }
      }}
    >
      <UploaderInput
        type="file"
        ref={uploadInput}
        name="file"
        multiple={false}
        onChange={() => {
          if (uploadInput.current !== null && uploadInput.current.files) {
            onUpload(uploadInput.current.files);
          }
        }}
      />
      <UploaderDocumentsString>
        {translate({ key: 'customer.documents.drop_file' })} <UploaderDocumentsIcon icon="upload" margin="left" />
      </UploaderDocumentsString>
      <UploaderDocumentsString>
        <UploaderDocumentsIcon icon="folder" margin="right" /> {translate({ key: 'customer.documents.browse_file' })}
      </UploaderDocumentsString>
    </UploaderDocuments>
  );
};


export default DocsUploader;
