import React from 'react';
import { DashboardImage, PageDashboard, UnderConstructionStyled } from './styles';
import dashboardImage from './dashboard.png';

const Home = () => (
  <PageDashboard>
    <UnderConstructionStyled />
    <DashboardImage src={dashboardImage} />
  </PageDashboard>
);

export default Home;
