import {
  CustomerNameCol, CustomerNameColTitle,
  CustomerNameTooltip,
  DateWrapper,
  MultiDateWrapper,
  MultiDateWrapperData,
  MultiDateWrapperLabel
} from './styles';
import { dateToDbDate, getCustomerName, getCustomerTypeIconName, isIframe, translate } from 'utils';
import React from 'react';
import { IDatePicker } from '@insly/qmt-reactjs-ui-lib/dist/components/DatePicker';
import { DatePickerPolicy } from 'app/Pages/Policy/List/styles';
import { TCustomer } from 'models';
import { NavLink } from 'react-router-dom';
import { ColUserType } from 'app/Pages/Clients/list/styles';

const PAGE_SIZE = 50;
const LIST_ITEM_HEIGHT = 60;
const LIST_PAGINATION_HEIGHT = 66;

export const setListPageSize = (
  pageSize: number = PAGE_SIZE,
  listPaginationHeight: number = LIST_PAGINATION_HEIGHT,
  listItemHeight: number = LIST_ITEM_HEIGHT
) => {
  const listElement = document.querySelector('.qmt-ui-table__body');
  const paginationContainer = document.querySelector('.pagination');
  const bottomPadding = paginationContainer ? 0 : listPaginationHeight;

  if (listElement) {
    return +((listElement.clientHeight - bottomPadding) / listItemHeight).toFixed();
  }

  return pageSize;
};

export const renderMultiDateWrapper = (
  from: string,
  to: string,
  translationKeyLabel: string,
  translationKeyFrom: string,
  translationKeyTo: string,
  filters: Record<string, any>,
  updateFilter: (name: string, value: string) => void) =>
  (
    <MultiDateWrapper>
      <MultiDateWrapperLabel>{translate({ key: translationKeyLabel })}</MultiDateWrapperLabel>
      <MultiDateWrapperData>
        {renderDateFilter({
          name: from,
          value: filters[from],
          translationKey: translationKeyFrom,
          updateFilter,
          options: {
            maxDate: filters[to] ? new Date(filters[to] as string) : undefined
          }
        })}
-
        {renderDateFilter({
          name: to,
          value: filters[to],
          translationKey: translationKeyTo,
          updateFilter,
          options: {
            minDate: filters[from] ? new Date(filters[from] as string) : undefined
          }
        })}
      </MultiDateWrapperData>
    </MultiDateWrapper>
  );

export const renderDateFilter = (
  props : {
    name: string,
    value?: string,
    translationKey: string,
    updateFilter: (name: string, value: string) => void,
    options?: Partial<IDatePicker>,
    translationKeyLabel?: string,
  }
) => {
  const { name, value = '', translationKey, updateFilter, options, translationKeyLabel } = props;

  return (
    <DateWrapper key={name}>
      {translationKeyLabel && <MultiDateWrapperLabel>{translate({ key: translationKeyLabel })}</MultiDateWrapperLabel>}
      <DatePickerPolicy
        name={name}
        value={value ? new Date(value) : null}
        placeholder={translate({ key: translationKey })}
        handleChange={(name, innerValue) => {
          if (innerValue) {
            updateFilter(name, dateToDbDate(innerValue) as string);
          } else {
            updateFilter(name, '');
          }
        }}
        isFixed={true}
        {...options}
      />
    </DateWrapper>
  );
};

export const renderColCustomerName = (customer: TCustomer) => (
  <CustomerNameWithTooltip
    name={getCustomerName(customer)}
    icon={getCustomerTypeIconName(customer as TCustomer)}
    id={customer?.id}
  />
);

export const CustomerNameWithTooltip = ({ name, id, icon }: {name: string, id?: string, icon: string}) => (
  <CustomerNameTooltip align="top" message={name} fixed={true}>
    <CustomerNameCol as={!isIframe() ? NavLink : undefined} to={!isIframe() ? `/clients/profile/${id}` : ''}>
      <ColUserType icon={icon} />
      <CustomerNameColTitle>
        {name}
      </CustomerNameColTitle>
    </CustomerNameCol>
  </CustomerNameTooltip>
);
