import { TError } from '../models';

export const getErrorFieldsFromErrors = (fieldsArray: string[], errors: TError[], parentField?: string, listIndex?: number) => {
  let filteredErrors = errors;

  if (parentField && typeof listIndex === 'number') {
    filteredErrors = errors.filter(e => e.parentField === parentField && e.listIndex === listIndex);
  }

  return filteredErrors.map(item => item.field);
};
