import { SvgIcon, Button } from '@insly/qmt-reactjs-ui-lib';
import styled from 'styled-components';
import { ButtonStandard } from 'app/Pages/Clients/common/styles';

export const SummaryStyled = styled.div`
  
`;

export const SummaryContactsRow = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeB};
  padding-bottom: 32px; 
`;

export const SummaryCol = styled.div`
  flex: 1;
  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.strokeB};
    margin-right: 24px;
  }
  padding-right: 24px;
`;

export const NoData = styled(SummaryCol)`
  ${({ theme }) => theme.text.normal};
  text-align: center;
`;

export const SummaryData = styled.div`
  display: flex;
  padding: 32px 0;
`;

export const SummaryDataRow = styled.div<{ borderd?: boolean, onClick?: () => void }>`
  cursor: ${({ onClick }) => onClick ? 'pointer' : null };
  display: flex;
  align-items: center;
  border-bottom: ${({ borderd, theme }) => borderd ? `1px solid ${theme.colors.strokeB}` : null};
  padding-bottom: 32px;
  margin-bottom: ${({ borderd }) => borderd ? `32px` : null};
`;

export const SummaryDataRowIcon = styled(SvgIcon)`
  width: 48px;
  height: 48px;
  margin-right: 16px;
`;

export const SummaryDataRowTitle = styled.div`
  flex: 1;
  ${({ theme }) => theme.text.normal};
`;

export const SummaryDataRowQuantity = styled.div`
  ${({ theme }) => theme.text.normal};
  font-size: 32px;
`;

//Contacts
export const ContactsStyled = styled.div`
`;

export const ContactsItemsWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 16px;
  grid-auto-rows: max-content;
`;

export const ContactsRemoveIcon = styled(SvgIcon)`
  position: absolute;
  left: calc(100%);
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin: 12px 0 0 6px;
  transition: 0.3s opacity ease;
`;

export const ContactsItemWrapper = styled.div<{ bordered?: boolean }>`
  display: flex;
  justify-content: space-between;
  position: relative;
  ${({ bordered, theme }) => bordered ? `
    &:not(:last-child) {
      border-bottom: 1px dashed ${theme.colors.strokeB};
      padding-bottom: 16px;
    }
  ` : null};
`;

export const ContactsControls = styled.div<{ flexPosition?: string }>`
  margin-top: 16px;
  display: flex;
  justify-content: flex-${({ flexPosition }) => flexPosition ? flexPosition : 'end'};
`;

export const ContactsControlsButton = styled(Button)<{ transparent?: boolean }>`
  background: ${({ transparent }) => transparent ? '#ECE3DA' : null};
  height: 40px;
  &:not(:last-child) {
    margin-right: 16px;
  }
`;

export const ButtonAddItem = styled(ButtonStandard)<{ transparent?: boolean }>`
  background-color: ${({ transparent }) => transparent ? '#ECE3DA' : null};
`;

export const IconPlus = styled(SvgIcon)`
  width: 24px;
  height: 24px;
  margin-left: 8px;
  stroke: ${({ theme }) => theme.colors.black};
`;

//Documents
export const DocumentsStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export const Coverage = styled.div<{ active?: boolean }>`
  color: ${({ active, theme }) => active ? theme.colors.text : theme.colors.subtle};
`;
