import {
  AdditionalData,
  AdditionalDataBlock,
  AdditionalDataIcon,
  AdditionalDataInfo,
  AdditionalDataValue,
  AdditionalDataWrapper,
  AddressBlock,
  Email,
  ButtonAddContact,
} from './styles';
import React from 'react';
import {
  JSONSchemaProp,
  TAddress,
  TCommunicationChanel,
  TContactPersonSchema,
  TError,
  TUserStatus,
  TCustomerType,
  TCustomerContact
} from 'models';
import {
  ContactsControls,
  ContactsItemsWrapper,
  ContactsItemWrapper,
  ContactsRemoveIcon,
} from '../profile/body/styles';
import { CUSTOMER_CONTACT_VALIDATION_FIELDS } from 'config/itemLists';
import {
  addressString,
  translateEnumItemInScheme,
  combineErrorsByParentAndIndex,
  translate,
  cloneObject
} from 'utils';
import { showErrors } from 'app/Components/common/Error';
import { useScheme } from 'context/Schema';
import { ADDRESS_TYPES } from 'app/Components/common/AddressForm';
import { ContactsDataBlock, ContactsDataBlockTitle, ContactsItem } from 'styles/common';
import { FormGroup } from '@insly/qmt-reactjs-ui-lib';
import { createContactFormFields } from 'app/Components/common/Contacts';
import { EMAIL_DEFAULT, PHONE_DEFAULT } from '../consts';

export type TCustomer = {
  addresses?: TAddress[] | null,
  contacts?: TCustomerContact[],
  contact_persons?: TContactPersonSchema[],
  id?: string,
  revision_id?: string,
  type: TCustomerType,
  props: {
    countryCode?: string,
    idCode?: string,
    resident?: boolean,
    preferred_communication_channel?: TCommunicationChanel[],
    preferred_communication_language?: string,
    company_type?: string,
    customer_manager_id?: string,
    birthday?: string,
    sex?: string,
    regon?: string,
    nip?: string,
    pesel?: string,
    company_name?: string,
    first_name?: string,
    last_name?: string,
    family_name?: string,
    customer_status: TUserStatus,
    pkdCode?: string,
    pkdDescription?: string,
  },
};

export type TUserProfile = {
  type?: TCustomerType,
  customer_manager_id?: string,
  preferred_communication_language?: string,
  company_type?: string,
  birthday?: string,
  sex?: string,
  regon?: string,
  nip?: string,
  pesel?: string,
  company_name?: string,
  first_name?: string,
  last_name?: string,
  family_name?: string,
  idCode?: string,
  resident?: boolean,
  pkdCode?: string,
  pkdDescription?: string,
};

export const renderContactsWrapper = (userData: TCustomer) => (
  <AdditionalData>
    {userData.contacts?.length ? renderContacts(userData.contacts) : null}
  </AdditionalData>
);

export const renderAdditionalDataBlock = (icon: string, value: string | JSX.Element, additionalInfo?: string, key?: string) => (
  <AdditionalDataBlock key={`${value}_${key || additionalInfo}`}>
    <AdditionalDataWrapper>
      <AdditionalDataIcon icon={icon} />
      <AdditionalDataValue>
        {value}
      </AdditionalDataValue>
    </AdditionalDataWrapper>
    {additionalInfo ? (
      <AdditionalDataInfo>
        {additionalInfo}
      </AdditionalDataInfo>
    ) : null}
  </AdditionalDataBlock>
);

const renderContacts = (items: TCustomerContact[]) => items.map(
  (item) => renderAdditionalDataBlock(item.type, item.type === 'email' ? <Email href={`mailto:${item.value}`}>{item.value}</Email> : item.value)
);

export const Addresses = (addresses: TAddress[], bordered?: boolean) => {
  const { customerAddressSchema } = useScheme();
  const addressType = customerAddressSchema.properties?.props?.properties?.type as JSONSchemaProp;

  return (
    <AddressBlock borderd={bordered}>
      {addresses.map(
        (item) => renderAdditionalDataBlock(ADDRESS_TYPES[item.props.type[0]]?.icon, item.props.type.map(type => translateEnumItemInScheme(addressType, type)).join(', '), renderAddress(item.props), item.id)
      )}
    </AddressBlock>
  );
};

const renderAddress = (address: TAddress['props']) => addressString(address);

export const PhonesAndEmails = ({
  contacts,
  handleChangeForm,
  errors,
  readOnly
}: {
  contacts: TCustomer['contacts'],
  handleChangeForm: (key: string, value: TCustomerContact[]) => void,
  errors: TError[],
  readOnly?: boolean,
}) => {
  const onAddItem = (type: 'phone' | 'email') => {
    const template = type === 'phone' ? cloneObject(PHONE_DEFAULT) : cloneObject(EMAIL_DEFAULT);

    handleChangeForm('contacts', contacts ? [
      ...contacts,
      template
    ] : [
      template
    ]);
  };

  const onRemoveItem = (itemIndex: number) => {
    const touchedForm = contacts ? [...contacts] : [];
    touchedForm.splice(itemIndex, 1);
    handleChangeForm('contacts', touchedForm);
  };

  const handleChange = (name: keyof TCustomerContact, value: string | boolean, itemIndex: number) => {
    const touchedForm = contacts ? [...contacts] : [];
    if (touchedForm[itemIndex]) {
      touchedForm[itemIndex][name] = value as never;
    }

    handleChangeForm('contacts', touchedForm);
  };

  return (
    <ContactsDataBlock>
      <ContactsDataBlockTitle>
        {translate({ key: 'customer.contact_data.title' })}
      </ContactsDataBlockTitle>
      <ContactsItemsWrapper>
        {renderPhonesAndEmailsItems(contacts as TCustomerContact[], handleChange, onRemoveItem, errors)}
      </ContactsItemsWrapper>
      <ContactsControls flexPosition="start">
        <ButtonAddContact disabled={readOnly} preset="secondary" onClick={() => onAddItem('phone')}>
          <>
            {translate({ key: 'customer.contact.add_phone' })}
          </>
        </ButtonAddContact>
        <ButtonAddContact disabled={readOnly} preset="secondary" onClick={() => onAddItem('email')}>
          <>
            {translate({ key: 'customer.contact.add_email' })}
          </>
        </ButtonAddContact>
      </ContactsControls>
      {showErrors(['contacts'], errors)}
    </ContactsDataBlock>
  );
};

const renderPhonesAndEmailsItems = (
  items: TCustomerContact[],
  handleChange: (name: keyof TCustomerContact, value: string | boolean, index: number) => void,
  onRemoveItem: (index: number) => void,
  errors: TError[],
  readOnly?: boolean,
) => items.map((item, index) => (
  <ContactsItemWrapper key={`contact-${index}`}>
    <ContactsItem>
      <FormGroup
        disabled={readOnly}
        transparent={true}
        error={combineErrorsByParentAndIndex(CUSTOMER_CONTACT_VALIDATION_FIELDS, errors, 'contacts', index, true) as string | undefined}
        items={[
          createContactFormFields(item, index, handleChange)
        ]}
      />
    </ContactsItem>
    {!readOnly && (
      <ContactsRemoveIcon icon="trash" onClick={() => onRemoveItem(index)} />
    )}
  </ContactsItemWrapper>
));
