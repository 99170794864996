import { ShowMore } from 'app/Components/common/ShowMore';
import styled from 'styled-components';
import { SHOW_MORE_EL_HEIGHT } from 'app/Components/common/ShowMore/styles';

export const ShowMoreForParticipants = styled(ShowMore)<{ show: boolean }>`
  bottom: ${({ show }) => show ? 0 : `-${SHOW_MORE_EL_HEIGHT}px`};
  left: 0;
  z-index: ${({ show }) => show ? null : '-1'};
  float: left;
`;
