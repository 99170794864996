import styled from 'styled-components';
import { SvgIcon } from '@insly/qmt-reactjs-ui-lib';

export const SearchResultsItemGroup = styled.div`
  padding: 8px 0;      
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.strokeB};
  }
`;

export const SearchResultsItemGroupHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 16px;
`;

export const SearchResultsItemGroupHeaderTitle = styled.div`
  flex: 1;
  font-family: ${({ theme }) => theme.font.bold};
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
`;

export const SearchResultsItemGroupHeaderShowAll = styled.div`
  ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.colors.textSecondary};
  cursor: pointer;
`;

export const SearchResultsIcon = styled(SvgIcon)<{ margin?: 'left' | 'right' }>`
  width: 24px;
  height: 24px;
  stroke: ${({ theme }) => theme.colors.black};
  fill: ${({ theme }) => theme.colors.black};
  margin-right: ${({ margin }) => margin === 'right' ? '16px' : null};
  margin-left: ${({ margin }) => margin === 'left' ? '16px' : null};
`;

export const SearchResultsItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 40px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  &:hover {
   background-color: #F0F0F0;
  }
`;

export const SearchResultsItemData = styled.div`
  flex: 1;
  padding: 0 16px;
`;

export const SearchResultsItemDataTitle = styled.div`
  ${({ theme }) => theme.text.normal};
  margin-bottom: 4px;
  display: flex;
  gap: 16px;
`;

export const SearchResultsItemDataAdditional = styled.div`
  ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.colors.subtle};
  margin-bottom: 2px;
  .highlight {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const SearchResultsItemControls = styled.div`
  display: flex;
`;

export const SearchResultsItemControlsItem = styled.div`
  &:not(:last-child) {
    display: flex;
    &:after {
      display: block;
      padding-left: 16px;
      margin-right: 16px;
      content: '';
      height: 100%;
      border-right: 1px solid ${({ theme }) => theme.colors.strokeB};
    }
  }
  .qmt-ui-svg-icon {
    stroke: ${({ theme }) => theme.colors.black};
    width: 24px;
    height: 24px;
  }
`;
