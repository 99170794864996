import styled from 'styled-components';
import { List, TitleWrapper } from 'app/Pages/common/List/styles';
import { DatePicker } from '@insly/qmt-reactjs-ui-lib';
import { SelectSource } from '../../Quote/List/styles';

export const PolicyList = styled(List)`
  padding-top: 40px;
  ${TitleWrapper} {
    margin-bottom: 40px;
  }
`;

export const FilterControls = styled.div`
  display: flex;
  gap: 8px;
  align-items: end;
`;

export const DatePickerPolicy = styled(DatePicker)`
  max-width: 100px;
`;

export const SelectWithWideDrop = styled(SelectSource)`
  .qmt-ui-dropdown__drop {
    width: 220px;
  }
`;

export const SelectPolicySource = styled(SelectSource)`
  .qmt-ui-dropdown__drop {
    width: 180px;
  }
`;
