import React, { useEffect, useState } from 'react';
import { BodySSO } from './body/SSO';
import { PageAuthStyled, Content } from './styles';
import { useParams } from 'react-router-dom';
import bgDefault from 'assets/images/bg_logo.svg';

export type TStep = 'login' | 'logout';

export interface IPageAuth {
  backgroundImageUrl?: string,
}

//@TODO: Uncomment all commented and update when old Insly 3 login will be replaces with QMT one
export const PageAuth = ({ backgroundImageUrl = bgDefault }: IPageAuth) => {
  const [step, updateStep] = useState<TStep>('login');
  const { section } = useParams<{ section: TStep }>();

  // let history = useHistory();

  useEffect(() => {
    if (section !== step) {
      updateStep(section);
    }
  }, [section, step]);

  return (
    <PageAuthStyled>
      <Content
        className="content"
      >
        {renderBody()}
      </Content>
    </PageAuthStyled>
  );
};

const renderBody = () => (
  <BodySSO />
);

export default PageAuth;

