import styled from 'styled-components';
import { SvgIcon } from '@insly/qmt-reactjs-ui-lib';
import { disabledStyles } from 'styles/helpers';

export const ListPagination = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.strokeB};
  padding: 20px 16px;
`;

export const PaginationDelimiter = styled.div`
  flex: 1;
`;

export const PaginationData = styled.div`
  ${({ theme }) => theme.text.normal};
`;

export const PaginationDataWithControls = styled(PaginationData)`
  border-left: 1px solid ${({ theme }) => theme.colors.strokeB};
  border-right: 1px solid ${({ theme }) => theme.colors.strokeB};
  padding: 0 16px;
  margin: 0 16px;
`;

export const PaginationControl = styled(SvgIcon)<{ direction?: 'prev' | 'next', disabled?: boolean }>`
  ${({ theme }) => theme.text.normal};
  width: 24px;
  height: 24px;
  stroke: ${({ theme }) => theme.colors.black};
  transform: ${({ direction }) => direction === 'next' ? 'rotate(180deg)' : null};
  ${disabledStyles};
`;
