export const TEMP_CUSTOMER_MANAGERS = [
  {
    key: '1',
    value: 'Manager 1'
  },
  {
    key: '2',
    value: 'Manager 2'
  },
];

export const CUSTOMER_TYPE_ICONS = {
  individual: 'user',
  company: 'company',
  sole_trader: 'sole_trader',
};

export const CONTACT_SUBTYPES = [
  {
    key: 'home',
    value: 'customer.contact.phone.home'
  },
  {
    key: 'office',
    value: 'customer.contact.phone.office'
  },
  {
    key: 'mobile',
    value: 'customer.contact.phone.mobile'
  },
];

//@TODO: refactor validation fields to by dynamic from schema. Example participant address/additional fields
export const ADDRESS_VALIDATION_FIELDS = [
  'type',
  'street',
  'house',
  'zip_code',
  'city',
  'region',
  'country'
];

export const PARTICIPANT_VALIDATION_FIELDS = [
  'name',
  'regon',
  'nip',
  'companyType',
  'companyForm',
  'firstName',
  'lastName',
  'pesel',
  'birthDate',
  'gender',
  'countryCode'
];

export const POLICY_HOLDER_VALIDATION_FIELDS = [
  'first_name',
  'last_name',
  'birthday',
  'sex'
];

export const SOLE_TRADER_POLICY_HOLDER_VALIDATION_FIELDS = [
  'pesel',
  'first_name',
  'last_name'
];

export const SOLE_TRADER_PARTICIPANT_VALIDATION_FIELDS = [
  'pesel',
  'firstName',
  'lastName'
];

export const SOLE_TRADER_POLICY_HOLDER_ADDITIONAL_VALIDATION_FIELDS = [
  'birthday',
  'sex',
];

export const SOLE_TRADER_PARTICIPANT_ADDITIONAL_VALIDATION_FIELDS = [
  'birthDate',
  'gender',
];

export const PARTICIPANT_INSURER_REQUIRED_FIELDS = [
  'maritalStatus',
  'occupationId',
  'childrenCount',
];

export const DRIVER_PARTICIPANT_INSURER_REQUIRED_FIELD = 'drivingLicenseIssueDate';

export const BORDER_PARTICIPANT_INSURER_REQUIRED_FIELDS: string[] = [];

export const CUSTOMER_CONTACT_VALIDATION_FIELDS = [
  'type',
  'value',
  'subtype'
];

export const CUSTOMER_VALIDATION_FIELDS = [
  'regon',
  'nip',
  'pesel',
  'idCode',
  'company_name',
  'company_type',
  'first_name',
  'last_name',
  'birthday',
  'sex',
  'countryCode'
];

export const CUSTOMER_COMMON_VALIDATION_FIELDS = [
  'contacts',
  'addresses'
];

export const VEHICLE_DB_SOURCE = {
  infoexpert: 'infoexpert',
  eurotax: 'eurotax'
};

export const motorProducts = [
  'motor-mtpl',
  'motor-accident',
  'motor-assistance',
  'motor-casco',
  'motor-glass',
  'motor-luggage',
  'motor-discounts',
];

export const externalInsurerLinks: Record<string, string> = {
  'mtu': 'https://sso.ergohestia.pl/my.policy',
  'ycd': 'https://sso.ergohestia.pl/my.policy',
  'pzu': 'https://everest.pzu.pl/my.policy',
  'warta': 'https://cas.warta.pl/cas/login?service=https://eagent.warta.pl/view360/login/cas',
  'hdi': 'https://cas.warta.pl/cas/login?service=https://eagent.warta.pl/view360/login/cas',
  'uniqa': 'https://serwis.uniqa.pl/partner/Login.html',
  'trasti': 'https://hpiprodfe.b2clogin.com/hpiprodfe.onmicrosoft.com/b2c_1_signin/oauth2/v2.0/authorize?client_id=d3a26c74-7520-428a-8bbf-dd8753ba9868&scope=openid%20offline_access%20https%3A%2F%2Fhpiprodfe.onmicrosoft.com%2Ff9a53140-f2b0-4432-99f4-4b1722e18de4%2Fread%20profile&redirect_uri=https%3A%2F%2Ftrasti.pl%2Fmanagement%2Fdashboard&client-request-id=24cd9368-a6fa-483c-9b0f-0f7804263744&response_mode=fragment&response_type=code&x-client-SKU=msal.js.browser&x-client-VER=2.14.2&x-client-OS=&x-client-CPU=&client_info=1&code_challenge=L05yYgbygzMLCB3dY7cLGjtPr_vczwRzWfQls7OVJoo&code_challenge_method=S256&nonce=45989ac4-9a8b-4411-a339-6d185fd6c68b&state=eyJpZCI6IjgxOTE5MjZiLTUyNTQtNDkzNC1iYjliLWEyNTcxZGMyZDJjZSIsIm1ldGEiOnsiaW50ZXJhY3Rpb25UeXBlIjoicmVkaXJlY3QifX0%3D',
};

export const externalInsurerLinksDynamic: string[] = [
  'link4',
  'proama',
  'generali',
  'tuz',
  'interrisk',
  'wefox',
  'tuw',
  'compensa',
  'agro',
  'dallbogg',
];

export const hidePolicyLinkInsurerList: string[] = [
  'interrisk',
  'agro'
];
