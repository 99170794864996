import React, { useEffect, useState } from 'react';

export const useOutsideClick = (ref: React.RefObject<HTMLElement>) => {
  const [isOutside, setIsOutside] = useState<boolean>(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOutside(true);
    } else {
      setIsOutside(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [ref]);

  return isOutside;
};
