import styled from 'styled-components';
import { Checkbox, Dialog } from '@insly/qmt-reactjs-ui-lib';

export const FiltersBody = styled.div`
  
`;

export const ListFiltersCustomStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxStyled = styled(Checkbox)`
  width: 100%;
`;

export const DialogStyled = styled(Dialog)`
  .qmt-ui-dialog {
    padding: 30px;
    width: min-content;
    .qmt-ui-svg-icon {
      display: none;
    }
  }
`;
