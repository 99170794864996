import { TError } from 'models';
import { combineErrors } from './combineErrors';

export const processErrorsToString = (fieldsArray: string[], errors: TError[]) => {
  const combinedErrors: TError[] = combineErrors(fieldsArray, errors);
  if (combinedErrors?.length) {
    const errorStrings = combinedErrors.map((item: { code: string, message: string }) => `${item.code}: ${item.message}`);
    return errorStrings.join('\n');
  }

};
