import { JSONSchemaProp, TCoverageFormInsurer, TShowIf, TShowIfField } from 'models';
import { leaf } from './leaf';
import { translate } from './translate';

export const showIf = (rule?: TShowIf, localForm: Record<string, any> = {}, globalForm: Record<string, any> = {}) => {
  if (!rule) {
    return true;
  }

  const coverageType = globalForm?.coverage?.coverage_type || [];

  let isPassed = false;
  let passedRules = 0;

  rule.fields?.forEach(field => {

    let value;
    if (field.use_global_form) {
      value = leaf(globalForm, field.name);
    } else {
      value = localForm[field.name];
    }

    passedRules += validateShowIfField(field, value);
  });

  rule.products?.forEach(product => {
    if (coverageType.includes(product)) {
      passedRules += 1;
    }
  });

  if (rule.customValidations && rule.customValidations.length > 0) {
    rule.customValidations.forEach(validation => {
      if (checkCustomValidation(validation.name, validation.params, localForm, globalForm, validation.use_global_form)) {
        passedRules += 1;
      }
    });
  }

  if (rule.dependency_type === 'all') {
    let shouldBePassedQuantity = 0;

    if (rule.fields) {
      shouldBePassedQuantity += rule.fields.length;
    }

    if (rule.products) {
      shouldBePassedQuantity += rule.products.length;
    }

    if (shouldBePassedQuantity === passedRules) {
      isPassed = true;
    }

  } else if (passedRules > 0) {
    isPassed = true;
  }

  return isPassed;

};

export const validateShowIfField = (field: TShowIfField, value: any) => {
  if (field.is_undefined && typeof value === 'undefined') {
    return 1;
  } else if (typeof field.value !== 'undefined' && field.value.includes(value)) {
    return 1;
  } else if (typeof field.value !== 'undefined' && Array.isArray(value) && field.value.filter(x => value.includes(x)).length > 0) {
    return 1;
  } else if (typeof field.prop !== 'undefined') {
    switch (field.prop) {
      case 'length':
        const item = value;
        if (Array.isArray(item) && item?.length) {
          return 1;
        } else if (typeof item === 'string' && item?.length) {
          return 1;
        } else if (typeof item === 'number' && item && `${item}`.length) {
          return 1;
        }
        break;
    }
  }
  return 0;
};

export const checkCustomValidation = (name: string, params: Record<string, any>, localForm: Record<string, any>, fullForm: Record<string, any>, useGlobalForm?: boolean) => {
  switch (name) {
    case 'vehicleAge':
      return vehicleAgeValidation({ age: params['age'] }, useGlobalForm ? fullForm : localForm);
    case 'validOnlyProducts':
      return validOnlyProductValidation({
        products: params['products'],
        fields: params['fields'],
      }, useGlobalForm ? fullForm : localForm);
    case 'invalidProducts':
      return invalidProductsValidation({
        products: params['products'],
      }, useGlobalForm ? fullForm : localForm);
    case 'mainSubValidation':
      return mainSubValidation({
        main: params['main'],
        sub: params['sub']
      }, useGlobalForm ? fullForm : localForm);
    case 'siblingProduct':
      return siblingProductValidation({
        field: params['field'],
        insurerTag: params['insurerTag'],
        product: params['product'],
      }, useGlobalForm ? fullForm : localForm);
    default:
      return true;
  }
};

const vehicleAgeValidation = (params: {age: number}, form: Record<string, any>) => {
  const { age } = params;

  if (typeof age === 'number' && form?.vehicle?.productionYear) {
    const currentYear = new Date().getFullYear();
    if (currentYear - age <= form.vehicle.productionYear) {
      return true;
    }
  }

  return false;
};

const mainSubValidation = (params: {main: TShowIfField, sub: TShowIfField[]}, form: Record<string, any>) => {
  const { main, sub } = params;

  if (validateShowIfField(main, leaf(form, main.name))) {
    let validSubItems = 0;

    sub.forEach(field => {
      if (validateShowIfField(field, leaf(form, field.name))) {
        validSubItems += 1;
      }
    });

    return validSubItems === sub.length;
  }

  return true;
};

const siblingProductValidation = (params: {
  field: {
    name: string,
    value: (string | boolean | number)[],
  },
  product: string,
  insurerTag: string,
}, form: Record<string, any>) => {
  const { field, insurerTag, product } = params;

  const insurer: TCoverageFormInsurer = form.coverage?.insurers?.find((item: TCoverageFormInsurer) => (item.tag === insurerTag));
  if (insurer) {
    const insurerProductForm = insurer.properties.find(insurerProduct => insurerProduct.key === product);
    if (insurerProductForm) {
      return validateShowIfField(field, leaf(insurerProductForm.options, field.name));
    }
  }

  return false;
};

const validOnlyProductValidation = (params: {
  products?: string[],
  fields?: {
    name: string,
    value: (string | number | boolean)[],
  }[],
}, form: Record<string, any>) => {
  const { products, fields } = params;

  let isProductsValid = false;
  let isFieldsValid = false;

  if (products?.length) {
    const coverageType = form?.coverage?.coverage_type;
    let validProductsCount = 0;


    products.forEach(product => {
      if (coverageType?.includes(product)) {
        validProductsCount += 1;
      }
    });

    isProductsValid = validProductsCount === products.length && validProductsCount === coverageType?.length;

  } else {
    isProductsValid = true;
  }

  if (fields?.length) {
    let validFieldsCount = 0;

    fields.forEach(field => {
      const value = leaf(form, field.name);
      validFieldsCount += validateShowIfField(field, value);
    });

    isFieldsValid = validFieldsCount === fields.length;
  } else {
    isFieldsValid = true;
  }

  return (isProductsValid && isFieldsValid);
};

const invalidProductsValidation = (params: {
  products?: string[],
}, form: Record<string, any>) => {
  const { products } = params;

  let isProductsValid = true;

  if (products?.length) {
    const coverageType = form?.coverage?.coverage_type;

    products.forEach(product => {
      if (coverageType?.includes(product)) {
        isProductsValid = false;
      }
    });

  }

  return isProductsValid;
};

export const checkIsDisabled = (prop: JSONSchemaProp, form: Record<string, any>) => {
  let isDisabled = false;
  if (prop && prop.show_if) {

    prop.show_if.fields?.forEach(field => {
      if (!validateShowIfField(field, leaf(form, field.name)) && field.disable) {
        isDisabled = true;
      }
    });

    prop.show_if.customValidations?.forEach(validation => {
      if (!checkCustomValidation(validation.name, validation.params, {}, form, validation.use_global_form) && validation.disable) {
        isDisabled = true;
      }
    });
  }
  return isDisabled;
};

export const checkIsDisabledWithReason = (prop: JSONSchemaProp, form: Record<string, any>): string[] => {
  let disabledDesc: string[] = [];

  if (prop && prop.show_if) {

    prop.show_if.fields?.forEach(field => {
      if (!validateShowIfField(field, leaf(form, field.name)) && field.disable) {
        disabledDesc.push(field.validation_desc ? translate({ key: field.validation_desc }) : '');
      }
    });

    prop.show_if.customValidations?.forEach(validation => {
      if (!checkCustomValidation(validation.name, validation.params, {}, form, validation.use_global_form) && validation.disable) {
        disabledDesc.push(validation.validation_desc ? translate({ key: validation.validation_desc }) : '');
      }
    });
  }

  return disabledDesc;
};
