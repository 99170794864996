import { Body, Headline, HeadlineIcon, Title, UnderConstructionStyled } from './styles';
import { FALLBACK_TRANSLATIONS } from 'config/translation';

const UnderConstruction = ({ ...props }) => (
  <UnderConstructionStyled { ...props }>
    <Headline>
      <HeadlineIcon icon="under_construction" />
      <Title>{FALLBACK_TRANSLATIONS.under_construction.title}</Title>
    </Headline>
    <Body>
      {FALLBACK_TRANSLATIONS.under_construction.body}
    </Body>
  </UnderConstructionStyled>
);

export default UnderConstruction;
