import { TFetchStatus } from 'models';
import React, { useEffect, useState } from 'react';
import { PageStyled } from 'app/Pages/common/styles';
import IDDList from 'app/Pages/IDD/List';
import { makeRequestQuery, preflightAPICall, processAPIError, translate } from 'utils';
import { IPageIDD } from 'app/Pages/IDD';
import IDD, { API_IDD_QUESTIONNAIRE_URL, setCustomerData } from 'app/Components/IDD';
import axios from 'axios';
import { TCustomer } from 'app/Pages/Clients/common';

const PageIDD = ({ profile } : { profile: TCustomer }) => {
  const [listDataFetchStatus, updateListDataFetchStatus] = useState<TFetchStatus>('loading');
  const [iddListErrorText, updateIddListErrorText] = useState('');
  const [iddList, updateIddList] = useState<IPageIDD['listData']>({});
  const [searchQuery, updateSearchQuery] = useState('');
  const [section, updateSection] = useState<'list' | 'add' >('list');


  useEffect(() => {
    updateListDataFetchStatus(null);

    if (section === 'list') {
      updateIddList({});
      getList({});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section, searchQuery]);

  const handleSearchChange = (value: string) => {
    updateSearchQuery(value);
  };

  const getList = ({ page }: { page?: number }) => {
    updateListDataFetchStatus('loading');

    let query: Record<string, string | number> = {};

    query.page_size = 10;

    if (page) {
      query.page = page;
    }

    query.customer_id = profile.id as string;

    if (searchQuery) {
      query.search = searchQuery;
    }

    const queryString = makeRequestQuery(query);

    preflightAPICall(() => {
      axios.get(`${API_IDD_QUESTIONNAIRE_URL}${queryString}`).then(response => {
        const data = response.data;
        onDataLoaded(data);

      }).catch(error => {

        onDataLoaded({
          results: [],
        });

        updateIddListErrorText(() => processAPIError(error) as string);

      });
    });

  };

  const onDataLoaded = (data: IPageIDD['listData']) => {
    updateIddList(mapIDDList(data));
    updateListDataFetchStatus('success');
  };

  const handleListChangePage = (page: number) => {
    getList({ page });
  };

  const handleAddIDDClick = () => {
    updateSection('add');
  };

  const mapIDDList = (iddList: IPageIDD['listData']) => (
    {
      ...iddList,
      total_pages: iddList.last_page,
      current_page: iddList.page,
      total_results: iddList.total,
    }
  );

  switch (section) {
    case 'add':
      return (
        <IDD
          customerData={setCustomerData(profile)}
          initialIDDState="idd_requested"
          handleIddCancel={() => updateSection('list')}
        />
      );
    case 'list':
    default:
      return (
        <PageStyled>
          <IDDList
            title={translate({ key: 'idd.list.title' })}
            errorText={iddListErrorText}
            step={section}
            dataStatus={listDataFetchStatus}
            itemList={iddList}
            handleSearchChange={handleSearchChange}
            handleListChangePage={handleListChangePage}
            handleAddIDDClick={handleAddIDDClick}
            hideCustomerInfo={true}
            updateIddList={updateIddList}
            updateIddErrorText={updateIddListErrorText}
            updateDataFetchStatus={updateListDataFetchStatus}
          />
        </PageStyled>
      );
  }

};

export default PageIDD;
