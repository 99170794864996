import { decorateBytes, errorNotification, preflightAPICall, translate } from 'utils';
import {
  ControlsIconWrapper,
  ControlsSet,
  DocError,
  DocErrorDeleteIcon,
  DocErrorText,
  DocumentControlsCol,
  DocumentFormat,
  DocumentIcon,
  DocumentName,
  DocumentNameCol,
  DocumentSize,
  DocumentsListStyled,
  Progress,
  ProgressBar,
  ProgressPercents,
  ProgressWrapper
} from './styles';
import React, { useState } from 'react';
import { TDoc, TError } from 'models';
import { useApp } from 'context/App';
import { ControlsIcon, DefaultIcon } from 'styles/common';
import axios, { AxiosError } from 'axios';
import { getFile } from 'utils/getFile';
import apiConfig, { MASTER_DOC_URL } from 'config/api';
import { TColumn } from '@insly/qmt-reactjs-ui-lib/dist/components/TableRow';

interface IDocumentsList {
  refId: string,
  noResultsText: string,
  data: TDoc[],
  dataStatus: string,
  handleRemoveDoc: (id: string) => void,
  additionalColumns?: TColumn[],
  skipDeleteDialog?: boolean,
}

const DocumentsList = ({
  refId, noResultsText = translate({ key: 'common.no_results' }), data, dataStatus, handleRemoveDoc, additionalColumns = [], skipDeleteDialog = false,
}: IDocumentsList) => <DocumentsListStyled
  className="document_list"
  columns={[
    {
      name: 'name',
      onRender: renderDocumentName,
      styles: {
        flex: 1
      }
    },
    ...additionalColumns,
    {
      name: 'controls',
      onRender: (rowData) => renderDocumentControls({
        data: rowData,
        refId: refId as string,
        handleRemoveDoc,
        skipDeleteDialog
      }),
    }
  ]}
  dataStatus={dataStatus}
  data={data as never[]}
  noResultsText={noResultsText || translate({ key: 'common.no_results' })}
  hideSearch={true}
/>;

const renderDocumentName = (rowData: TDoc) => (
  <DocumentNameCol error={!!rowData.errors?.length} isUploading={typeof rowData.progress === 'number'}>
    <DocumentIcon icon="file_text" />
    <DocumentName>
      {rowData.file_name || rowData.file?.name || rowData.title}
      <DocumentSize>
        {rowData.props?.size ? decorateBytes(+(rowData.props.size)) : null}
      </DocumentSize>
    </DocumentName>
  </DocumentNameCol>
);

const renderDocumentControls = ({
  data,
  refId,
  handleRemoveDoc,
  skipDeleteDialog,
}: {
  data: TDoc,
  refId: string,
  handleRemoveDoc: (id: string) => void,
  skipDeleteDialog: boolean,
}) => {
  if (typeof data.progress === 'number') {
    return renderProgress(data.progress);
  } else if (data.errors) {
    return renderErrors(data, handleRemoveDoc);
  } else {
    return <DocumentControls
      data={data}
      customerId={refId}
      handleRemoveDoc={handleRemoveDoc}
      skipDeleteDialog={skipDeleteDialog}
    />;
  }
};

export const renderProgress = (progress: number) => (
  <ProgressWrapper>
    <ProgressPercents>
      {Math.round(progress)}%
    </ProgressPercents>
    <Progress>
      <ProgressBar style={ { width: Math.round(progress) + '%' } } />
    </Progress>
  </ProgressWrapper>
);

const renderErrors = (data: TDoc, handleRemoveDoc: (id: string) => void) => (
  <DocError>
    <DocErrorText>
      {data.errors && data.errors.map((item: TError) => `${item.code}: ${item.message} `)}
    </DocErrorText>
    <DocErrorDeleteIcon icon="close" onClick={() => handleRemoveDoc(data.id as string)} />
  </DocError>
);

const DocumentControls = ({
  data,
  customerId,
  handleRemoveDoc,
  skipDeleteDialog,
}: {
  data: TDoc,
  customerId: string,
  handleRemoveDoc: (id: string) => void,
  skipDeleteDialog: boolean,
}) => {
  const [active, updateActive] = useState(false);

  const { showDialog, showNotification } = useApp();

  const handleRemoveDialog = () => {
    showDialog({
      body: translate({ key: 'dialog.remove_document.body' }),
      buttonAccept: <>
        {translate({ key: 'dialog.remove_document.accept' })}&nbsp;
        <DefaultIcon icon="trash" />
      </>,
      buttonDecline: translate({ key: 'common.cancel' }),
      onAccept: handleRemove,
    });
  };

  const handleRemove = () => {
    preflightAPICall(() => {
      axios.delete(`${MASTER_DOC_URL}/${data.id}`).then(response => {
        if (response.status === 204) {
          handleRemoveDoc(data.id as string);
        }
      }).catch(error => {
        errorNotification(error, showNotification);
      });
    });
  };

  return (
    <DocumentControlsCol>
      {active ? (
        renderControlsSet([
          {
            icon: 'download',
            onClick: () => getFile({
              url: `${apiConfig.DOCUMENT}/${customerId}/${data.id}/file`,
              fileName: data.file_name || data.file?.name || '',
              onError: (error: AxiosError) => errorNotification(error, showNotification)
            })
          },
          {
            icon: 'trash',
            onClick: skipDeleteDialog ? handleRemove : handleRemoveDialog
          }
        ])
      ) : (
        <ControlsIcon icon="more" onClick={() => updateActive(true)} />
      )}
    </DocumentControlsCol>
  );
};

const renderControlsSet = (controls: {icon: string, onClick: () => void}[]) => (
  <ControlsSet>
    {controls.map((item, index) => (
      <ControlsIconWrapper key={index}>
        <ControlsIcon icon={item.icon} onClick={item.onClick} />
      </ControlsIconWrapper>
    ))}
  </ControlsSet>
);

export default DocumentsList;
