import { JSONSchema, JSONSchemaProp, TAddress, TAddressType, TError, TZipCodeResult } from 'models';
import { useApp } from 'context/App';
import React, { useEffect, useState } from 'react';
import {
  translateTitleInScheme,
  getErrorFieldsFromErrors,
  zipCodeMatcher,
  fetchZipCodeData,
  setStreets,
  combineErrorsByParentAndIndex,
  getOptionsFromEnum,
  translate,
  filterStreets,
  fetchCityData,
  processAPIError,
  cloneObject,
  filterStreetsWithoutCity,
  translateEnumItemInScheme
} from 'utils';
import axios, { AxiosError } from 'axios';
import { ADDRESS_VALIDATION_FIELDS } from 'config/itemLists';
import { FALLBACK_TRANSLATIONS } from 'config/translation';
import { showErrors } from 'app/Components/common/Error';
import { ContactsDataBlock, ContactsItem } from 'styles/common';
import { FormGroup } from '@insly/qmt-reactjs-ui-lib';
import { IFormGroupItem } from '@insly/qmt-reactjs-ui-lib/dist/components/FormGroup';
import { DEFAULT_RESIDENT_COUNTRY } from 'config/api';
import { ADDRESS_DEFAULT } from 'app/Pages/Clients/consts';
import { ContactsItemWrapper, ContactsRemoveIcon } from 'app/Pages/Clients/profile/body/styles';

export const ADDRESS_TYPES = {
  legal: {
    short_title: FALLBACK_TRANSLATIONS.address_types_short.legal,
    icon: 'company'
  },
  postal: {
    short_title: FALLBACK_TRANSLATIONS.address_types_short.postal,
    icon: 'mail'
  },
  registered: {
    short_title: FALLBACK_TRANSLATIONS.address_types_short.registered,
    icon: 'house'
  },
  other: {
    short_title: FALLBACK_TRANSLATIONS.address_types_short.other,
    icon: 'house'
  },
};

let CancelToken = axios.CancelToken;
let cancelTokenSource = CancelToken.source();

const SEARCH_DELAY = 300;
const MIN_VALUE_LENGTH_TO_START_SEARCH = 3;
let searchTimeout: ReturnType<typeof setTimeout>;

export const AddressFormPolicyHolder = ({
  item,
  handleFormChange,
  schemaProps,
  errors,
  isNotResident,
  typesInUse,
  onRemoveItem,
  isRemovalAllowed,
} : {
  item: TAddress['props'],
  handleFormChange: (key: string, value: TAddress['props'][]) => void,
  schemaProps: JSONSchema,
  errors: TError[],
  isNotResident?: boolean,
  typesInUse?: string[],
  onRemoveItem?: () => void,
  isRemovalAllowed?: boolean,
}) => {

  const { showNotification } = useApp();

  const [zipCodeResults, updateZipCodeResults] = useState<TZipCodeResult[]>([]);
  const [streets, updateStreets] = useState<{key: string, value: string}[]>([]);
  const [cities, updateCities] = useState<{key: string, value: string}[]>([]);
  const [isFetching, updateIsFetching] = useState(false);

  useEffect(() => {
    if (item?.zip_code?.length === 6 && !isNotResident) {
      updateIsFetching(true);
      fetchZipCodeData(
        item && item.zip_code as string,
        (zipCodeResults) => {
          const streets = filterStreets(zipCodeResults, item.city);
          if (streets.length > 1) {
            setStreets(streets, updateStreets);
          }
          updateZipCodeResults(zipCodeResults);
          updateIsFetching(false);
        },
        updateCities,
        () => {},
        () => {},
        onError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAddressFromZip = (zipCodeResults: TZipCodeResult[]) => {
    handleFormChange('addresses', [{
      ...form,
      ...fillAddressFieldsFromResults(zipCodeResults[0]),
    }]);
  };

  const setCityInput = () => ({
    type: !!cities.length && !isNotResident ? 'select' : 'input',
    options: {
      name: 'city',
      required: true,
      error: errorFields.includes('city') ? 'error' : null,
      label: translateTitleInScheme(schemaProps.city),
      value: form.city,
      applyCustomValueOnSearch: true,
      customValue: form.city,
      options: cities,
      searchable: true,
      styles: {
        flexBasis: '66.6%'
      },
      placeholder: !!cities.length ? translate({ key: 'common.select' }) : '',
      notFoundText: translate({ key: 'common.nothing_found' }),
      handleChange: (name: keyof TAddress['props'], value: string) => !!cities.length ? handleCityChange(name, value as string) : handleChange(name, value as string),
    }
  });

  const setStreetInput = () => ({
    type: !!streets.length && !isNotResident ? 'select' : 'input',
    options: {
      name: 'street',
      required: true,
      error: errorFields.includes('street') ? 'error' : null,
      label: translateTitleInScheme(schemaProps.street),
      value: form.street,
      applyCustomValueOnSearch: true,
      customValue: form.street,
      options: streets,
      searchable: true,
      fullStringSearch: true,
      styles: {
        flexBasis: '66.6%'
      },
      placeholder: !!streets.length ? translate({ key: 'common.select' }) : '',
      notFoundText: translate({ key: 'common.nothing_found' }),
      handleChange: (name: keyof TAddress['props'], value: string) => handleChange(name, value as string),
    }
  });

  let form = item ? { ...item } : {
    ...cloneObject(ADDRESS_DEFAULT),
    country: isNotResident ? '' : DEFAULT_RESIDENT_COUNTRY,
  };

  const onError = (error: AxiosError) => {
    updateIsFetching(false);
    showNotification({
      preset: 'error',
      message: processAPIError(error, false, true) as string,
      autoHide: true
    });
  };

  const errorFields = errors?.length ? getErrorFieldsFromErrors(ADDRESS_VALIDATION_FIELDS, errors, 'addresses', 0) : [];

  const handleCityChange = (
    name: keyof TAddress['props'],
    value: string,
  ) => {
    if (value?.length >= MIN_VALUE_LENGTH_TO_START_SEARCH) {
      clearTimeout(searchTimeout);

      searchTimeout = setTimeout(() => {
        updateIsFetching(true);
        cancelTokenSource.cancel('Operation canceled by the user.');
        CancelToken = axios.CancelToken;
        cancelTokenSource = CancelToken.source();
        fetchCityData(form.zip_code, value, cancelTokenSource, (results) => {
          let addressMiscData: Record<string, string> = {};
          if (results.length) {
            addressMiscData = {
              city: results[0].city,
              region: results[0].province,
              county: results[0].county,
              post: results[0].post_office,
              commune: results[0].community,
            };
          }

          const touchedForm = {
            ...form,
            ...addressMiscData,
            [name]: value,
          };

          const streets = filterStreetsWithoutCity(results);

          if (streets.length > 1) {
            touchedForm.street = '';
            setStreets(streets, updateStreets);
          } else {

            touchedForm.street = streets?.length ? streets[0].street : touchedForm.city;

            updateStreets([]);
          }

          handleFormChange('addresses', [
            touchedForm
          ]);
          updateIsFetching(false);
        }, onError);
      }, SEARCH_DELAY);

    } else {
      const touchedForm = {
        ...form,
        [name]: value,
      };

      handleFormChange('addresses', [
        touchedForm
      ]);

      updateStreets([]);
    }
  };

  const handleChange = (key: keyof TAddress['props'], value: string) => {
    if (key === 'type') {
      form.type = [value as TAddressType];
    } else if (key === 'zip_code') {
      form.zip_code = isNotResident ? value : zipCodeMatcher(value, form.zip_code);
    } else {
      form[key] = value as never;
    }

    handleFormChange('addresses', [{
      ...form,
    }]);
  };

  const setDefaultsAndUpdateZipCodeResults = (results: TZipCodeResult[]) => {
    if (results?.length > 1) {

      handleFormChange('addresses', [{
        ...form,
        ...fillAddressFieldsWithDefaultData(results, updateStreets),
      }]);
    }

    updateZipCodeResults(results);
  };

  const getAllowedOptions = () => {
    if (schemaProps.type?.items) {
      const schemaPropsItems = cloneObject(schemaProps.type?.items);

      if (typesInUse?.length) {
        schemaPropsItems.enum = schemaPropsItems.enum.filter((item: string) => !typesInUse.includes(item));
      }

      return getOptionsFromEnum(schemaPropsItems as JSONSchemaProp, schemaProps.type);
    } else {
      return [];
    }
  };

  return (
    <ContactsDataBlock>
      <ContactsItemWrapper>
        <ContactsItem>
          <FormGroup
            disabled={isFetching}
            transparent={true}
            error={combineErrorsByParentAndIndex(ADDRESS_VALIDATION_FIELDS, errors, 'addresses', 0, true) as string | undefined}
            items={[
              [{
                type: 'select',
                options: {
                  placeholder: translate({ key: 'customer.address.type_placeholder' }),
                  name: 'type',
                  label: translateTitleInScheme(schemaProps.type),
                  value: translateEnumItemInScheme(schemaProps.type, form.type[0]),
                  error: errorFields.includes('type') ? 'error' : undefined,
                  handleChange: (name, value) => handleChange(name as keyof TAddress['props'], value as string),
                  options: getAllowedOptions(),
                  icon: form.type.length ? ADDRESS_TYPES[form.type[0] as TAddressType].icon : undefined,
                }
              }],
              [
                {
                  type: 'input',
                  options: {
                    name: 'zip_code',
                    placeholder: isNotResident ? '': '""-"""',
                    label: translateTitleInScheme(schemaProps.zip_code),
                    value: form.zip_code,
                    required: true,
                    error: errorFields.includes('zip_code') ? 'error' : null,
                    styles: {
                      flexBasis: `33.3%`
                    },
                    handleChange: (name: keyof TAddress['props'], value: string) => handleChange(name, value as string),
                    onBlur: () => (form.zip_code?.length === 6 && !isNotResident) ? fetchZipCodeData(form.zip_code, setDefaultsAndUpdateZipCodeResults, updateCities, setAddressFromZip, updateStreets, onError) : null
                  }
                },
                setCityInput() as IFormGroupItem,
              ],
              [
                setStreetInput() as IFormGroupItem,
                {
                  type: 'input',
                  options: {
                    name: 'house',
                    label: translateTitleInScheme(schemaProps.house),
                    value: form.house,
                    error: errorFields.includes('house') ? 'error' : null,
                    className: 'address-house-nr',
                    required: true,
                    styles: {
                      flexBasis: `${33.3 / 2}%`,
                      borderRight: 'none',
                    },
                    handleChange: (name: keyof TAddress['props'], value: string) => handleChange(name, value as string),
                  }
                },
                {
                  type: 'input',
                  options: {
                    name: 'room',
                    label: translateTitleInScheme(schemaProps.room),
                    value: form.room,
                    styles: {
                      flexBasis: `${33.3 / 2}%`
                    },
                    handleChange: (name: keyof TAddress['props'], value: string) => handleChange(name, value as string),
                  }
                },
              ],
            ]}
          />
        </ContactsItem>
        {isRemovalAllowed && <ContactsRemoveIcon icon="trash" onClick={onRemoveItem} />}
      </ContactsItemWrapper>
      {showErrors(['addresses'], errors)}
    </ContactsDataBlock>
  );
};

export const fillAddressFieldsFromResults = (results: TZipCodeResult) => ({
  city: results.city,
  street: results.street,
  region: results.province,
  county: results.county,
  post: results.post_office,
  commune: results.community,
});

export const fillAddressFieldsWithDefaultData = (results: TZipCodeResult[], updateStreets: (streets: {key: string, value: string}[]) => void) => {

  const form = {
    city: results[0].city,
    street: '',
    region: results[0].province,
    county: results[0].county,
    post: results[0].post_office,
    commune: results[0].community,
  };

  const streets = filterStreets(results, form.city);

  if (streets.length > 1) {
    setStreets(streets, updateStreets);
  } else {
    form.street = streets?.length ? streets[0].street : form.city;
    updateStreets([]);
  }

  return form;
};
