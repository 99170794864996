import styled from 'styled-components';
import { SvgIcon } from '@insly/qmt-reactjs-ui-lib';

export const ColUserName = styled.span`
  ${({ theme }) => theme.text.normal};
`;

export const ColUserType = styled(SvgIcon)`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  fill: ${({ theme }) => theme.colors.black};
  stroke: ${({ theme }) => theme.colors.black};
`;

export const ColUserId = styled.div`
  
`;

export const ColUserAddress = styled.div`
  
`;
