import { TAssistance, TParticipantProps, TSearchListData, TTravelCoverage, TTraveller } from 'models';
import { TQuoteProduct } from 'app/Pages/Quote/Profile/body/Coverage/models';
import { TVehicleObject } from '../QuoteMotor/Profile/body/Vehicle/components/SearchInput';
import { TBorderVehicleObject } from '../QuoteBorder/Profile/body/Vehicle/components/SearchInput';
import { AxiosError } from 'axios';
import {
  INSURANCE_PRODUCT_ASSISTANCE,
  INSURANCE_PRODUCT_BORDER,
  INSURANCE_PRODUCT_MOTOR,
  INSURANCE_PRODUCT_TRAVEL
} from 'config/consts';

export const POLICY_ISSUED_STATUS = 'accepted';
export const IDD_REQUIRED_STATUS = 'idd_required';
export const INITIALIZED_STATUS = 'initialized';

export const ASSISTANCE_STEP = 'assistance';
export const POLICY_STEP = 'policy';
export const COVERAGE_STEP = 'coverage';
export const VEHICLE_STEP = 'vehicle';
export const PARTICIPANTS_STEP = 'participants';
export const ISSUING_STEP = 'issuing';
export const QUOTE_STEP = 'quote';
export const SUMMARY_STEP = 'summary';

export type TypeVehicleQuoteSteps = typeof PARTICIPANTS_STEP | typeof VEHICLE_STEP | typeof COVERAGE_STEP | typeof QUOTE_STEP | typeof SUMMARY_STEP | string;

export type TQuoteListData = TSearchListData & { results?: TQuoteListItemData[] };

export type TQuoteListItemData = {
  products?: TQuoteProduct[],
  customer?: {
    id: string,
    props: {
      first_name: string,
      last_name: string,
    },
  },
  domain: {
    meta: {
      broker_id: string,
      broker_name: string,
    },
  },
  id: string,
  number: string,
  objects: {
    'border-vehicle': [
      {
        props: {
          registrationNumber: string,
          vin: string,
          make?: number | string,
          model?: number | string,
          string_eurotax?: string[],
          string_infoexpert?: string[],
        },
      }
    ],
    vehicle: [
      {
        registrationNumber: string,
        vin: string,
        props: {
          make?: number | string,
          model?: number | string,
          string_eurotax?: string[],
          string_infoexpert?: string[],
        },
      },
    ],

  },
  sales_channel: {
    source: string,
  },
  status: TQuoteStatus,
  type: TQuoteType,
  created_at: string,
  created_by_identity: {
    name: string,
  },
};

export type TQuoteOfferRisk = {
  riskPremium?: number,
  description: string,
  id: string,
  premium?: string,
  product_tag: string,
};

export interface TQuoteOffer {
  actual: boolean,
  comment?: string,
  created_at?: string,
  created_by?: string,
  details?: {
    offerId: string,
    offerNumber: string,
    risks: {
      code: string,
      name: string,
      riskPremium: string,
      tag: string,
    }[],
    vehiclePrice: {
      gross?: number,
      net?: number,
    },
  },
  id: string,
  installments?: {
    amount: string,
    date: string,
  }[],
  insurer_tag: string,
  log_rec_id?: string,
  name?: string,
  original_offer_id?: string,
  original_offer_no?: string,
  premium: string,
  risks: TQuoteOfferRisk[],
  errors?: AxiosError,
  updated_at?: string,
  updated_by?: string,
  isCanceled?: boolean,
}

export interface TQuoteCalculationInProgress extends TQuoteOffer {
  status?: 'progress' | 'success' | 'error',
  isCanceled?: boolean,
}

export type TQuoteData = {
  // allowed_events: null
  installments_no?: number,
  sales_channel?: {
    source: string,
  },
  additional_properties?: Record<string, any>,
  customer_ref?: {
    id: string,
    revision_id: string,
  },
  created_at?: string,
  created_by?: string,
  customer_id?: string,
  offers?: TQuoteOffer[],
  id?: string,
  number?: string,
  products?: TQuoteProduct[],
  start_date?: string,
  end_date?: string,
  status?: TQuoteStatus,
  type?: TQuoteType,
  objects?: {
    vehicle?: TVehicleObject[],
    'border-vehicle'?: TBorderVehicleObject[],
    participant?: Partial<TParticipantProps>[],
    'border-participant'?: Partial<TParticipantProps>[],
    traveller?: {
      object_id: string,
      props: Partial<TTraveller>,
    }[],
    'travel-coverage'?: {
      object_id: string,
      props: TTravelCoverage,
    }[],
    'assistance-assistance'?: {
      object_id: string,
      props: TAssistance,
    }[],
  },
  updated_at?: string,
  updated_by?: string,
  // UI only flags
  is_new_quote?: boolean,
  policy_id?: string,
  idd_required?: boolean,
  selectedPackage?: string,
};

export type TQuoteStatus = typeof INITIALIZED_STATUS | typeof IDD_REQUIRED_STATUS | typeof POLICY_ISSUED_STATUS;
export type TQuoteType = typeof INSURANCE_PRODUCT_MOTOR | typeof INSURANCE_PRODUCT_TRAVEL | typeof INSURANCE_PRODUCT_BORDER | typeof INSURANCE_PRODUCT_ASSISTANCE;

type TQuoteListValue = string | TQuoteType | TQuoteStatus | undefined;
export type TQuoteListFilter = Record<string, TQuoteListValue>;
export type TQuoteListUpdateFilter = (name: string, value: TQuoteListValue) => void;
