import React, { createContext, useContext, useState } from 'react';
import { JSONSchema, JSONSchemaProp, TError, TFetchStatus } from 'models';
import axios, { AxiosPromise } from 'axios';
import { preflightAPICall, processResponseErrors } from 'utils';
import apiConfig from 'config/api';

type ContextProps = {
  customerSchemaNetworkStatus: TFetchStatus,
  customerSchema: JSONSchema,
  customerContactsSchema: JSONSchema,
  customerAddressSchema: JSONSchema,
  customerContactPersonSchema: JSONSchema,
  customerSchemaErrors: TError[],
  getCustomerSchemas: () => void,
  vehicleSchemaErrors: TError[],
  vehicleSchemaFetchStatus: TFetchStatus,
  vehicleSchema: JSONSchemaProp,
  getVehicleSchema: () => void,
  borderVehicleSchemaErrors: TError[],
  borderVehicleSchemaFetchStatus: TFetchStatus,
  borderVehicleSchema: JSONSchemaProp,
  getBorderVehicleSchema: () => void,
  participantsSchemaErrors: TError[],
  participantsSchemaFetchStatus: TFetchStatus,
  participantsSchema: JSONSchemaProp,
  getParticipantsSchema: () => void,
  borderParticipantsSchemaErrors: TError[],
  borderParticipantsSchemaFetchStatus: TFetchStatus,
  borderParticipantsSchema: JSONSchemaProp,
  getBorderParticipantsSchema: () => void,
  travelCoverageSchemaErrors: TError[],
  travelCoverageSchemaFetchStatus: TFetchStatus,
  travelCoverageSchema: JSONSchemaProp,
  getTravelCoverageSchema: () => void,
  travellerSchemaErrors: TError[],
  travellerSchemaFetchStatus: TFetchStatus,
  travellerSchema: JSONSchemaProp,
  getTravellerSchema: () => void,
  assistanceSchemaErrors: TError[],
  assistanceSchemaFetchStatus: TFetchStatus,
  assistanceSchema: JSONSchemaProp,
  getAssistanceSchema: () => void,
  GDPRSchemaErrors: TError[],
  GDPRSchemaFetchStatus: TFetchStatus,
  GDPRSchema: JSONSchemaProp,
  getGDPRSchema: () => void,
};

const API_CUSTOMER_SCHEMA_URL = `${apiConfig.QMT_SCHEMAS}/customer/profile`;
const API_VEHICLE_SCHEME_URL = `${apiConfig.QMT_SCHEMAS}/object/vehicle`;
const API_BORDER_VEHICLE_SCHEME_URL = `${apiConfig.QMT_SCHEMAS}/object/border-vehicle`;
const API_PARTICIPANTS_SCHEME_URL = `${apiConfig.QMT_SCHEMAS}/object/participants`;
const API_BORDER_PARTICIPANTS_SCHEME_URL = `${apiConfig.QMT_SCHEMAS}/object/border-participant`;
const API_TRAVEL_COVERAGE_SCHEME_URL = `${apiConfig.QMT_SCHEMAS}/object/travel-coverage`;
const API_TRAVELLER_SCHEME_URL = `${apiConfig.QMT_SCHEMAS}/object/traveller`;
const API_ASSISTANCE_OBJECT_SCHEME_URL = `${apiConfig.QMT_SCHEMAS}/object/assistance-assistance`;
const API_GET_GDPR_SCHEME_PATH = `${apiConfig.GDPR}/schema`;
const API_GDPR_SCHEME_URL = `${apiConfig.QMT_SCHEMAS}/%PATH%`;

const SchemeContext = createContext<ContextProps>({
  customerSchemaNetworkStatus: null,
  customerSchema: {},
  customerContactsSchema: {},
  customerAddressSchema: {},
  customerContactPersonSchema: {},
  customerSchemaErrors: [],
  getCustomerSchemas: () => {},
  vehicleSchemaErrors: [],
  vehicleSchemaFetchStatus: null,
  vehicleSchema: {},
  getVehicleSchema: () => {},
  borderVehicleSchemaErrors: [],
  borderVehicleSchemaFetchStatus: null,
  borderVehicleSchema: {},
  getBorderVehicleSchema: () => {},
  participantsSchemaErrors: [],
  participantsSchemaFetchStatus: null,
  participantsSchema: {},
  getParticipantsSchema: () => {},
  borderParticipantsSchemaErrors: [],
  borderParticipantsSchemaFetchStatus: null,
  borderParticipantsSchema: {},
  getBorderParticipantsSchema: () => {},
  travelCoverageSchemaErrors: [],
  travelCoverageSchemaFetchStatus: null,
  travelCoverageSchema: {},
  getTravelCoverageSchema: () => {},
  travellerSchemaErrors: [],
  travellerSchemaFetchStatus: null,
  travellerSchema: {},
  getTravellerSchema: () => {},
  assistanceSchemaErrors: [],
  assistanceSchemaFetchStatus: null,
  assistanceSchema: {},
  getAssistanceSchema: () => {},
  GDPRSchemaErrors: [],
  GDPRSchemaFetchStatus: null,
  GDPRSchema: {},
  getGDPRSchema: () => {},
});

export const ProvideScheme = ({ children }: {children: JSX.Element}) => {
  const value = useProvideScheme();
  return (
    // @ts-ignore
    <SchemeContext.Provider value={value}>
      {children}
    </SchemeContext.Provider>
  );
};

export const useScheme = () => useContext(SchemeContext);

const useProvideScheme = () => {

  const [customerSchema, updateCustomerSchema] = useState<JSONSchema>({});
  const [customerSchemaNetworkStatus, updateCustomerSchemaNetworkStatus] = useState<TFetchStatus>(null);
  const [customerContactsSchema, updateCustomerContactsSchema] = useState<JSONSchema>({});
  const [customerAddressSchema, updateCustomerAddressesSchema] = useState<JSONSchema>({});
  const [customerContactPersonSchema, updateCustomerContactPersonsSchema] = useState<JSONSchema>({});
  const [customerSchemaErrors, updateCustomerSchemaErrors] = useState<TError[]>([]);

  const [vehicleSchema, updateVehicleSchema] = useState<JSONSchema>({});
  const [vehicleSchemaFetchStatus, updateVehicleSchemaFetchStatus] = useState<TFetchStatus>(null);
  const [vehicleSchemaErrors, updateVehicleSchemaErrors] = useState<TError[]>([]);

  const [borderVehicleSchema, updateBorderVehicleSchema] = useState<JSONSchema>({});
  const [borderVehicleSchemaFetchStatus, updateBorderVehicleSchemaFetchStatus] = useState<TFetchStatus>(null);
  const [borderVehicleSchemaErrors, updateBorderVehicleSchemaErrors] = useState<TError[]>([]);

  const [participantsSchema, updateParticipantsSchema] = useState<JSONSchema>({});
  const [participantsSchemaFetchStatus, updateParticipantsSchemaFetchStatus] = useState<TFetchStatus>(null);
  const [participantsSchemaErrors, updateParticipantsSchemaErrors] = useState<TError[]>([]);

  const [borderParticipantsSchema, updateBorderParticipantsSchema] = useState<JSONSchema>({});
  const [borderParticipantsSchemaFetchStatus, updateBorderParticipantsSchemaFetchStatus] = useState<TFetchStatus>(null);
  const [borderParticipantsSchemaErrors, updateBorderParticipantsSchemaErrors] = useState<TError[]>([]);

  const [travelCoverageSchema, updateTravelCoverageSchema] = useState<JSONSchema>({});
  const [travelCoverageSchemaFetchStatus, updateTravelCoverageSchemaFetchStatus] = useState<TFetchStatus>(null);
  const [travelCoverageSchemaErrors, updateTravelCoverageSchemaErrors] = useState<TError[]>([]);

  const [travellerSchema, updateTravellerSchema] = useState<JSONSchema>({});
  const [travellerSchemaFetchStatus, updateTravellerSchemaFetchStatus] = useState<TFetchStatus>(null);
  const [travellerSchemaErrors, updateTravellerSchemaErrors] = useState<TError[]>([]);

  const [assistanceSchema, updateAssistanceSchema] = useState<JSONSchema>({});
  const [assistanceSchemaFetchStatus, updateAssistanceSchemaFetchStatus] = useState<TFetchStatus>(null);
  const [assistanceSchemaErrors, updateAssistanceSchemaErrors] = useState<TError[]>([]);

  const [GDPRSchema, updateGDPRSchema] = useState<JSONSchema>({});
  const [GDPRSchemaFetchStatus, updateGDPRSchemaFetchStatus] = useState<TFetchStatus>(null);
  const [GDPRSchemaErrors, updateGDPRSchemaErrors] = useState<TError[]>([]);

  const getCustomerSchemas = () => {
    updateCustomerSchemaNetworkStatus('loading');
    preflightAPICall(() => {
      axios.get(API_CUSTOMER_SCHEMA_URL).then(response => {
        updateCustomerSchema(response.data);
        if (response.data) {
          const requests: AxiosPromise[] = [
            axios.get(response.data.properties.contacts.items.$ref),
            axios.get(response.data.properties.addresses.items.$ref),
            axios.get(response.data.properties.contact_persons.items.$ref),
          ];

          axios.all(requests).then(axios.spread((...responses) => {
            updateCustomerContactsSchema(responses[0].data);
            updateCustomerAddressesSchema(responses[1].data);
            updateCustomerContactPersonsSchema(responses[2].data);
            updateCustomerSchemaNetworkStatus('success');
          })).catch(error => {
            console.error(error);
            updateCustomerSchemaErrors(processResponseErrors(error));
            updateCustomerSchemaNetworkStatus('failed');
          });

        }
      }).catch(error => {
        console.error(error);
        updateCustomerSchemaErrors(processResponseErrors(error));
        updateCustomerSchemaNetworkStatus('failed');
      });
    });
  };

  const getVehicleSchema = () => {
    updateVehicleSchemaFetchStatus('loading');
    preflightAPICall(() => {
      axios.get(API_VEHICLE_SCHEME_URL).then(response => {
        updateVehicleSchema(response.data);
        updateVehicleSchemaFetchStatus('success');
      }).catch(error => {
        console.error(error);
        updateVehicleSchemaErrors(processResponseErrors(error));
        updateVehicleSchemaFetchStatus('failed');
      });
    });
  };

  const getBorderVehicleSchema = () => {
    updateBorderVehicleSchemaFetchStatus('loading');
    preflightAPICall(() => {
      axios.get(API_BORDER_VEHICLE_SCHEME_URL).then(response => {
        updateBorderVehicleSchema(response.data);
        updateBorderVehicleSchemaFetchStatus('success');
      }).catch(error => {
        console.error(error);
        updateBorderVehicleSchemaErrors(processResponseErrors(error));
        updateBorderVehicleSchemaFetchStatus('failed');
      });
    });
  };

  const getParticipantsSchema = () => {
    updateParticipantsSchemaFetchStatus('loading');
    preflightAPICall(() => {
      axios.get(API_PARTICIPANTS_SCHEME_URL).then(response => {
        updateParticipantsSchema(response.data);
        updateParticipantsSchemaFetchStatus('success');
      }).catch(error => {
        console.error(error);
        updateParticipantsSchemaErrors(processResponseErrors(error));
        updateParticipantsSchemaFetchStatus('failed');
      });
    });
  };

  const getBorderParticipantsSchema = () => {
    updateBorderParticipantsSchemaFetchStatus('loading');
    preflightAPICall(() => {
      axios.get(API_BORDER_PARTICIPANTS_SCHEME_URL).then(response => {
        updateBorderParticipantsSchema(response.data);
        updateBorderParticipantsSchemaFetchStatus('success');
      }).catch(error => {
        console.error(error);
        updateBorderParticipantsSchemaErrors(processResponseErrors(error));
        updateBorderParticipantsSchemaFetchStatus('failed');
      });
    });
  };

  const getTravelCoverageSchema = () => {
    updateTravelCoverageSchemaFetchStatus('loading');
    preflightAPICall(() => {
      axios.get(API_TRAVEL_COVERAGE_SCHEME_URL).then(response => {
        updateTravelCoverageSchema(response.data);
        updateTravelCoverageSchemaFetchStatus('success');
      }).catch(error => {
        console.error(error);
        updateTravelCoverageSchemaErrors(processResponseErrors(error));
        updateTravelCoverageSchemaFetchStatus('failed');
      });
    });
  };

  const getTravellerSchema = () => {
    updateTravellerSchemaFetchStatus('loading');
    preflightAPICall(() => {
      axios.get(API_TRAVELLER_SCHEME_URL).then(response => {
        updateTravellerSchema(response.data);
        updateTravellerSchemaFetchStatus('success');
      }).catch(error => {
        console.error(error);
        updateTravellerSchemaErrors(processResponseErrors(error));
        updateTravellerSchemaFetchStatus('failed');
      });
    });
  };

  const getAssistanceSchema = () => {
    updateAssistanceSchemaFetchStatus('loading');
    preflightAPICall(() => {
      axios.get(API_ASSISTANCE_OBJECT_SCHEME_URL).then(response => {
        updateAssistanceSchema(response.data);
        updateAssistanceSchemaFetchStatus('success');
      }).catch(error => {
        console.error(error);
        updateAssistanceSchemaErrors(processResponseErrors(error));
        updateAssistanceSchemaFetchStatus('failed');
      });
    });
  };

  const getGDPRSchema = () => {
    updateGDPRSchemaFetchStatus('loading');
    preflightAPICall(() => {
      axios.get(API_GET_GDPR_SCHEME_PATH).then(response => {
        const path = response.data.schema_path;
        axios.get(API_GDPR_SCHEME_URL.replace('%PATH%', path)).then(response => {
          updateGDPRSchema({
            ...response.data,
            path
          });
          updateGDPRSchemaFetchStatus('success');
        });
      }).catch(error => {
        console.error(error);
        updateGDPRSchemaErrors(processResponseErrors(error));
        updateGDPRSchemaFetchStatus('failed');
      });
    });
  };

  return {
    customerSchemaNetworkStatus,
    customerSchema,
    customerContactsSchema,
    customerAddressSchema,
    customerContactPersonSchema,
    customerSchemaErrors,
    getCustomerSchemas,
    vehicleSchemaErrors,
    vehicleSchemaFetchStatus,
    vehicleSchema,
    getVehicleSchema,
    borderVehicleSchemaErrors,
    borderVehicleSchemaFetchStatus,
    borderVehicleSchema,
    getBorderVehicleSchema,
    participantsSchemaErrors,
    participantsSchemaFetchStatus,
    participantsSchema,
    getParticipantsSchema,
    borderParticipantsSchemaErrors,
    borderParticipantsSchemaFetchStatus,
    borderParticipantsSchema,
    getBorderParticipantsSchema,
    travelCoverageSchemaErrors,
    travelCoverageSchemaFetchStatus,
    travelCoverageSchema,
    getTravelCoverageSchema,
    travellerSchemaErrors,
    travellerSchemaFetchStatus,
    travellerSchema,
    getTravellerSchema,
    assistanceSchemaErrors,
    assistanceSchemaFetchStatus,
    assistanceSchema,
    getAssistanceSchema,
    GDPRSchemaErrors,
    GDPRSchemaFetchStatus,
    GDPRSchema,
    getGDPRSchema,
  };
};
