import styled from 'styled-components';
import { Input, Select, SvgIcon, Switch } from '@insly/qmt-reactjs-ui-lib';
import { Loader } from 'styles/common';
import { ControlsButton, OffersContentControlsRow } from '../partials/ControlsNextStep/styles';

export const QuoteDataWrapper = styled.div`
  position: sticky;
  top: -45px;
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;
`;

export const QuoteDataStyled = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.borderHighlighted};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding-top: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 100px);
`;

export const QuoteList = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
`;

export const QuoteDataHead = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeB};
  padding: 0 20px 16px;
`;

export const QuoteDataHeadPriceAndLogo = styled.div`
  flex: 2;
  gap: 4px;
`;

export const QuoteDataHeadPrice = styled.div`
  ${({ theme }) => theme.text.h3Strong};
`;

export const QuoteDataHeadPriceLabel = styled.span`
  ${({ theme }) => theme.text.headerH3};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const QuoteDataHeadName = styled.div`
  flex: 3;
  ${({ theme }) => theme.text.h3Strong};
`;
export const QuoteDataHeadPaymentTerms = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary};
  ${({ theme }) => theme.text.medium};
`;

export const QuoteDataHeadCloseIcon = styled(SvgIcon)`
  width: 24px;
  height: 24px;
`;

export const QuoteDataItemStyled = styled.div`
  padding: 20px;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.strokeB};
  }
`;

export const QuoteDataItemHead = styled.div<{ clickAble?: boolean }>`
  display: flex;
  cursor: ${({ clickAble }) => clickAble ? 'pointer' : null};
`;

export const QuoteDataItemBody = styled.div<{ isOpened: boolean }>`
  padding: ${({ isOpened }) => isOpened ? '10px' : 0} 6px;
  max-height: ${({ isOpened }) => isOpened ? 'auto' : 0};
  overflow: ${({ isOpened }) => isOpened ? null : 'hidden'};
`;

export const OptionStyled = styled.div`
 &:not(:last-child) {
   padding-bottom: 6px;
 }
`;

export const QuoteDataItemlabel = styled.span`
  ${({ theme }) => theme.text.h3Strong};
  flex: 2;
`;

export const QuoteDataItemData = styled.div<{hasBody?: boolean}>`
  ${({ theme }) => theme.text.small};
  position: relative;
  flex: 3;
  margin-right: ${({ hasBody }) => hasBody ? '24px' : '48px'};
  color: #5F5F5F;
`;

export const QuoteDataItemDataCheck = styled.div<{active: boolean}>`
  position: absolute;
  top: -3px;
  right: calc(100% + 20px);
  background-color: ${({ theme, active }) => active ? theme.colors.primary : 'transparent'};
  border: 1px solid ${({ theme, active }) => active ? 'transparent' : theme.colors.strokeA};
  border-radius: 50%;
  width: 24px;
  height: 24px;
`;

export const QuoteDataItemDataCheckIcon = styled(SvgIcon)`
  width: 100%;
  height: 100%;
`;

export const QuoteDataFooter = styled.div`
  margin-top: auto;
  border-top: 1px solid ${({ theme }) => theme.colors.strokeB};
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  gap: 16px;
`;

export const Center = styled.div`
  ${({ theme }) => theme.text.h2}
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const SwitchLabeled = styled.div`
  display: flex;
  align-items: center;
`;

export const SwitchLabel = styled.div`
  ${({ theme }) => theme.text.normal};
  flex: 1;
  padding-right: 16px;
  cursor: pointer;
`;

export const SwitchStyled = styled(Switch)`
  width: 40px;
  height: 22px;
`;

export const InputStyled = styled(Input)`
  height: 50px;
  padding: 10px;
`;

export const SelectStyled = styled(Select)`
  height: 50px;
`;

export const Toggler = styled(SvgIcon)<{opened: boolean}>`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  transform: ${({ opened }) => opened ? 'rotate(-90deg)' : 'rotate(90deg)'};
`;

export const InsurerImage = styled.img<{ height: string }>`
  object-fit: contain;
  width: auto;
  height: ${({ height }) => height};
`;

export const ExternalLinkButtonLoader = styled(Loader)`
  width: 24px;
  height: 24px;
`;

export const ComparisonTableBackButton = styled(ControlsButton)`
  gap: 8px;
`;

export const DefaultIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OffersSortOrderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ComparisonTableControlsRow = styled(OffersContentControlsRow)`
  justify-content: flex-start;
`;
