import styled from 'styled-components';
import { Button, SvgIcon } from '@insly/qmt-reactjs-ui-lib';

export const AdditionalData = styled.div`
`;

export const AdditionalDataBlock = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const AdditionalDataWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AdditionalDataValue = styled.div`
  ${({ theme }) => theme.text.normal};
`;

export const AdditionalDataIcon = styled(SvgIcon)`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

export const AdditionalDataInfo = styled.div`
  padding-left: ${24 + 16}px; // Icon + margin
  ${({ theme }) => theme.text.normal};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const Email = styled.a`
  ${({ theme }) => theme.text.normal};
  color: ${({ theme }) => theme.colors.link};
  text-decoration: none;
`;

export const AddressBlock = styled.div<{ borderd?: boolean }>`
  border-top: ${({ borderd, theme }) => borderd ? `1px solid ${theme.colors.strokeB}` : null};
  border-bottom: ${({ borderd, theme }) => borderd ? `1px solid ${theme.colors.strokeB}` : null};
  padding: ${({ borderd }) => borderd ? '32px' : null} 0;
`;

export const ButtonStandard = styled(Button)`
  width: fit-content;
  height: 40px;
  &:not(:last-child) {
    margin-right: 16px;
  }
`;

export const ButtonAddContact = styled(ButtonStandard)`
  background-color: #ECE3DA;
  flex: 1;
`;
