import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  borderRadius: '5px',

  colors: {
    primary: '#FF7D00',
    secondary: '#00AF87',
    ternary: '#000',
    white: '#FFF',
    black: '#000',
    textSecondary: '#777',
    text: '#000',
    textOff: '#D0D0D0',
    strokeA: 'rgba(0, 0, 0, 0.2)',
    strokeB: 'rgba(0, 0, 0, 0.1)',
    label: '#8D8D8D',
    border: '#D6D6D6',
    borderHighlighted: '#000',
    sidebarBg: '#FAF3EB',
    sidebarBgB: '#ECE3DA',
    buttonHover: '#454545',
    hoverGray: '#F0F0F0',
    link: '#D2863C',
    buttonPreset: {
      secondary: {
        bg: '#FAF3EB',
        hover: '#F1E5D5',
        text: '#000',
        textHover: '#000',
      },
      primary: {
        bg: '#FF7D00',
        hover: 'rgb(255, 155, 0)',
        text: '#FFF',
        textHover: '#FFF',
      },
      ternary: {
        bg: '#F0F0F0',
        hover: '#E4E4E4',
        text: '#000',
        textHover: '#000',
      },
      transparent: {
        bg: 'transparent',
        hover: 'transparent',
        text: '#000',
        textHover: '#000',
      }
    },
    subtle: '#999',
    error: '#E23009',
    errorLight: '#423A3A',
    borderError: '#FFA693',
    borderErrorLight: '#CF3F20',
    hover: '#F0F0F0',
    quote_status: {
      'copy-in-progress': '#FF7D00',
      initialized: '#DED5CA',
      accepted: '#00AF87',
      unknown: '#000',
      idd_required: '#CF0000',
    },
    idd_status: {
      1: '#DED5CA',
      2: '#00AF87',
      3: '#FF7D00',
      4: '#00AF87',
      unknown: '#000'
    }
  },

  font: {
    light: 'MuseoSans300Light',
    medium: 'MuseoSans500Medium',
    bold: 'MuseoSans700Bold',
  },
  text: {
    small: {
      'font-family': 'MuseoSans500Medium',
      'font-size': '12px'
    },
    normal: {
      'font-family': 'MuseoSans500Medium',
      'font-size': '14px',
    },
    medium: {
      'font-family': 'MuseoSans300Light',
      'font-size': '16px',
    },
    label: {
      'font-family': 'MuseoSans700Bold',
      'font-size': '10px',
    },
    inputValue: {
      'font-family': 'MuseoSans500Medium',
      'font-size': '14px',
    },
    h2: {
      'font-family': 'MuseoSans700Bold',
      'font-size': '20px',
    },
    headerH1: {
      'font-family': 'MuseoSans700Bold',
      'font-size': '24px',
    },
    headerH2: {
      'font-family': 'MuseoSans500Medium',
      'font-size': '20px',
    },
    headerH3: {
      'font-family': 'MuseoSans300Light',
      'font-size': '18px',
    },
    h3: {
      'font-family': 'MuseoSans500Medium',
      'font-size': '18px',
    },
    h3Strong: {
      'font-family': 'MuseoSans500Medium',
      'font-size': '18px',
      'line-height': '120%',
    },
    tiny: {
      'font-family': 'MuseoSans500Medium',
      'font-size': '11px',
    },
  }

};

export { theme };
