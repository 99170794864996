import React from 'react';
import { useAuth } from 'context/Auth';
import HeaderAuthed from './Authed';
import { isIframe, translate } from 'utils';

export const Header = () => {
  const { user } = useAuth();

  if (isIframe()) {
    return <div />;
  }

  return user ? (
    <HeaderAuthed
      textNewQuote={translate({ key: 'quote.create_new_quote' })}
      user={user}
      // notifications={[
      // {
      //   unread: true,
      //   title: 'Nowe zapytanie',
      //   body: 'Otrzymałeś nowe zapytanie o kalkulację od: Aleksander Głowacki',
      //   type: 'message'
      // },
      // {
      //   unread: false,
      //   title: 'Nowe zapytanie',
      //   body: 'Otrzymałeś nowe zapytanie o kalkulację od: Aleksander Głowacki',
      //   type: 'message'
      // },
      // {
      //   unread: false,
      //   title: 'Nowe zapytanie',
      //   body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget rhoncus est. Curabitur tristique quis sapien id placerat. Pellentesque urna libero, posuere id ante id, ultrices feugiat ex. Vestibulum non aliquam nisi.',
      //   type: 'calculations'
      // },
      // {
      //   unread: false,
      //   title: 'Wymagane działanie',
      //   body: 'Lorem ipsum ustawienia Twojego konta',
      //   type: 'settings'
      // }
      // ]}
    />
  ) : null;
};
