import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TUserData, useAuth } from 'context/Auth';
import {
  getQueryParam,
  configAxios,
  makeRequestQuery,
  getUserRoles,
  isIframe, getCookie,
} from 'utils';
import axios from 'axios';
import { LoaderAuth } from '../styles';
import { API_MASTER_URL_USER } from 'config/api';
import { SelectBroker } from './components';
import { getAPIBroker, redirectUser, setXBrokerHeader } from './utils';
import { getTranslations } from 'config/translation';

export const BodySSO = () => {
  const [iframeLoaded, updateIframeLoaded] = useState(false);
  const [step, updateStep] = useState<'login' | 'selectBroker'>('login');
  const [user, updateUser] = useState<TUserData>(null);

  useEffect(() => {
    const handler = (event: { data: any }) => {
      if (typeof event.data === 'string' && event.data?.length) {
        processMessage(event.data);
      }
    };

    window.addEventListener('message', handler);

    // clean up
    return () => window.removeEventListener('message', handler);

    // eslint-disable-next-line
  }, []); // empty array => run only once

  useEffect(() => {
    if (isIframe() && step === 'login' && !getCookie('authentication_result')) {
      window.parent?.postMessage(
        {
          name: 'isNotLoggedIn',
          error: false,
        },
        '*'
      );
    }
  }, []);

  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();

  const processMessage = (eventData: string) => {
    if (!iframeLoaded) {
      updateIframeLoaded(true);
    }

    const data = JSON.parse(eventData);
    switch (data.name) {
      case 'login':
        if (!data.error) {
          auth.signin(() => {
            const authData = data?.message;
            const authHeader = `${authData.token_type} ${authData.access_token}`;
            // sessionStorage.setItem('authentication_result', JSON.stringify(authData));
            configAxios({ authHeader });

            getUserFromMasterService();

          });
        }
        break;
      case 'logout':
        if (!data.error) {
          auth.signout();
          if (window.location.pathname !== '/auth/login') {
            window.location.href = '/auth/login';
          }
        }
        break;
      case 'lang':
        if (!data.error) {
          getTranslations({
            locale: data?.message,
          });

          auth.setLocale(data?.message);
        }
        break;
    }
  };

  const getUserFromMasterService = (isBrokerSet?: boolean) => {

    axios.get(`${API_MASTER_URL_USER}/info`).then(response => {
      if (response.data) {
        const roles = getUserRoles(response.data);
        const broker = getAPIBroker();

        if (!response.data.masquerade && roles && roles.length > 1 && !isBrokerSet) {
          if (broker) {
            setXBrokerHeader(broker, getCookie('authentication_result') as string, getUserFromMasterService);
          } else {
            updateUser(response.data);
            updateStep('selectBroker');
          }

        } else {
          if (!isIframe() && response.data.props.sync) {
            window.location.replace(response.data.props.sync);
          } else {
            auth.setUser(response.data);
            redirectUser(location, history);
          }
        }
      } else {
        console.error(response);
      }

    }).catch(error => {
      console.error(error);
    });
  };

  switch (step) {
    case 'login':
      return (
        <>
          {!iframeLoaded ? (<LoaderAuth />) : null}
          <iframe
            style={{
              zIndex: 1
            }}
            title="login"
            src={`${window._env_.SSO_URL}${buildQueryString(location)}`}
            frameBorder={0}
            height={'100%'}
            width={'100%'}
          />
        </>
      );
    case 'selectBroker':
      return <SelectBroker user={user} getUserFromMasterService={getUserFromMasterService} />;
  }
};

const buildQueryString = (location: any) => {
  const from = location?.state?.from;

  const queryObject: Record<string, boolean | string> = {};

  if (getQueryParam('logout')) {
    queryObject.logout = true;
  }

  if (getQueryParam('resetPassword')) {
    queryObject.resetPassword = true;
  }

  if (from && from.search) {
    const broker = getQueryParam('broker', from.search);

    if (broker) {
      queryObject.broker = broker;
    }
  }

  return makeRequestQuery(queryObject);
};
