import { TQuoteType } from 'app/Pages/Quote/models';
import {
  INSURANCE_PRODUCT_ASSISTANCE,
  INSURANCE_PRODUCT_BORDER,
  INSURANCE_PRODUCT_MOTOR,
  INSURANCE_PRODUCT_TRAVEL
} from 'config/consts';

export const getQuoteTypeIconName = (productType: TQuoteType) => {
  switch (productType) {
    case INSURANCE_PRODUCT_TRAVEL:
      return 'travel';
    case INSURANCE_PRODUCT_BORDER:
      return 'border';
    case INSURANCE_PRODUCT_MOTOR:
      return 'vehicle';
    case INSURANCE_PRODUCT_ASSISTANCE:
      return 'assistance';
    default:
      return '';
  }
};
