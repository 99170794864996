import styled from 'styled-components';
import { Loader } from 'styles/common';

export const PageAuthStyled = styled.div`
  min-height: 100vh;
  display: grid;
  grid-gap: 0;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "content"
    "footer";
  .header {
    grid-area: header;
  }
  .content {
    grid-area: content;
  }
  .footer {
    grid-area: footer;
  }
`;

export const Content = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoaderAuth = styled(Loader)`
  position: absolute;
`;

export const SelectBrokerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 300px;
  margin: 8px auto 0;
`;

export const SelectBrokerTitle = styled.div`
  ${({ theme }) => theme.text.medium};
  text-align: center;
  max-width: 600px;
`;
