import {
  customerEmailValidation,
  customerPhoneValidation,
  setRules,
  translateTitleInScheme,
  validate
} from 'utils';
import { CUSTOMER_CONTACT_VALIDATION_FIELDS } from 'config/itemLists';
import { JSONSchema, TError } from 'models';

export const contactsValidation = (
  contacts: { subtype?: string, type: 'phone' | 'email', value: string }[],
  contactsRequiredFields: string[],
  errorsUI: TError[],
  props?: JSONSchema,
) => {
  contacts.forEach((contact, contactIndex) => {
    contactsRequiredFields.forEach((item) => {
      let pattern, regExpErrorMessage;

      if (contact.type === 'email' && item === 'value') {
        const processedObj = customerEmailValidation();
        pattern = processedObj.pattern;
        regExpErrorMessage = processedObj.regExpErrorMessage;
      }

      if (contact.type === 'phone' && item === 'value') {
        const processedObj = customerPhoneValidation();
        pattern = processedObj.pattern;
        regExpErrorMessage = processedObj.regExpErrorMessage;
      }

      const rules = setRules({
        props,
        item,
        pattern,
        regExpErrorMessage
      });

      if (item === 'value' && !contact[item]) {
        return;
      }

      const errors = validate({
        field: item,
        parentField: 'contacts',
        listIndex: contactIndex,
        fieldName: props && props[item] ? translateTitleInScheme(props[item]) : undefined,
        value: contact[item as 'type' | 'subtype' | 'value'],
        rules,
        uncommonError: CUSTOMER_CONTACT_VALIDATION_FIELDS.includes(item)
      });

      if (errors?.length) {
        errorsUI = [
          ...errorsUI,
          ...errors
        ];
      }
    });
  });

  return errorsUI;
};

