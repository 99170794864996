import { TError } from 'models';
import { combineErrors } from './combineErrors';
import { processErrorsToString } from './processErrorsToString';

export const combineErrorsByParentAndIndex = (fieldsArray: string[], errors: TError[], parentField: string, listIndex: number, toString?: boolean) => {

  const filteredErrors = errors.filter(e => e.parentField === parentField && e.listIndex === listIndex);

  if (filteredErrors?.length) {
    if (toString) {
      return processErrorsToString(fieldsArray, filteredErrors);
    } else {
      return combineErrors(fieldsArray, filteredErrors);
    }
  }

};
