import React, { useEffect, useRef, useState } from 'react';
import {
  JSONSchema,
  JSONSchemaProp,
  TAddress,
  TAddressType,
  TError,
  TCustomerType,
  TCustomerContact,
  TCustomerAdd,
  TCustomer,
} from 'models';
import { PhonesAndEmails } from 'app/Pages/Clients/common';
import {
  CustomerProfile,
  renderUserType,
  validateCustomer
} from '../common';
import { AxiosError } from 'axios';
import LoadingOverlay from 'helpers/LoadingOverlay';
import {
  processAPIError,
  validate,
  setRules,
  filterUncommonErrors,
  translateTitleInScheme,
  translate,
  scrollToFirstError,
  getOptionsFromEnum,
  getErrorsByFieldName,
  cloneObject,
} from 'utils';
import { ADDRESS_VALIDATION_FIELDS } from 'config/itemLists';
import { renderErrors } from 'app/Components/common/Error';
import { shouldShowMoreShow } from 'app/Components/common/ShowMore';
import { DrawerBodyStyled, DrawerCloseIcon, DrawerDataForm, DrawerHeaderRow, DrawerHeaderRowTitle } from 'app/Components/common/Drawer/styles';
import { renderControls, scrollElementToBottom } from 'app/Components/common/Drawer';
import { AddressFormPolicyHolder } from 'app/Components/common/AddressForm';
import { addCustomerCommonFields } from 'app/Components/common/Customer';
import { useAuth } from 'context/Auth';
import { Checkbox, Select } from '@insly/qmt-reactjs-ui-lib';
import { DEFAULT_RESIDENT_COUNTRY, DEFAULT_RESIDENT_COUNTRY_CODE } from 'config/api';
import { ContentBodyRow } from 'app/Pages/Quote/Profile/body/styles';
import { SOLE_TRADER_CUSTOMER_TYPE } from 'config/consts';
import { updatePolicyHolderType } from 'app/Pages/Quote/Profile/body/Participants';
import { ADDRESS_DEFAULT, EMAIL_DEFAULT, PHONE_DEFAULT } from 'app/Pages/Clients/consts';
import { contactsValidation } from 'app/Pages/Quote/Profile/body/Participants/common/utils';
import { ShowMoreForParticipants } from 'app/Pages/QuoteAssistance/Profile/body/Assistance/Drawer/body/styles';

export type THandlePostCustomer = (userForm: TCustomer, onError: (error: AxiosError) => void) => void;

interface IProps {
  customerSchemaContext: Record<string, unknown>,
  handleDrawerClose: () => void,
  handlePostCustomer: THandlePostCustomer,
}

type TValue = string | boolean | string[] | number | TCustomerContact[] | TAddress['props'][];

//@TODO: Update Drawer Lib component and make children not required and remove unnecessary <div />
const DrawerAddUser = ({ customerSchemaContext, handleDrawerClose, handlePostCustomer }: IProps) => {
  const [showMore, updateShowMore] = useState(false);

  const bodyEl = useRef<HTMLDivElement>(null);

  return (
    <>
      {renderHeaderRow(translate({ key: 'customer.add_customer' }), handleDrawerClose)}
      <DrawerBody
        handlePostCustomer={handlePostCustomer}
        customerSchemaContext={customerSchemaContext}
        bodyEl={bodyEl}
        showMore={showMore}
        updateShowMore={updateShowMore}
      />
      <ShowMoreForParticipants
        show={showMore}
        onClick={() => scrollElementToBottom(bodyEl.current)}
      />
    </>
  );
};

const DrawerBody = ({
  handlePostCustomer,
  customerSchemaContext,
  bodyEl,
  showMore,
  updateShowMore
}: {
  handlePostCustomer: THandlePostCustomer,
  customerSchemaContext: Record<string, unknown>,
  bodyEl: React.RefObject<HTMLDivElement>,
  showMore: boolean,
  updateShowMore: (showMore: boolean) => void,
}) => {
  useEffect(() => {
    if (bodyEl.current !== null) {
      shouldShowMoreShow(bodyEl.current, showMore, updateShowMore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBodyScroll = () => {
    if (bodyEl.current !== null) {
      shouldShowMoreShow(bodyEl.current, showMore, updateShowMore);
    }
  };

  return (
    <DrawerBodyStyled ref={bodyEl} onScroll={onBodyScroll} showMore={true}>
      <UserForm
        handlePostCustomer={handlePostCustomer}
        customerSchema={customerSchemaContext.customerSchema as JSONSchema}
        customerContactsSchema={customerSchemaContext.customerContactsSchema as JSONSchema}
        customerAddressSchema={customerSchemaContext.customerAddressSchema as JSONSchema}
      />
    </DrawerBodyStyled>
  );
};

const renderHeaderRow = (title: string, handleDrawerClose: () => void) => (
  <DrawerHeaderRow>
    <DrawerHeaderRowTitle>{title}</DrawerHeaderRowTitle>
    <DrawerCloseIcon icon="close" onClick={handleDrawerClose} />
  </DrawerHeaderRow>
);

const UserForm = ({
  handlePostCustomer,
  customerSchema,
  customerContactsSchema,
  customerAddressSchema,
}: {
  handlePostCustomer: THandlePostCustomer,
  customerSchema: JSONSchemaProp,
  customerContactsSchema: JSONSchemaProp,
  customerAddressSchema: JSONSchemaProp,
}) => {
  const { locale } = useAuth();

  const [form, updateForm] = useState<TCustomerAdd>({
    first_name: '',
    last_name: '',
    birthday: '',
    sex: '',
    pesel: '',
    preferred_communication_channel: [],
    preferred_communication_language: '',
    customer_manager_id: '',
    company_name: '',
    company_type: '',
    regon: '',
    pkdCode: '',
    pkdDescription: '',
    contact_persons: [],
    contacts: [cloneObject(PHONE_DEFAULT), cloneObject(EMAIL_DEFAULT)],
    addresses: [cloneObject(ADDRESS_DEFAULT)],
    type: 'individual',
    resident: true,
    countryCode: DEFAULT_RESIDENT_COUNTRY_CODE,
  });

  const [errors, updateErrors] = useState<TError[]>([]);
  const [isTouched, updateIsTouched] = useState(false);

  const [isSubmitting, updateIsSubmitting] = useState(false);
  const schemaProps = customerSchema.properties?.props && customerSchema.properties.props.properties;

  useEffect(() => {
    if (errors?.length) {
      scrollToFirstError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(errors)]);

  const handleChange = (key?: string, value?: TValue) => {
    if (key) {
      updateErrors([]);
      updateIsTouched(true);

      if (key === 'pkdCode' && typeof value === 'string') {
        value = value.split('.').join('');
      }

      const touchedForm = {
        ...form,
        [key]: value,
      };

      if (key === 'resident') {
        if (value) {
          touchedForm.countryCode = DEFAULT_RESIDENT_COUNTRY_CODE;
        } else {
          touchedForm.countryCode = undefined;
        }
      }

      updateForm(touchedForm);
    }
  };

  const handleChangeMultiple = (data: Partial<TCustomerAdd>) => {
    updateForm({
      ...form,
      ...data,
    });
  };

  const handleSaveData = () => {
    updateIsSubmitting(true);

    let errorsUI = validateCustomer({
      customerSchema,
      schemaProps,
      form,
      excludedFields: ['domain', 'customer_status', 'props']
    });

    const addressRequiredFields = [];
    const contactsRequiredFields: string[] = [];

    if (customerContactsSchema.required?.length) {
      contactsRequiredFields.push(...customerContactsSchema.required);
    }

    if (customerAddressSchema.properties && customerAddressSchema.properties.props && customerAddressSchema.properties.props.required?.length) {
      addressRequiredFields.push(...customerAddressSchema.properties.props.required);
    }

    let contacts: { subtype?: string, type: 'phone' | 'email', value: string }[] = [];
    if (form.contacts?.length) {
      contacts = form.contacts.map(item => ({
        type: item.type,
        value: item.value,
        subtype: item.subtype
      }));
    }

    let addresses: { props: { type: TAddressType[], zip_code?: string, country: string, city: string, street: string, house: string } }[] = [];
    if (form.addresses?.length) {
      addresses = form.addresses.map(item => ({
        props: {
          type: item.type,
          zip_code: item.zip_code,
          city: item.city,
          street: item.street,
          house: item.house,
          room: item.room || undefined,
          county: item.county,
          post: item.post,
          commune: item.commune,
          region: item.region,
          country: (form.type === 'individual' && form.resident === false) ? '' : DEFAULT_RESIDENT_COUNTRY,
        }
      }));
    }

    if (addresses?.length) {
      addressRequiredFields.forEach((item) => {
        const props = customerAddressSchema.properties?.props.properties;
        let pattern, regExpErrorMessage;

        if (['zip_code'].includes(item) && form.resident === true) {
          pattern = new RegExp(/^\d{2}-\d{3}$/);
          regExpErrorMessage = translate({ key: 'error.regexp_zip' });
        }

        const rules = setRules({
          props,
          item,
          pattern,
          regExpErrorMessage
        });

        const errors = validate({
          field: item,
          fieldName: props && props[item] ? translateTitleInScheme(props[item]) : undefined,
          value: form.addresses[0][item as keyof TAddress['props']],
          rules,
          parentField: 'addresses',
          listIndex: 0,
          uncommonError: ADDRESS_VALIDATION_FIELDS.includes(item)
        });

        if (errors?.length) {
          errorsUI = [
            ...errorsUI,
            ...errors
          ];
        }

      });
    }

    if (contacts?.length) {
      errorsUI = contactsValidation(contacts, contactsRequiredFields, errorsUI, customerContactsSchema.properties);
    }

    if (errorsUI?.length) {
      updateErrors(errorsUI);
      updateIsSubmitting(false);
      return;
    }

    let requestObject: TCustomer = {
      addresses: [],
      contacts: [],
      type: form.type,
      props: {
        customer_status: 'active',
      }
    };

    requestObject = addCustomerCommonFields(form, requestObject, addresses as TAddress[], contacts);

    handlePostCustomer(requestObject, (error) => {
      updateErrors(() => processAPIError(error, true) as TError[]);
      updateIsSubmitting(false);
    });

    updateIsTouched(false);
  };

  return (
    <DrawerDataForm>
      {isSubmitting ? <LoadingOverlay /> : null}
      {renderUserType(
        form.company_type === SOLE_TRADER_CUSTOMER_TYPE ? SOLE_TRADER_CUSTOMER_TYPE : form.type,
        customerSchema.properties?.type as JSONSchemaProp,
        (key, value) => updatePolicyHolderType(key, value, handleChangeMultiple),
        customerSchema.properties?.type?.enum as TCustomerType[],
        'type',
        true
      )}
      <ContentBodyRow>
        {(form.type === 'individual') ? (
          <Checkbox
            name="resident"
            label={translateTitleInScheme(schemaProps?.resident)}
            checked={form.resident}
            handleChange={handleChange}
          />
        ) : null}
        {form.type === 'individual' && form.resident === false ? (
          <Select
            name="countryCode"
            label={translateTitleInScheme(schemaProps?.countryCode)}
            value={form?.countryCode}
            options={getOptionsFromEnum(schemaProps?.countryCode).filter(item => item.key !== DEFAULT_RESIDENT_COUNTRY_CODE) || []}
            handleChange={handleChange}
            searchable={true}
            error={errors?.length ? getErrorsByFieldName('countryCode', errors).join('\n') : ''}
            required={!form.resident}
          />
        ) : null}
      </ContentBodyRow>
      <CustomerProfile
        form={form}
        schemaProps={schemaProps as JSONSchema}
        userLocale={locale}
        handleChange={handleChange}
        handleChangeMultiple={handleChangeMultiple}
        errors={errors}
        isClients
      />
      <PhonesAndEmails
        contacts={form.contacts}
        handleChangeForm={handleChange}
        errors={errors}
      />
      <AddressFormPolicyHolder item={form.addresses[0] as TAddress['props']} handleFormChange={handleChange} schemaProps={customerAddressSchema.properties?.props.properties as JSONSchema} errors={errors} isNotResident={form.type === 'individual' && form.resident === false} />
      {/*{renderCommunication(schemaProps as JSONSchema, form.preferred_communication_channel as TCommunicationChanel[], handleChange)}*/}
      {errors?.length ? renderErrors(filterUncommonErrors(errors)) : null}
      {(isTouched) ? renderControls('list', isTouched, handleSaveData) : null}
    </DrawerDataForm>
  );

};

export default DrawerAddUser;
