import React, { useEffect, useState } from 'react';
import { TDoc } from 'models';
import { useApp } from 'context/App';
import axios, { AxiosError } from 'axios';
import { cloneObject, errorNotification, preflightAPICall, translate } from 'utils';
import { EmailDocList, TEmailDoc } from '../../Pages/Clients/profile/body/GDPR/EmailDocs';
import DocsUploader, { PickerDefault } from 'app/Components/DocsUploader';
import apiConfig from 'config/api';
import DocumentsList from 'app/Components/DocumentsList';
import { Controls, DocumentsModal, DocNotice } from './styles';
import { Button } from '@insly/qmt-reactjs-ui-lib';
import { getFile } from 'utils/getFile';
import {
  ContentEditableEvent,
  Editor,
  EditorProvider,
  BtnBold,
  BtnItalic,
  Toolbar,
  BtnUndo,
  BtnRedo,
  Separator,
  BtnUnderline,
  BtnStrikeThrough,
  BtnNumberedList,
  BtnBulletList,
  BtnStyles
} from 'react-simple-wysiwyg';

export const EmailPreview = ({
  isEditEnabled,
  customerId,
  emailTemplate,
  typeId,
  sendEmailUrl,
  onSuccess,
  successMessage,
  docs
}: {
  isEditEnabled: boolean,
  customerId: string,
  emailTemplate: string,
  typeId: string,
  sendEmailUrl: string,
  onSuccess?: (data?: any) => void,
  successMessage?: string,
  docs?: TEmailDoc[],
}) => {
  const [documentsList, setDocumentList] = useState<TDoc[]>([]);
  const [emailDocs, setEmailDocs] = useState<TEmailDoc[]>([]);
  const [editedEmailTemplateBody, setEditedEmailTemplateBody] = useState('');
  const [isEditable, setIsEditable] = useState(false);

  const { showNotification, hideDialog, dialogProps } = useApp();

  useEffect(() => {
    if (dialogProps.show) {
      if (docs?.length) {
        setEmailDocs(docs);
      } else {
        setEmailDocs([]);
      }

      setDocumentList([]);
      setEditedEmailTemplateBody('');
      setIsEditable(false);
    }

  }, [dialogProps.show]);

  const handleAddDoc = (doc: TDoc) => {

    const touchedList = documentsList || [];
    touchedList.unshift(doc);
    setDocumentList({
      ...touchedList
    });
  };

  const handleUpdateTempDoc = (doc: TDoc) => {
    const touchedList: TDoc[] = documentsList || [];
    const docIndex = touchedList.findIndex(i => i.temp_id && i.temp_id === doc.temp_id);

    if (docIndex !== -1) {
      touchedList[docIndex] = doc;
      setDocumentList([
        ...cloneObject(touchedList)
      ]);
    }
  };

  const prepareDocsBeforeSend = () => {
    const docs: string[] = [];
    documentsList.forEach(doc => docs.push(doc.id as string));
    emailDocs.forEach(doc => docs.push(doc.id));

    if (docs.length) {
      return docs;
    }
  };

  const handleSendEmail = () => {
    preflightAPICall(() => {
      axios.post(sendEmailUrl, {
        attachments: prepareDocsBeforeSend(),
        email_content: isEditable ? setEmailBody(emailTemplate, editedEmailTemplateBody) : undefined,
      }).then(() => {
        onSuccess && onSuccess();
        successMessage && showNotification({
          autoHide: true,
          preset: 'success',
          message: successMessage
        });

        hideDialog();
      }).catch(error => errorNotification(error, showNotification, false, 'quote'));
    });
  };

  const handleRemoveDoc = (id: string) => {
    const touchedList: TDoc[] = documentsList || [];
    const docIndex = touchedList.findIndex(i => i.id === id);

    if (docIndex !== -1) {
      touchedList?.splice(docIndex, 1);
      setDocumentList(cloneObject(touchedList));
    }
  };

  const handleRemoveEmailDoc = (id: string) => {
    const touchedList: TEmailDoc[] = emailDocs || [];
    const docIndex = touchedList.findIndex(i => i.id === id);

    if (docIndex !== -1) {
      touchedList?.splice(docIndex, 1);
      setEmailDocs(cloneObject(touchedList));
    }
  };

  const handleDownloadEmailDoc = (data: TEmailDoc) => {
    getFile({
      url: `${apiConfig.DOCUMENT}/${data.ref_id}/${data.id}/file`,
      fileName: data.filename || 'Agreement',
      onError: (error: AxiosError) => errorNotification(error, showNotification)
    });
  };

  const handleEditorChange = (e: ContentEditableEvent) => {
    setEditedEmailTemplateBody(e.target.value);
  };

  const handleEditClick = () => {
    setEditedEmailTemplateBody(getEmailBody(emailTemplate));
    setIsEditable(true);
  };

  return (
    <DocumentsModal>
      {(emailTemplate && !isEditable) && (
        <>
          <div dangerouslySetInnerHTML={{ __html: emailTemplate }} />
          {isEditEnabled && (
            <Button onClick={handleEditClick}>{translate({ key: 'email_preview.button.edit' })}</Button>
          )}
        </>
      )}
      {isEditable && (
        <EditorProvider>
          <Editor value={editedEmailTemplateBody} onChange={handleEditorChange}>
            <Toolbar>
              <BtnUndo />
              <BtnRedo />
              <Separator />
              <BtnBold />
              <BtnItalic />
              <BtnUnderline />
              <BtnStrikeThrough />
              <Separator />
              <BtnNumberedList />
              <BtnBulletList />
              <Separator />
              <BtnStyles />
            </Toolbar>
          </Editor>
        </EditorProvider>
      )}

      <EmailDocList
        docs={emailDocs}
        handleRemoveDoc={handleRemoveEmailDoc}
        handleDownloadDoc={handleDownloadEmailDoc}
      />
      <DocsUploader
        handleAddDoc={handleAddDoc}
        handleUpdateTempDoc={handleUpdateTempDoc}
        refId={customerId}
        typeId={typeId}
        Picker={(props) => <PickerDefault {...props} />}
      />
      <DocNotice>{translate({ key: 'mail.attachments.limit' })}</DocNotice>
      {documentsList.length ? (
        <DocumentsList
          refId={customerId}
          dataStatus="success"
          data={documentsList as never[]}
          noResultsText={''}
          handleRemoveDoc={handleRemoveDoc}
          skipDeleteDialog={true}
        />
      ) : null}
      <Controls>
        <Button onClick={handleSendEmail}>
          {translate({ key: 'customer.gdpr.mail' })}
        </Button>
      </Controls>
    </DocumentsModal>
  );
};

const getEmailBody = (emailPreview: string) => {
  const doc = parseEmailPreview(emailPreview);
  return doc.getElementById('content')?.innerHTML || '';
};

const setEmailBody = (emailPreview: string, editedBody: string) => {
  const doc = parseEmailPreview(emailPreview);
  const contentDiv = doc.getElementById('content');

  if (contentDiv) {
    contentDiv.innerHTML = editedBody;
  }

  return doc.documentElement.outerHTML;
};

const parseEmailPreview = (emailPreview: string) => {
  const parser = new DOMParser();
  return parser.parseFromString(emailPreview, 'text/html');
};

export const getEmailTemplate = (getEmailURL: string, onSuccess: (response: any) => void, onError: (error: AxiosError) => void) => {
  preflightAPICall(() => {
    axios.get(`${getEmailURL}/email/preview`).then(onSuccess).catch(error => onError(error));
  });
};

export const setDialogProps = ({
  isEditEnabled,
  customerId,
  emailTemplate,
  typeId,
  sendEmailUrl,
  onSuccess,
  successMessage,
  onDecline,
  docs
} : {
  isEditEnabled: boolean,
  customerId: string,
  emailTemplate: string,
  typeId: string,
  sendEmailUrl: string,
  onSuccess?: (data?: any) => void,
  successMessage?: string,
  onDecline?: () => void,
  docs?: TEmailDoc[],
}) => ({
  title: translate({ key: 'email_preview.title' }),
  showCloseIcon: true,
  body: <EmailPreview
    isEditEnabled={isEditEnabled}
    customerId={customerId}
    emailTemplate={emailTemplate}
    typeId={typeId}
    sendEmailUrl={sendEmailUrl}
    onSuccess={onSuccess}
    successMessage={successMessage}
    docs={docs}
  />,
  hideIcon: true,
  hideControls: true,
  preventClose: true,
  onDecline: onDecline,
  style: {
    minWidth: '50vw',
    padding: '8px',
  }
});
