import axios from 'axios';
import { setCookie, prepareAuthData } from 'utils';

export const setXBrokerHeader = (broker: string | undefined, authenticationResult: string, getUserFromMasterService: (isBrokerSet?: boolean) => void) => {
  const requestObject = { ...JSON.parse(authenticationResult), broker };

  setCookie('authentication_result', JSON.stringify(prepareAuthData(requestObject)), 365, window._env_.COOKIE_DOMAIN);

  axios.defaults.headers.common['X-Broker'] = broker;

  getUserFromMasterService(!!broker);
};
