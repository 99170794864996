import { TAddressType, TCustomerContact } from 'models';

export const ADDRESS_DEFAULT = {
  city: '',
  country: '',
  house: '',
  room: '',
  street: '',
  type: ['legal'] as TAddressType[],
  zip_code: '',
  county: '',
  post: '',
  commune: '',
  region: '',
};

export const PHONE_DEFAULT: TCustomerContact = {
  id: '',
  type: 'phone',
  subtype: 'mobile',
  value: '',
  main: false,
  error: []
};

export const EMAIL_DEFAULT: TCustomerContact = {
  id: '',
  type: 'email',
  subtype: 'other',
  value: '',
  main: false,
  error: []
};
