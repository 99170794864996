import React from 'react';
import { useAuth } from 'context/Auth';
import { DatePicker } from '@insly/qmt-reactjs-ui-lib';
import { translate } from 'utils/translate';
import { dateToDbDate } from 'utils/dateTime';
import { JSONSchemaProp } from 'models';

interface IInputDate {
  name: string,
  prop: JSONSchemaProp,
  value: Date, required?: boolean,
  handleFormChange: (name: string, value: any, parentName?: string) => void,
  parentName?: string,
}

export const InputDate = ({ name, prop, value, required, handleFormChange, parentName }: IInputDate) => {
  const { locale } = useAuth();

  return (
    <DatePicker
      name={name}
      label={translate({ key: prop.title_translation_key, fallback: name })}
      value={value ? new Date(value) : null}
      required={required}
      userLocale={locale}
      disabled={prop.read_only}
      handleChange={(key, value) => handleFormChange(key as string, value ? dateToDbDate(value) : '', parentName)}
    />
  );
};
