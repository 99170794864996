import { TCustomer } from 'app/Pages/Clients/common';
import { SOLE_TRADER_CUSTOMER_TYPE } from 'config/consts';
import { CUSTOMER_TYPE_ICONS } from '../config/itemLists';

export const getCustomerTypeIconName = (userData: TCustomer) => {
  switch (userData.type) {
    case 'individual':
      return CUSTOMER_TYPE_ICONS['individual'];
    case 'company':
      if (userData.props?.company_type === SOLE_TRADER_CUSTOMER_TYPE) {
        return CUSTOMER_TYPE_ICONS[SOLE_TRADER_CUSTOMER_TYPE];
      } else {
        return CUSTOMER_TYPE_ICONS['company'];
      }
  }

  return '';
};
