import styled from 'styled-components';
import { PageStyled } from 'app/Pages/common/styles';
import UnderConstruction from 'app/Components/common/UnderConstruction';
import { Body } from 'app/Components/common/UnderConstruction/styles';

export const PageDashboard = styled(PageStyled)`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px;
`;

export const DashboardImage = styled.img`
  object-fit: contain;
  opacity: 50%;
`;

export const UnderConstructionStyled = styled(UnderConstruction)`
  padding: 20px 50px;
  margin: 0 auto;
  width: 100%;
  
  ${Body} {
    padding-top: 0;
  }
`;
