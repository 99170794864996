import { SvgIcon } from '@insly/qmt-reactjs-ui-lib';
import styled from 'styled-components';
import { TStandardSectionStep } from 'models';
import { Profile } from 'app/Pages/common/styles';
import { isIframe } from 'utils';
import { ContactsDataBlock, ContactsDataBlockTitle, SMALL_SCREEN_WIDTH } from 'styles/common';
import { ContentInner } from './body/styles';
import { ParticipantRoleTitle, ParticipantsSubheadline } from './body/Participants/styles';
import { DrawerHeaderRowTitle } from 'app/Components/common/Drawer/styles';
import { ControlsButton } from './body/partials/ControlsNextStep/styles';

export const PageQuote = styled(Profile)<{ step: TStandardSectionStep, isIssuing?: boolean }>`
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;
  left: 0;
  padding: 0;
};
  
  .content {
    flex: 1;
    overflow: auto;
  }
  
  .sidebar {
    grid-area: sidebar;
    position: sticky;
    height: ${isIframe() ? '100vh' : 'calc(100vh - 55px)'};
    width: 33.4vw;
    overflow-y: auto;
    overflow-x: hidden;
    
    @media (max-width: ${SMALL_SCREEN_WIDTH}) {
      width: 35vw;
      max-width: 35vw;
    }
  }
  
  .qmt-ui-input.owner__law-address-nr {
    position: relative;
    &:after {
      content: '/';
      position: absolute;
      right: 0;
      bottom: 18px;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.textSecondary};
    }
  }
  
  .qmt-ui-option {
    @media (max-width: ${SMALL_SCREEN_WIDTH}) {
      font-size: 12px;
      height: 40px;
      width: 100px;
    }
  }
  
`;

export const Content = styled.div`
  padding-top: 48px;
  ${ParticipantsSubheadline} {
    padding-top: 0;
  }
  ${ContentInner} {
    @media (max-width: ${SMALL_SCREEN_WIDTH}) {
      padding-left: 3vw;
      padding-right: 3vw;
      
      &.page-vehicle {
        label {
          height: 60px;
        }
        input,
        .qmt-ui-select__value {
          font-size: 12px;
        }
      }
      
      &.page-coverage {
        .qmt-ui-switch {
          height: 22px;
        }
      }
      
      &.page-participants {
        .qmt-ui-person-opt {
          min-height: 58px;
          padding-top: 8px;
        }
      }
      
      .qmt-ui-button--l {
        padding: 8px 40px;
        font-size: 14px;
      }
      
      .qmt-ui-input-option {
        height: auto;
      }
      
      .qmt-ui-select {
        height: auto;
        .qmt-ui-dropdown__picker {
          padding: 10px;
        }
      }
      
      label {
        padding: 10px;
        height: auto;
      }
      
      ${ControlsButton} {
        height: 36px;
        min-width: 160px;
      }
      
      ${ParticipantRoleTitle} {
        label {
          height: 28px;
        }
      }
      
      ${ContactsDataBlockTitle} {
        margin-bottom: 20px;
      }
      
      ${ContactsDataBlock} {
        margin-top: 20px;
        padding-top: 20px;
      }
      
      ${DrawerHeaderRowTitle} {
        font-size: 20px;
      }
      
    }
  }
`;

export const NavAdditionalItem = styled.div`
  display: flex;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const NavAdditionalItemTextStrong = styled.div`
  ${({ theme }) => theme.text.small};
  line-height: 18px;
`;

export const NavAdditionalItemText = styled.div`
  ${({ theme }) => theme.text.small};
  line-height: 18px;
  color: ${({ theme }) => theme.colors.textSecondary};
  &:first-child {
    margin-top: 6px;
  }
`;

export const NavAdditionalItemIcon = styled(SvgIcon)`
  width: 24px;
  height: 24px;
  margin-top: -4px;
  margin-right: 8px;
  flex-shrink: 0;
`;

export const ErrorsHolder = styled.div`
  margin: auto;
`;

export const DataItem = styled.span`
  ${({ theme }) => theme.text.tiny};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.textSecondary};
  &:not(:last-child) {
    &:after {
      content: '|';
      color: ${({ theme }) => theme.colors.strokeB};
      margin: 0 8px;
      font-size: 14px;
    }
  }
`;
