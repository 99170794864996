import { TQuoteType } from '../app/Pages/Quote/models';
import { INSURANCE_PRODUCT_ASSISTANCE } from '../config/consts';

export const serverDateTimeFormatter = (dateTime: Date, onlyDate?: boolean) => onlyDate ? dateTime.toISOString().split('T')[0] : dateTime.toISOString().split('.')[0]+'Z';

export const setDefaultQuoteStartDate = (type?: TQuoteType) => {
  let date = new Date();

  if (type === INSURANCE_PRODUCT_ASSISTANCE) {
    date.setUTCDate(date.getUTCDate() + 1);
    return date;
  }

  // add 15 minutes
  return addMinutes(date, 15);
};

export const ymdToDbDate = (year: number, month: number, date: number) => {

  if (!year || !month || !date) {
    return null;
  }

  return `${year}-${addZero(month)}-${addZero(date)}`;

};

export const dateToDbDate = (date: Date) => {

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  if (!year || !month || !day) {
    return null;
  }

  return `${year}-${addZero(month)}-${addZero(day)}`;

};

export const addZero = (num: number) => {
  if (num < 10) {
    return '0' + num;
  } else return num;
};

export const addMinutes = (date: Date, minutes: number) => new Date(date.getTime() + minutes * 60000);

export const addMinutesToTodayDate = (dateTime?: Date, minutes = 15) => {
  if (dateTime) {
    const now = new Date();

    if (isToday(now, dateTime)) {
      dateTime = addMinutes(now, minutes);
    }
  }

  return dateTime;
};

export const isToday = (date1: Date, date2: Date) => new Date(date1.getTime()).setHours(0, 0, 0, 0) === new Date(date2.getTime()).setHours(0, 0, 0, 0);
