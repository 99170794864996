import * as Sentry from '@sentry/react';

import type { ErrorEvent, EventHint } from '@sentry/types/build/types/event';
import { SENTRY_DSN, SENTRY_ENV } from 'config/api';

function beforeSend(event: ErrorEvent, hint: EventHint) {
  const error = hint.originalException;

  // @ts-ignore
  if (error.response) {
    event.extra = {
      ...event.extra,
      // @ts-ignore
      responseHeaders: error.response.headers,
    };
  }

  return event;
}

if (!!SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost'],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    // profilesSampleRate: 1.0,
    // Session Replay
    // replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    integrations: [
      Sentry.browserTracingIntegration(),
      // Sentry.browserProfilingIntegration(),
      // Sentry.replayIntegration(),
    ],
    beforeSend: beforeSend,
  });
}
