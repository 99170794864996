import styled from 'styled-components';
import { TStandardSectionStep } from 'models';
import background from 'assets/background.svg';

const stepOpacityTime = '0.5s';

export const PageStyled = styled.div<{ centerAligned?: boolean, isApproved?: boolean }>`
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;
  background: ${({ isApproved }) => isApproved && `url(${background}) 0 0/contain no-repeat, white`};
  
  ${({ centerAligned }) => centerAligned ? `
    align-items: center;
    justify-content: center;
  ` : null}

  .qmt-ui-table-row__column {
    display: flex;
    align-items: center;
  }
`;

export const Profile = styled.div<{ step?: TStandardSectionStep }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 520px;
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 60px 60px 20px 40px;
  z-index: ${({ step }) => step === 'profile' ? 'unset' : -1};
  opacity: ${({ step }) => step === 'profile' ? 1 : 0};
  transition: opacity ${stepOpacityTime} ease;
`;

export const ProfileInner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 714px;
`;
