import { JSONSchemaProp } from 'models';

const IDD_COMMENT_FIELDS = ['agricultural', 'car', 'company', 'inthefuture', 'life', 'property', 'travel'];

export const getIDDRequiredFields = (form: Record<string, any>, schemaProps: JSONSchemaProp) => {
  const requiredFields = schemaProps.required || [];

  if (form['policy_numbers']?.length) {
    requiredFields.push('policy_numbers');
  }

  if (form['comment']) {
    requiredFields.push('comment');
  }

  if (form['others_comment']) {
    requiredFields.push('others_comment');
  }

  Object.keys(form).forEach(key => {
    if (IDD_COMMENT_FIELDS.includes(key) && form[key].includes('other')) {
      requiredFields.push(`${key}_comment`);
    }
  });

  if (!form.refusal && !schemaProps.if?.properties?.refusal.const && schemaProps.then?.required) {
    requiredFields.push(...schemaProps.then?.required);
  }

  return requiredFields;
};
