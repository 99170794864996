import React, { useEffect, useState } from 'react';
import {
  DocumentsStyled
} from './styles';
import { cloneObject, preflightAPICall, processAPIError, translate } from 'utils';
import apiConfig, { DEFAULT_LOCALE } from 'config/api';
import axios from 'axios';
import { TDoc, TDocsListData } from 'models';
import Pagination from 'app/Components/common/Pagination';
import DocumentsList from 'app/Components/DocumentsList';
import Uploader from './components/Uploader';

interface IPageDocuments {
  refId: string,
}

const Documents = ({ refId } : IPageDocuments) => {
  const [dataStatus, updateDataStatus] = useState('loading');
  const [documentsList, updateList] = useState<TDocsListData>({});
  const [noResultsText, updateNoResultsText] = useState(translate({ key: 'common.no_results' }));

  useEffect(() => {
    callList({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callList = ({ page }: {page?: number}) => {
    updateDataStatus('loading');
    let query = '';
    if (page) {
      query = `?page=${page}`;
    }

    preflightAPICall(() => {
      axios.get(`${apiConfig.DOCUMENT}/${refId}${query}`).then(response => {
        if (response.status === 200) {
          const data = response.data;

          if (!data.results) {
            data.results = [];
          }
          dataLoading(data);
        }

      }).catch(error => {

        dataLoading({
          results: [],
        });

        updateNoResultsText(() => processAPIError(error) as string);

      });
    });
  };

  const dataLoading = (data: TDocsListData) => {
    updateList(data);
    updateDataStatus('success');
  };

  const handleListChangePage = (page: number) => {
    callList({ page });
  };

  const handleAddDoc = (doc: TDoc) => {
    const touchedList = documentsList.results || [];
    touchedList.unshift(doc);
    updateList({
      ...documentsList,
      results: cloneObject(touchedList)
    });
  };

  const handleUpdateTempDoc = (doc: TDoc) => {
    const touchedList: TDoc[] = documentsList.results || [];
    const docIndex = touchedList.findIndex(i => i.temp_id && i.temp_id === doc.temp_id);
    if (docIndex !== -1) {
      touchedList[docIndex] = doc;
      updateList({
        ...documentsList,
        results: cloneObject(touchedList)
      });
    }
  };

  const handleRemoveDoc = (id: string) => {
    const results = documentsList.results || [];
    const docIndex = results.findIndex(i => i.id === id);
    if (docIndex !== -1) {
      results?.splice(docIndex, 1);
      updateList({
        ...documentsList,
        results
      });
    }
  };

  return (
    <DocumentsStyled>
      <Uploader
        iddId={refId}
        handleUpdateTempDoc={handleUpdateTempDoc}
        handleAddDoc={handleAddDoc}
      />
      <DocumentsList
        refId={refId as string}
        dataStatus={dataStatus}
        data={documentsList.results as never[]}
        noResultsText={noResultsText}
        handleRemoveDoc={handleRemoveDoc}
        additionalColumns={[
          {
            name: 'type',
            onRender: (data) => renderDocumentType(data),
            styles: {
              flex: 1
            }
          },
          {
            name: 'created_at',
            onRender: renderCreatedAt,
            styles: {
              flex: 1
            }
          },
        ]}
      />
      {documentsList?.last_page && documentsList.last_page > 1 ? Pagination({ page: documentsList.page as number, lastPage: documentsList.last_page }, handleListChangePage) : null}
    </DocumentsStyled>
  );

};

const renderCreatedAt = (rowData: TDoc) => (
  <div>
    {rowData.created_at ? new Date(rowData.created_at).toLocaleString(DEFAULT_LOCALE) : ''}
  </div>
);

const renderDocumentType = (data: TDoc,) => {
  const docType = data.type?.tag;

  return (
    <div>
      {docType ? translate({ key: `policy.docs.${docType}.name` }) : ''}
    </div>
  );
};

export default React.memo(Documents);
