export const setYearOptions = (years: number = 50) => {
  const currentYear = new Date().getFullYear();
  const options = [];

  for (let i = currentYear; i > (currentYear - years); i-=1) {
    options.push({
      key: i,
      value: i
    });
  }

  return options;
};
