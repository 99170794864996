import { TError } from 'models';

export const combineErrors = (fieldsArray: string[], errors: TError[]) => {
  let combinedErrors: TError[] = [];

  fieldsArray.forEach(i => {
    const filteredErrors = errors.filter(e => e.field === i);
    combinedErrors = [
      ...combinedErrors,
      ...filteredErrors
    ];
  });

  return combinedErrors;
};
