import styled from 'styled-components';
import { DefaultIcon } from 'styles/common';

export const UnderConstructionStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.sidebarBg};
  border-radius: ${({ theme }) => theme.borderRadius};
  max-width: 1080px;
  padding: 72px 50px;
  margin: auto;
`;

export const Headline = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;

export const HeadlineIcon = styled(DefaultIcon)`
  width: 40px;
  height: 40px;
`;

export const Title = styled.h3`
  ${({ theme }) => theme.text.headerH3};
`;

export const Body = styled.article`
  ${({ theme }) => theme.text.normal};
  color: ${({ theme }) => theme.colors.textSecondary};
  padding-top: 20px;
  padding-left: 70px;
`;
