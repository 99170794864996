import { DrawerUserTypeWrapper } from '../styles';
import React, { useEffect, useRef, useState } from 'react';
import {
  JSONSchema,
  JSONSchemaProp,
  TError,
  TStandardSectionStep,
  TUserStatus,
  TCustomerType,
  TCustomerAdd
} from 'models';
import { Loader } from 'styles/common';
import {
  Addresses,
  renderContactsWrapper,
  TUserProfile,
  TCustomer,
} from 'app/Pages/Clients/common';
import { AxiosError } from 'axios';
import {
  CustomerProfile,
  renderUserType,
  validateCustomer
} from '../common';
import LoadingOverlay from 'helpers/LoadingOverlay';
import {
  processAPIError,
  filterUncommonErrors,
  scrollToFirstError,
  translateTitleInScheme,
  getOptionsFromEnum,
  getCustomerName,
  getErrorsByFieldName,
  translate,
} from 'utils';
import { renderErrors } from 'app/Components/common/Error';
import { shouldShowMoreShow, ShowMore } from 'app/Components/common/ShowMore';
import { DrawerBodyStyled, DrawerCloseIcon, DrawerDataForm, DrawerHeaderRow, DrawerHeaderRowTitle } from 'app/Components/common/Drawer/styles';
import ControlsRow from 'app/Components/common/DrawerHeaderControlsRow';
import DropdownSingle from 'app/Components/common/DropdownSingle';
import { renderControls, scrollElementToBottom, scrollElementToTop } from 'app/Components/common/Drawer';
import { prepareCustomerDataBeforeSend } from 'app/Components/common/Customer';
import { useAuth } from 'context/Auth';
import { Checkbox, Select } from '@insly/qmt-reactjs-ui-lib';
import { DEFAULT_RESIDENT_COUNTRY_CODE } from 'config/api';
import { ContentBodyRow } from 'app/Pages/Quote/Profile/body/styles';
import { SOLE_TRADER_CUSTOMER_TYPE } from 'config/consts';

interface IProps {
  customerSchema: JSONSchema,
  step: TStandardSectionStep,
  selectedUser: TCustomer | null,
  customerId?: string,
  dataStatus: string,
  errors: TError[],
  handleDrawerClose: () => void,
  updateStep: (step: string) => void,
  handleSaveCustomer: (user: TCustomer, onError?: (error: AxiosError) => void, onSuccess?: () => void) => void,
  handleDeleteCustomer: () => void,
}

const DrawerProfile = ({ customerSchema, selectedUser, handleDrawerClose, updateStep, step, handleSaveCustomer, handleDeleteCustomer, dataStatus, errors }: IProps) => {
  switch (dataStatus) {
    case 'error':
      return (
        <>
          {renderErrors(errors)}
        </>
      );
    case 'success':
      return (selectedUser ? renderDrawerInner(customerSchema, selectedUser, handleDrawerClose, updateStep, handleSaveCustomer, handleDeleteCustomer, step) : <div />);
    case 'loading':
    default:
      return <Loader />;
  }
};

const renderDrawerInner = (
  customerSchema: JSONSchemaProp,
  userData: TCustomer,
  handleDrawerClose: () => void,
  updateStep: (step: string) => void,
  handleSaveCustomer: (selectedUser: TCustomer, onError?: (errors: AxiosError) => void, onSuccess?: () => void) => void,
  handleDeleteCustomer: (customerId: string, onError: (error: AxiosError) => void) => void,
  step: TStandardSectionStep,
) => (
  <>
    {HeaderRow(userData, handleDrawerClose)}
    <ControlsRow
      step={step}
      updateStep={updateStep}
      onUserDelete={handleDeleteCustomer}
      userId={userData.id as string}
      // userAdditionalControl={
      //   <DrawerControlsRowControl onClick={() => {}}>
      //     <DrawerControlsRowControlLabel>{translate({ key: 'quote.create_new_quote' })}</DrawerControlsRowControlLabel>
      //     <DrawerControlsRowControlIcon margin="left" icon="add_new_calculation" />
      //   </DrawerControlsRowControl>
      // }
    />
    <DrawerBody
      step={step}
      userData={userData}
      customerSchema={customerSchema}
      handleSaveCustomer={handleSaveCustomer}
    />
  </>
);

const DrawerBody = ({
  step,
  userData,
  customerSchema,
  handleSaveCustomer,
}: {
  customerSchema: JSONSchemaProp,
  userData: TCustomer,
  handleSaveCustomer: (selectedUser: TCustomer, onError?: (errors: AxiosError) => void, onSuccess?: () => void) => void,
  step: TStandardSectionStep,
}) => {
  const bodyEl = useRef<HTMLDivElement>(null);
  const [showMore, updateShowMore] = useState(false);

  useEffect(() => {
    if (bodyEl.current !== null) {
      scrollElementToTop(bodyEl.current);
      shouldShowMoreShow(bodyEl.current, showMore, updateShowMore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.id]);

  const onSaveUser = (userForm: TCustomer['props'], onError?: (error: AxiosError) => void, onSuccess?: () => void) => {
    const requestObject: TCustomer = {
      ...userData,
      props: {
        customer_status: userForm.customer_status,
      }
    };

    requestObject.props = {
      ...requestObject.props,
      ...prepareCustomerDataBeforeSend(userData, userForm),
    };

    if (userForm.preferred_communication_channel?.length) {
      requestObject.props.preferred_communication_channel = userForm.preferred_communication_channel;
    }

    if (userForm.preferred_communication_language) {
      requestObject.props.preferred_communication_language = userForm.preferred_communication_language;
    }

    if (userForm.customer_manager_id) {
      requestObject.props.customer_manager_id = userForm.customer_manager_id;
    }

    handleSaveCustomer(requestObject, onError, onSuccess);
  };

  const onChangeStatus = (status: string, onError: (error: AxiosError) => void) => {
    const user = { ...userData.props };
    user.customer_status = status as TUserStatus;

    onSaveUser(user, onError);
  };

  const onBodyScroll = () => {
    if (bodyEl.current !== null) {
      shouldShowMoreShow(bodyEl.current, showMore, updateShowMore);
    }
  };

  return (
    <DrawerBodyStyled ref={bodyEl} onScroll={onBodyScroll} showMore={step === 'list'}>
      <DrawerUserTypeWrapper>
        {renderUserType(
          userData.props.company_type === SOLE_TRADER_CUSTOMER_TYPE ? SOLE_TRADER_CUSTOMER_TYPE : userData.type,
          customerSchema.properties?.type as JSONSchemaProp,
          undefined,
          customerSchema.properties?.type?.enum as TCustomerType[],
        )}
        {userData.props.customer_status && (
          <DropdownSingle
            schemaProp={customerSchema.properties?.props.properties?.customer_status as JSONSchemaProp}
            value={userData.props.customer_status}
            onChangeValue={onChangeStatus}
            title={translate({ key: 'clients.profile.status_dropdown.title' })} />
        )}
      </DrawerUserTypeWrapper>
      <UserForm customerSchema={customerSchema} step={step} userData={userData} onSaveUser={onSaveUser} />
      {(step === 'list' && userData.contacts?.length) ? renderContactsWrapper(userData) : null}
      {(step === 'list' && userData.addresses?.length) ? Addresses(userData.addresses, true) : null}
      {/*{step === 'list' ? renderCommunication(customerSchema.properties?.props.properties as JSONSchema, userData.props.preferred_communication_channel as TCommunicationChanel[]) : null}*/}
      {step === 'list' ? <ShowMore show={showMore} style={{
        position: 'fixed',
        right: '52px',
        bottom: showMore ? 0 : '-88px',
        left: '40px'
      }} onClick={() => scrollElementToBottom(bodyEl.current)} /> : null}
    </DrawerBodyStyled>
  );
};

const HeaderRow = (userData: TCustomer, handleDrawerClose: () => void) => {

  let name = getCustomerName(userData);

  return (
    <DrawerHeaderRow>
      <DrawerHeaderRowTitle>{name}</DrawerHeaderRowTitle>
      <DrawerCloseIcon icon="close" onClick={handleDrawerClose} />
    </DrawerHeaderRow>
  );
};

const UserForm = ({ customerSchema, step, userData, onSaveUser, readOnly }: {customerSchema: JSONSchemaProp, step: TStandardSectionStep, userData: TCustomer, onSaveUser: (user: TCustomer['props'], onError: (error: AxiosError) => void, onSuccess?: () => void) => void, readOnly?: boolean }) => {
  const [form, updateForm] = useState(() => prepareData(userData));

  const { locale } = useAuth();

  const [isSubmitting, updateIsSubmitting] = useState(false);
  const [errors, updateErrors] = useState<TError[]>([]);
  const [isTouched, updateIsTouched] = useState(false);
  const schemaProps = customerSchema.properties?.props && customerSchema.properties?.props.properties;

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(userData)]);

  useEffect(() => {
    if (errors?.length) {
      scrollToFirstError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(errors)]);

  const handleChange = (key?: string, value?: string | string[] | number | boolean) => {
    if (key) {
      updateErrors([]);
      updateIsTouched(true);

      if (key === 'pkdCode' && typeof value === 'string') {
        value = value.split('.').join('');
      }

      const touchedForm = {
        ...form,
        [key]: value,
      };

      if (key === 'resident') {
        if (value) {
          touchedForm.countryCode = DEFAULT_RESIDENT_COUNTRY_CODE;
        } else {
          touchedForm.countryCode = undefined;
        }
      }

      updateForm(touchedForm);
    }
  };

  const handleChangeMultiple = (data: Partial<TCustomerAdd>) => {
    updateForm({
      ...form,
      ...data,
    });
  };

  const resetForm = () => {
    updateForm(() => prepareData(userData));
    updateIsTouched(false);
    updateErrors([]);
  };

  const handleSaveData = () => {

    const errorsUI = validateCustomer({
      customerSchema,
      schemaProps,
      form,
      excludedFields: ['domain', 'customer_status', 'props', 'contacts', 'addresses']
    });

    if (errorsUI?.length) {
      updateErrors(errorsUI);
      updateIsSubmitting(false);
      return;
    }

    updateIsSubmitting(true);
    onSaveUser({ ...form }, (error) => {
      updateErrors(processAPIError(error, true) as TError[]);
      updateIsSubmitting(false);
    }, () => {
      updateIsTouched(false);
      updateIsSubmitting(false);
    });
  };

  return (
    <DrawerDataForm controlsShown={isTouched && step === 'profile'}>
      {isSubmitting ? <LoadingOverlay /> : null}
      <ContentBodyRow>
        {(form.type === 'individual') ? (
          <Checkbox
            name="resident"
            label={translateTitleInScheme(schemaProps?.resident)}
            checked={form.resident}
            handleChange={handleChange}
            disabled={readOnly}
          />
        ) : null}
        {form.type === 'individual' && form.resident === false ? (
          <Select
            name="countryCode"
            label={translateTitleInScheme(schemaProps?.countryCode)}
            value={form?.countryCode}
            options={getOptionsFromEnum(schemaProps?.countryCode).filter(item => item.key !== DEFAULT_RESIDENT_COUNTRY_CODE) || []}
            handleChange={handleChange}
            searchable={true}
            error={errors?.length ? getErrorsByFieldName('countryCode', errors).join('\n') : ''}
            required={!form.resident}
            disabled={readOnly}
          />
        ) : null}
      </ContentBodyRow>
      <CustomerProfile
        form={form as TUserProfile}
        schemaProps={schemaProps as JSONSchema}
        userLocale={locale}
        handleChange={handleChange}
        handleChangeMultiple={handleChangeMultiple}
        errors={errors}
      />

      {/*{(step === 'profile') ? (*/}
      {/*  <>*/}
      {/*    {renderCommunication(schemaProps as JSONSchema, form.preferred_communication_channel, handleChange)}*/}
      {/*    {renderLanguageAndAgentInfo(form as TUserProfile, schemaProps as JSONSchema, handleChange)}*/}
      {/*  </>*/}
      {/*) : null}*/}

      {errors?.length ? renderErrors(filterUncommonErrors(errors)) : null}

      {(isTouched || step === 'profile') ? (
        renderControls(step, isTouched, handleSaveData, true, resetForm)
      ) : null}
    </DrawerDataForm>
  );

};

const prepareData = (userData: TCustomer) => ({
  first_name: userData.props.first_name,
  last_name: userData.props.last_name,
  family_name: userData.props.family_name,
  birthday: userData.props.birthday,
  sex: userData.props.sex,
  pesel: userData.props.pesel,
  idCode: userData.props.idCode,
  preferred_communication_channel: userData.props.preferred_communication_channel || [],
  preferred_communication_language: userData.props.preferred_communication_language,
  customer_manager_id: userData.props.customer_manager_id,
  company_name: userData.props.company_name,
  company_type: userData.props.company_type,
  regon: userData.props.regon || '',
  nip: userData.props.nip || '',
  type: userData.type || '',
  customer_status: userData.props.customer_status || '',
  resident: userData.props.resident,
  countryCode: userData.props.countryCode,
  pkdCode: userData.props.pkdCode,
  pkdDescription: userData.props.pkdDescription,
});

export default DrawerProfile;
