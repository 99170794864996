import React from 'react';
import { TQuoteType } from '../app/Pages/Quote/models';
import { JSONSchemaProp } from '../models';

export const QuoteContext = React.createContext<{
  quoteType?: TQuoteType,
  travelProductsSchema: JSONSchemaProp,
  motorProductsSchema: JSONSchemaProp,
  borderProductsSchema: JSONSchemaProp,
  assistanceProductsSchema: JSONSchemaProp,

  motorInsurersSchema: JSONSchemaProp,
  travelInsurersSchema: JSONSchemaProp,
  borderInsurersSchema: JSONSchemaProp,
  assistanceInsurersSchema: JSONSchemaProp,

  travelProducts: string[],
  motorProducts: string[],
  borderProducts: string[],
  assistanceProducts: string[],

  motorInsurers: string[],
  travelInsurers: string[],
  borderInsurers: string[],
  assistanceInsurers: string[],
}>({
  quoteType: undefined,
  travelProductsSchema: {},
  motorProductsSchema: {},
  borderProductsSchema: {},
  assistanceProductsSchema: {},

  motorInsurersSchema: {},
  travelInsurersSchema: {},
  borderInsurersSchema: {},
  assistanceInsurersSchema: {},

  travelProducts: [],
  motorProducts: [],
  borderProducts: [],
  assistanceProducts: [],

  motorInsurers: [],
  travelInsurers: [],
  borderInsurers: [],
  assistanceInsurers: [],
});
