import styled from 'styled-components';
import { ControlsWrapper } from 'utils/JSONSchemaFormBuilder/styles';
import { Button, Input } from '@insly/qmt-reactjs-ui-lib';
import { SelectSource } from 'app/Pages/Quote/List/styles';
import { ButtonTransparent, wiggle } from 'app/Pages/Quote/Profile/body/Participants/styles';
import { GDPRControlsIcon, GDPRTitle } from 'app/Pages/Clients/profile/body/GDPR/styles';
import { SMALL_SCREEN_WIDTH } from 'styles/common';
import { TableStyled } from 'app/Pages/common/List/styles';

export const IDDWrapper = styled.div`
  .document_list {
    min-height: auto;
  }
`;

export const IDDFormWrapper = styled.div<{ isDisabled?: boolean }>`
  padding: 6px;
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.colors.strokeA};
  border-radius: ${({ theme }) => theme.borderRadius};
  
  ${ControlsWrapper} {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  
  pointer-events: ${({ isDisabled }) => isDisabled && 'none'};
  opacity: ${({ isDisabled }) => isDisabled && '0.4'};
`;

export const ExistedIDD = styled.div`
  ${({ theme }) => theme.text.normal};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ControlsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: end;
  align-items: center;
`;

export const ControlsRowWrapper = styled.div`
  margin: 16px 0;
`;

export const IddInput = styled(Input)<{ halfWidth?: boolean }>`
  margin: 10px 0;
  width: ${({ halfWidth }) => halfWidth ? '50%' : null };
`;

export const IDDNumbersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
`;

export const IDDNumbersItemWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.strokeA};
  padding-right: 6px;
  border-radius: ${({ theme }) => theme.borderRadius};
  display: flex;
  gap: 6px;
  align-items: center;
  label {
    border: 0;
  }
  min-width: 32%;
`;

export const IDDNumbersWrapperBody = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export const SelectStatus = styled(SelectSource)`
  max-width: 100px;
  
  .qmt-ui-select__value {
    text-overflow: ellipsis;
    width: 70px;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const AddPolicyNumberWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 16px;
`;

export const AddPolicyNumberInput = styled(Input)`
  padding-top: 10px;
  padding-bottom: 10px;
  height: auto;
  border: 0;
  justify-content: center;
  width: 100%;
`;

export const IDDButton = styled(ButtonTransparent)<{ highlighted?: boolean }>`
  animation-name: ${({ highlighted }) => highlighted && wiggle};
  animation-iteration-count: 3;
`;

export const IDDControlsButton = styled(Button)`
  &.qmt-ui-button--s {
    padding: 6px 16px;
  }
`;

export const IDDControlsIcon = styled(GDPRControlsIcon)`
  
`;

export const IDDTitle = styled(GDPRTitle)`
  padding: 36px 0;
  ${({ theme }) => theme.text.h3};
  @media (max-width: ${SMALL_SCREEN_WIDTH}) {
    font-size: 95%;
    padding: 18px 0;
  }
`;

export const IDDTable = styled(TableStyled)`
  overflow: visible;
  flex: unset;

  .qmt-ui-table__head {
    border-top: 0;
    border-bottom: 0;
    padding: 0;
  }

  .qmt-ui-table__body {
    overflow: visible;
  }
`;

export const IDDNumberColData = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 16px;
`;

export const IDDSubTitle = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.text.normal};
`;
