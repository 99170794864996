import { DocsUploaderStyled } from './styles';
import { TDoc } from 'models';
import React from 'react';
import { UploaderInput } from 'styles/common';
import { translate } from 'utils';
import { ControlsButton } from 'app/Pages/Quote/Profile/body/partials/ControlsNextStep/styles';
import { SIGNED_ANSWERS_DOC } from 'app/Pages/IDD/consts';
import DocsUploader, { TPicker } from 'app/Components/DocsUploader';

interface IProps {
  iddId: string,
  handleAddDoc: (item: TDoc) => void,
  handleUpdateTempDoc: (doc: TDoc) => void,
}

const DOCUMENT_TYPES = [
  SIGNED_ANSWERS_DOC
];

const Uploader = ({ iddId, handleAddDoc, handleUpdateTempDoc }: IProps) => (
  <DocsUploaderStyled>
    {DOCUMENT_TYPES.map(docType => (
      <DocsUploader
        typeId={docType.id}
        refId={iddId}
        handleAddDoc={handleAddDoc}
        handleUpdateTempDoc={handleUpdateTempDoc}
        Picker={(props) => <Picker {...props} docType={docType.tag} />}
      />
    ))}
  </DocsUploaderStyled>
);

const Picker = ({
  uploadInput,
  onUpload,
  docType
} : TPicker & {
  docType: string,
}) => (
  <ControlsButton
    preset="secondary"
    onClick={() => {
      if (uploadInput.current !== null) {
        uploadInput.current.click();
      }
    }}
  >
    <>
      <UploaderInput
        type="file"
        ref={uploadInput}
        name="file"
        multiple={false}
        onChange={() => {
          if (uploadInput.current !== null && uploadInput.current.files) {
            onUpload(uploadInput.current.files);
          }
        }}
      />
      {translate({ key: `idd.docs.${docType}.upload` })}
    </>
  </ControlsButton>
);


export default Uploader;
