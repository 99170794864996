import jwt_decode from 'jwt-decode';

export const prepareAuthData = (authData: Record<string, any>) => {
  const decodedToken: Record<string, unknown> = jwt_decode(authData.access_token);
  const expTime = decodedToken.exp as number;
  const expIn = authData.expires_in as number;

  if (expTime > 0) {
    authData.user_time_diff = new Date().getTime() - ((expTime - expIn) * 1000);
  }

  delete authData.id_token;

  return authData;
};
