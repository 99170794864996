import { preflightAPICall } from './preflightAPICall';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import apiConfig from '../config/api';
import { TZipCodeResult } from '../models';

export const zipCodeMatcher = (value: string, prevValue: string = '') => {
  let processedValue = value;

  if (value.match(/^\d{2}$/) && prevValue.length < 3) {
    processedValue = `${value}-`;
  } else if (value.match(/^\d{3,}$/)) {
    const valueArr = value.split('');
    valueArr.splice(2, 0, '-');
    value = valueArr.join('');

    processedValue = value;
  } else if (value.length > 6) {
    return prevValue;
  }

  return processedValue;
};

export const setStreets = (zipCodeResults: {city: string, street: string}[], updateStreets: (streets: { key: string, value: string }[]) => void) => updateStreets(zipCodeResults.map(i => ({ key: i.street, value: i.street })));
export const setCities = (
  zipCodeResults: TZipCodeResult[],
  updateCities: (cities: { key: string, value: string }[]) => void,
  updateStreets: (streets: {key: string, value: string}[]) => void,
  setAddressFromZip: (zipCodeResults: TZipCodeResult[]) => void,
) => {
  const filteredCities = zipCodeResults.map(item => item.city)
    .filter((value, index, self) => self.indexOf(value) === index);
  if (filteredCities.length === 1) {

    const streets = zipCodeResults.reduce((streets: {key: string, value: string}[], item) => {
      if (item.street && streets.findIndex(i => i?.key === item.street) === -1) {
        streets.push({
          key: item.street,
          value: item.street
        });
      }
      return streets;
    }, []);
    if (streets.length === 1) {
      updateStreets([]);
      zipCodeResults[0].street = streets[0].value;
      setAddressFromZip(zipCodeResults);
    } else {
      updateStreets(streets);
      setAddressFromZip(zipCodeResults);
    }
    updateCities([]);

  } else {
    updateCities(filteredCities.map(city => ({ key: city, value: city })));
  }
};

export const fetchCityData = (
  zipCode: string,
  city: string,
  cancelTokenSource: CancelTokenSource,
  updateZipCodeList: (results: TZipCodeResult[]) => void,
  onError?: (error: AxiosError) => void,
) => {
  preflightAPICall(() => {
    axios.get(`${apiConfig.ZIP_CODE_SERVICE}/search?code=${zipCode}&city=${city}`, {
      cancelToken: cancelTokenSource ? cancelTokenSource.token : undefined,
    }).then(response => {

      if (response.data?.data && response.data?.data.length) {
        const results = response.data?.data;

        updateZipCodeList(results);
      } else {
        updateZipCodeList([]);
      }

    }).catch(error => {
      if (onError) {
        onError(error);
      } else {
        console.error(error);
      }
    });
  });
};

export const fetchZipCodeData = (
  zipCode: string,
  updateZipCodeResults: (results: TZipCodeResult[]) => void,
  updateCities: (cities: {key: string, value: string}[]) => void,
  setAddressFromZip: (zipCodeResults: TZipCodeResult[]) => void,
  updateStreets: (streets: {key: string, value: string}[]) => void,
  onError: (error: AxiosError) => void,
) => {
  preflightAPICall(() => {
    axios.get(`${apiConfig.ZIP_CODE_SERVICE}/search?code=${zipCode}`).then(response => {
      if (response.data?.data && response.data.data?.length) {
        updateZipCodeResults(response.data.data);

        if (response.data.data.length > 1) {
          setCities(response.data.data, updateCities, updateStreets, setAddressFromZip);
        } else {
          setAddressFromZip(response.data.data);
          updateStreets([]);
          updateCities([]);
        }
      } else {
        updateZipCodeResults([]);
      }
    }).catch(error => {
      if (onError) {
        onError(error);
      } else {
        console.error(error);
      }
    });
  });
};

export const filterStreets = (zipCodeResults: TZipCodeResult[], city: string) => zipCodeResults.reduce((streets: TZipCodeResult[], item) => {
  if (item.street && item.city === city && streets.findIndex(i => i.street === item.street) === -1) {
    streets.push(item);
  }
  return streets;
}, []);

export const filterStreetsWithoutCity = (zipCodeResults: TZipCodeResult[]) => zipCodeResults.reduce((streets: TZipCodeResult[], item) => {
  if (item.street && streets.findIndex(i => i.street === item.street) === -1) {
    streets.push(item);
  }
  return streets;
}, []);
