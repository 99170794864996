import React, { useEffect } from 'react';
import { TCustomer } from '../common';
import DrawerAddUser, { THandlePostCustomer } from './body/add';
import DrawerProfile from './body/profile';
import { JSONSchema, TError, TStandardSectionStep } from 'models';
import { Loader } from 'styles/common';
import { useScheme } from 'context/Schema';
import { AxiosError } from 'axios';
import { renderErrors } from 'app/Components/common/Error';
import { DrawerStyled } from 'app/Components/common/Drawer/styles';

interface IProps {
  type: string,
  scheme?: JSONSchema,
  errors: TError[],
  dataStatus: string,
  step: TStandardSectionStep,
  selectedUser: TCustomer | null,
  customerId?: string,
  handleDrawerClose: () => void,
  updateStep: (step: string) => void,
  handleSaveCustomer: (selectedUser: TCustomer, onError?: (errors: AxiosError) => void) => void,
  handleDeleteCustomer: () => void,
  handlePostCustomer: THandlePostCustomer,
}

const Drawer = ({
  type,
  selectedUser,
  customerId,
  handleDrawerClose,
  updateStep,
  step,
  errors,
  dataStatus,
  handleSaveCustomer,
  handleDeleteCustomer,
  handlePostCustomer,
}: IProps) => (
  <DrawerStyled show={!!selectedUser || !!customerId || type === 'add_user'} dir="right" step={step}>
    {DrawerBody({
      type,
      selectedUser,
      customerId,
      handleDrawerClose,
      updateStep,
      step,
      errors,
      dataStatus,
      handleSaveCustomer,
      handleDeleteCustomer,
      handlePostCustomer,
    })}
  </DrawerStyled>
);

const DrawerBody = ({
  type,
  selectedUser,
  customerId,
  handleDrawerClose,
  updateStep,
  step,
  errors,
  dataStatus,
  handleSaveCustomer,
  handleDeleteCustomer,
  handlePostCustomer,
}: IProps) => {

  let schema = useScheme();

  useEffect(() => {
    if (schema.getCustomerSchemas && (!schema.customerSchemaNetworkStatus || schema.customerSchemaNetworkStatus === 'failed')) {
      schema.getCustomerSchemas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (schema.customerSchemaErrors?.length) {
    return (
      <>
        {renderErrors(schema.customerSchemaErrors)}
      </>
    );
  } else if (!schema.customerSchemaNetworkStatus || schema.customerSchemaNetworkStatus === 'loading') {
    return <Loader />;
  } else {
    switch (type) {
      case 'add_user':
        return (
          <DrawerAddUser
            customerSchemaContext={schema}
            handleDrawerClose={handleDrawerClose}
            handlePostCustomer={handlePostCustomer}
          />
        );
      case 'profile':
      default:
        return (
          <DrawerProfile
            customerSchema={schema.customerSchema}
            selectedUser={selectedUser}
            customerId={customerId}
            handleDrawerClose={handleDrawerClose}
            updateStep={updateStep}
            step={step}
            handleSaveCustomer={handleSaveCustomer}
            handleDeleteCustomer={handleDeleteCustomer}
            errors={errors}
            dataStatus={dataStatus}
          />
        );
    }
  }
};

export default Drawer;
