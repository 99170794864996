import React from 'react';
import { TCustomer, TFetchStatus, TStandardSectionStep } from 'models';
import {
  ListInner,
  Title,
  IconAddUser,
  PrimaryNumber,
  ColData,
  Col,
} from 'app/Pages/common/List/styles';
import { cloneObject, getCustomerTypeIconName, isIframe, preflightAPICall, processAPIError, translate } from 'utils';
import Pagination from 'app/Components/common/Pagination';
import { IPageIDD, LIST_IDD_NAME, TIDD } from '../index';
import { DEFAULT_LOCALE } from 'config/api';
import {
  ButtonsWrapper,
  ColStatus,
  ColStatusData,
  IDDList,
  IDDListButton,
  IDDListTitleWrapper,
  TableIDD
} from './styles';
import { SelectSource } from 'app/Pages/Quote/List/styles';
import { renderDateFilter, renderMultiDateWrapper, CustomerNameWithTooltip } from 'app/Pages/common/List';
import { TColumn } from '@insly/qmt-reactjs-ui-lib/dist/components/TableRow';
import { NavLink } from 'react-router-dom';
import { LIST_FILTERS } from 'config/consts';
import { ListFiltersCustom } from 'app/Components/common/ListFiltersCustom';
import { API_IDD_QUESTIONNAIRE_URL, StatusToggler } from 'app/Components/IDD';
import axios from 'axios';

interface IProps {
  step: TStandardSectionStep,
  title: string,
  dataStatus: TFetchStatus,
  errorText: string,
  itemList: IPageIDD['listData'],
  selectedIDD?: TIDD,
  searchQuery?: string,
  handleListChangePage: (page: number) => void,
  handleSearchChange?: (value: string) => void,
  handleAddIDDClick: () => void,
  hideCustomerInfo?: boolean,
  handleCSVDownload?: () => void,
  filters?: Record<string, any>,
  updateFilter?: (name?: string, value?: string) => void,
  handleScanIDDClick?: () => void,
  userFilters?: string[],
  updateSelectedIDD?: (data: TIDD) => void,
  updateDataFetchStatus: (dataStatus: TFetchStatus) => void,
  updateIddErrorText: (errorText: () => string) => void,
  updateIddList: (iddList: IPageIDD['listData']) => void,
}

const IDD_STATUSES = [1, 2, 3, 4];

export const PageIDDList = ({
  step,
  title,
  dataStatus,
  errorText,
  itemList,
  filters,
  updateFilter,
  selectedIDD,
  handleListChangePage,
  handleSearchChange,
  handleAddIDDClick,
  hideCustomerInfo,
  handleCSVDownload,
  userFilters,
  handleScanIDDClick,
  updateSelectedIDD,
  updateDataFetchStatus,
  updateIddErrorText,
  updateIddList
}: IProps) => {
  const updateStatus = (status: number, id: string) => {
    updateDataFetchStatus('loading');

    preflightAPICall(() => {
      axios.patch(`${API_IDD_QUESTIONNAIRE_URL}/${id}`, {
        status
      }).then(response => {
        updateDataFetchStatus('success');

        if (updateSelectedIDD) {
          updateSelectedIDD(response.data);
        }

        const _iddList: IPageIDD['listData'] = cloneObject(itemList);

        if (_iddList.results?.length) {
          const resultIndex = _iddList.results?.findIndex(result => result.id === id);

          if (resultIndex > -1) {
            _iddList.results[resultIndex].status = response.data.status;
            updateIddList(_iddList);
          }
        }
      }).catch(error => {
        console.error(error);
        updateIddErrorText(() => processAPIError(error) as string);
        updateDataFetchStatus('failed');
      });
    });
  };

  return (
    <IDDList step={step} customerPage={hideCustomerInfo}>
      <ListInner>
        <IDDListTitleWrapper customerPage={hideCustomerInfo}>
          <Title>{title}</Title>
          <ButtonsWrapper>
            <IDDListButton preset="secondary" onClick={handleAddIDDClick}>
              <>
                {translate({ key: 'idd.create_new_idd' })}&nbsp;
                <IconAddUser icon="add_idd" />
              </>
            </IDDListButton>
            {handleScanIDDClick && (
              <IDDListButton preset="secondary" onClick={handleScanIDDClick}>
                <>
                  {translate({ key: 'idd.scan_idd' })}&nbsp;
                  <IconAddUser icon="folder" />
                </>
              </IDDListButton>
            )}
          </ButtonsWrapper>
        </IDDListTitleWrapper>

        <TableIDD
          dataStatus={dataStatus as string}
          data={itemList.results ? itemList.results as never[] : undefined}
          noResultsText={errorText || translate({ key: 'common.no_results' })}
          selectedId={selectedIDD ? selectedIDD.id : undefined}
          handleSearchChange={handleSearchChange}
          columns={setColumns(updateStatus, hideCustomerInfo)}
          controlsRight={handleCSVDownload ? prepareControls(filters, updateFilter, handleCSVDownload, userFilters) : undefined}
          showHeadline={true}
        />
        {itemList?.total_pages && itemList.total_pages > 1 ? Pagination({ page: itemList.current_page as number, lastPage: itemList.total_pages, resultsNumber: itemList.results?.length || 0, totalResults: itemList.total_results }, handleListChangePage) : null}
      </ListInner>
    </IDDList>
  );
};

const prepareControls = (filters?: Record<string, string>, updateFilter?: (name?: string, value?: string) => void, handleCSVDownload?: () => void, userFilters?: string[]) => {
  let allowedFilters = [...LIST_FILTERS[LIST_IDD_NAME]];

  if (userFilters) {
    allowedFilters = allowedFilters.filter(filter => userFilters.includes(filter));
  }

  let controls = [];

  if (allowedFilters.includes('status')) {
    controls.push(<SelectSource
      key="status"
      name="status"
      value={filters?.status}
      placeholder={translate({ key: 'idd.status.placeholder' })}
      options={prepareStatusOptions()}
      handleChange={(name, value) => updateFilter ? updateFilter(name as string, value as string) : undefined}
    />);
  }

  if (allowedFilters.includes('createdFrom') && allowedFilters.includes('createdTo')) {
    controls.push(
      renderMultiDateWrapper('createdFrom', 'createdTo', 'policy.filter.created_at.label', 'policy.filter.created_at.from', 'policy.filter.created_at.to', filters as Record<string, string>, updateFilter as (name?: string, value?: string) => void)
    );
  } else if (allowedFilters.includes('createdFrom')) {
    controls.push(
      renderDateFilter({
        name: 'createdFrom',
        value: filters?.createdFrom,
        translationKey: 'policy.filter.created_at.from',
        updateFilter: updateFilter as (name?: string, value?: string) => void,
        translationKeyLabel: 'policy.filter.created_at.label'
      })
    );
  } else if (allowedFilters.includes('createdTo')) {
    controls.push(
      renderDateFilter({
        name: 'createdTo',
        value: filters?.createdTo,
        translationKey: 'policy.filter.created_at.to',
        updateFilter: updateFilter as (name?: string, value?: string) => void,
        translationKeyLabel: 'policy.filter.created_at.label'
      })
    );
  }

  if (handleCSVDownload) {
    controls.push(<DownloadCSV handleClick={handleCSVDownload} />);
  }

  controls.push(<ListFiltersCustom listType={LIST_IDD_NAME} />);

  return controls;

};

const prepareStatusOptions = () => {
  let options: {key: string| number, value: string}[] = [{
    key: '',
    value: translate({ key: 'idd.status.placeholder' })
  }];
  IDD_STATUSES.forEach((status) => {
    options.push({
      key: status,
      value: translate({ key: `idd.status.${status}` })
    });
  });

  return options;
} ;

const DownloadCSV = ({ handleClick }: { handleClick?: () => void }) => (
  <IDDListButton onClick={handleClick}>{translate({ key: 'idd.button.csv' })}</IDDListButton>
);

const setColumns = (updateStatus: (status: number, id: string) => void, hideCustomerInfo?: boolean) => {
  const columns: TColumn[] = [
    {
      title: translate({ key: 'idd.number' }),
      onRender: (data: TIDD) => renderIDDNumber(data),
      name: 'number',
      styles: {
        flex: 1,
      }
    },
    {
      title: translate({ key: 'idd.quote_number' }),
      name: 'quote_number',
      onRender: renderQuoteNumber,
      styles: {
        flex: 1,
      }
    },
    {
      title: translate({ key: 'idd.policy_number' }),
      name: 'policy_number',
      onRender: renderColPolicyNumber,
      styles: {
        flex: 1,
      }
    },
    {
      title: translate({ key: 'common.created_at' }),
      name: 'created_at',
      onRender: renderColCreatedDate,
      styles: {
        flex: 1,
      }
    },
    {
      title: translate({ key: 'idd.status' }),
      name: 'status',
      onRender: (data: TIDD) => renderColStatus(data, updateStatus),
      styles: {
        flex: 1,
      }
    },
    {
      title: translate({ key: 'idd.created_by.name' }),
      name: 'created_by',
      onRender: renderColCreatedBy,
      styles: {
        flex: 1,
      }
    },
  ];

  if (!hideCustomerInfo) {
    columns.splice(3, 0, ...[{
      title: translate({ key: 'customer.name' }),
      name: 'name',
      onRender: (data: TIDD) => (
        <CustomerNameWithTooltip
          id={data.customer_data.id}
          icon={getCustomerTypeIconName(data.customer as TCustomer)}
          name={data.customer_data.name}
        />
      ),
      styles: {
        flex: 1,
        paddingRight: '10px',
        overflow: 'hidden'
      }
    }, {
      title: translate({ key: 'idd.customer.pesel-regon' }),
      name: 'pesel',
      onRender: (data: TIDD) => renderCustomerID(data),
      styles: {
        flex: 1,
      }
    }]);
  }

  return columns;
};

const renderColCreatedBy = (data: TIDD) => (
  <ColData>{data.created_by_identity?.name}</ColData>
);

const renderCustomerID = (data: TIDD) => (
  <ColData as={!isIframe() ? NavLink : undefined} to={!isIframe() ? `/clients/profile/${data.customer_data.id}` : ''}>{data.customer_data.pesel || data.customer_data.idcode || data.customer_data.regon}</ColData>
);

const renderColCreatedDate = (data: TIDD) => (
  <ColData>
    {data.created_at ? new Date(data.created_at).toLocaleString(DEFAULT_LOCALE) : ''}
  </ColData>
);

const renderColPolicyNumber = (data: TIDD) => (
  <Col>
    <ColData>{data.policy_numbers?.length ? data.policy_numbers.join(', ') : ''}</ColData>
  </Col>
);

const renderQuoteNumber = (data: TIDD) => (
  <ColData>{data.quote ? data.quote.number : ''}</ColData>

);

const renderIDDNumber = (data: TIDD) => (
  <PrimaryNumber to={`/idd/profile/${data.id}`}>{data.number}</PrimaryNumber>
);

const renderColStatus = (data: TIDD, updateStatus: (status: number, id: string) => void) => (
  <ColStatusData>
    <ColStatus status={data.status} />
    <ColData>
      <StatusToggler updateStatus={(status) => updateStatus(status, data.id)} status={data.status} />
    </ColData>
  </ColStatusData>
);

export default PageIDDList;
