import fileTypeChecker from 'file-type-checker';
import { translate } from './translate';
import { decorateBytes } from './decorateBytes';
import { ALLOWED_FILE_TYPES_EXOTIC, ALLOWED_FILE_TYPES_LIB_CAN_CHECK, MAX_FILE_SIZE } from '../config/consts';

interface IProps {
  file: File,
  maxFileSize?: number,
  allowedFileTypes?: string[],
  allowedExoticFileTypes?: string[],
  onSuccess?: (data?: any) => void,
  onError?: (message: string) => void,
}

export const checkFileRestrictions = ({
  file,
  maxFileSize,
  allowedFileTypes,
  allowedExoticFileTypes,
  onSuccess,
  onError
}: IProps) => {
  try {
    const reader = new FileReader();
    const types = allowedFileTypes || ALLOWED_FILE_TYPES_LIB_CAN_CHECK;

    // When the file is loaded, validate its type
    reader.onload = () => {
      const isValidFileType = fileTypeChecker.validateFileType(reader.result as ArrayBuffer, types, { chunkSize: 30000 }) || checkExoticFileExt(file, allowedExoticFileTypes);
      const isValidFileSize = checkFileSize(file, maxFileSize);

      if (isValidFileType && isValidFileSize) {
        onSuccess && onSuccess();
      } else {
        if (!isValidFileSize) {
          onError && onError(translate({ key: 'error.uploader.invalid_file_size', replace: [decorateBytes(MAX_FILE_SIZE)] }));
          return;
        }

        if (!isValidFileType) {
          onError && onError(translate({ key: 'error.uploader.invalid_file_type', replace: [ALLOWED_FILE_TYPES_EXOTIC.join()] }));
          return;
        }

        onError && onError(translate({ key: 'common.error' }));
        return;
      }

    };

    // Use the FileReader API to read the file as an ArrayBuffer
    reader.readAsArrayBuffer(file);
  } catch (err) {
    console.error(err);
  }

};


const checkFileSize = (file: File, maxFileSize?: number) => {
  const fileSize = file.size;
  return fileSize < (maxFileSize || MAX_FILE_SIZE);
};

const checkExoticFileExt = (file: File, allowedExoticFileTypes?: string[]) => {
  const allowedFileTypes = allowedExoticFileTypes || ALLOWED_FILE_TYPES_EXOTIC;
  const fileExt = getFileExtension(file.name)?.toLowerCase();

  if (fileExt) {
    return allowedFileTypes.includes(fileExt);
  }

  return false;

};

const getFileExtension = (filename: string) => filename.split('.').pop();
