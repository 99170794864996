import { getCookie } from 'utils';

export const getAPIBroker = () => {
  const authenticationResult = getCookie('authentication_result');

  if (authenticationResult) {
    const requestObject = JSON.parse(authenticationResult);

    if (requestObject.broker) {
      return requestObject.broker;
    }
  }
};
