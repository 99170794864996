import { JSONSchema, JSONSchemaProp } from 'models';
import { ContentBodyRow } from 'app/Pages/Quote/Profile/body/styles';
import { ArrayItemContent, ArrayItemControls, ArrayItemWrapper, ControlsWrapper, RequiredMark, SectionHeadline } from './styles';
import { translate, getOptionsFromEnum, translateTitleInScheme, showIf, validateShowIfField, sortSchemaPropertiesByPriority } from 'utils';
import { Button, Checkbox, FormGroup, Input, Select } from '@insly/qmt-reactjs-ui-lib';
import { DefaultIcon } from 'styles/common';
import { DOCUMENTS_STATEMENTS_NAME, INSPECTION_FIELD_NAME, INSURED_PERSONS_FIELD_NAME, PAYMENT_METHOD_FIELD_NAME } from 'app/Pages/Quote/Profile/body/Issuing/consts';
import { TQuoteType } from 'app/Pages/Quote/models';
import { InputDate, Documents } from './components';

const INSURED_PERSONS_FIELD_INSURER_TAGS = ['axa', 'balcia'];

export const renderAdditionalProperties = ({
  type,
  form,
  formChange,
  schemaProps,
  fullForm,
  renderCustomField,
  customAdditionalProperties,
  customRequiredFields,
  insurerTag
}: {
  type?: TQuoteType,
  form: Record<string, any>,
  formChange: (data: Record<string, any>) => void,
  schemaProps: JSONSchema,
  fullForm: Record<string, any>,
  renderCustomField?: (form: Record<string, any>, formChange: (data: Record<string, any>) => void, schemaPropKey: string, prop: JSONSchemaProp, insurerTag?: string) => JSX.Element,
  customAdditionalProperties?: JSONSchema,
  customRequiredFields?: string[],
  insurerTag?: string,
}) => {
  const formProperties: JSX.Element[] = [];

  const handleFormChange = (name: string, value: any, parentName?: string) => {

    const touchedForm = { ...form };
    if (parentName) {
      if (touchedForm[parentName]) {
        touchedForm[parentName][name] = value;
      } else {
        touchedForm[parentName] = {
          [name]: value
        };
      }
    } else {
      touchedForm[name] = value;
    }

    formChange(touchedForm);
  };

  if (customAdditionalProperties || schemaProps.additionalOptions?.properties) {
    let additionalProperties = customAdditionalProperties || schemaProps.additionalOptions.properties || {};
    const requiredFields = customRequiredFields || schemaProps.additionalOptions?.required || [];

    if (Object.keys(additionalProperties)?.length) {
      additionalProperties = sortSchemaPropertiesByPriority(additionalProperties);
    }

    Object.keys(additionalProperties).forEach(schemaPropKey => {

      const prop = additionalProperties[schemaPropKey];

      if (!checkShowIf(prop, fullForm, form)) {
        return;
      }

      if (shouldRenderCustomField(schemaPropKey, type, insurerTag, prop.custom_ui_field) && renderCustomField) {
        if (shouldHideInspection(schemaPropKey, form[INSPECTION_FIELD_NAME], insurerTag)) {
          return;
        } else {
          formProperties.push(
            <ContentBodyRow key={schemaPropKey}>
              <SectionHeadline>{translate({ key: prop.title_translation_key, fallback: schemaPropKey })} {(requiredFields?.includes(schemaPropKey) || prop.required_if ? showIf(prop.required_if, form, fullForm) : false)? <RequiredMark /> : null}</SectionHeadline>
              {renderCustomField(form, formChange, schemaPropKey, prop, insurerTag)}
            </ContentBodyRow>
          );
        }
      } else if (prop.object_type) {
        switch (prop.object_type) {
          case 'address':
            formProperties.push(
              <ContentBodyRow key={schemaPropKey}>
                <SectionHeadline>{translate({ key: prop.title_translation_key, fallback: schemaPropKey })} {(requiredFields?.includes(schemaPropKey) || prop.required_if ? showIf(prop.required_if, form, fullForm) : false) ? <RequiredMark /> : null}</SectionHeadline>
                {renderAddressObject({ form: form[schemaPropKey], properties: prop.properties as JSONSchema, handleFormChange, parentName: schemaPropKey })}
              </ContentBodyRow>
            );
            break;
          case 'participant':
            formProperties.push(
              <ContentBodyRow key={schemaPropKey}>
                <SectionHeadline>{translate({ key: prop.title_translation_key, fallback: schemaPropKey })} {(requiredFields?.includes(schemaPropKey) || prop.required_if ? showIf(prop.required_if, form, fullForm) : false) ? <RequiredMark /> : null}</SectionHeadline>
                {renderParticipantObject({ form: form[schemaPropKey], fullForm: fullForm, required: prop.required || [], properties: prop.properties as JSONSchema, handleFormChange, parentName: schemaPropKey })}
              </ContentBodyRow>
            );
        }
      } else {
        switch (prop.type) {
          case 'object':
            formProperties.push(
              renderObject(schemaPropKey, prop, (requiredFields?.includes(schemaPropKey) || prop.required_if ? showIf(prop.required_if, form, fullForm) : false), form, fullForm, handleFormChange)
            );
            break;
          case 'array':
            formProperties.push(
              <ContentBodyRow key={schemaPropKey} className={`form-section--${schemaPropKey}`}>
                <SectionHeadline>{translate({ key: prop.title_translation_key, fallback: schemaPropKey })} {(requiredFields?.includes(schemaPropKey) || prop.required_if ? showIf(prop.required_if, form, fullForm) : false) ? <RequiredMark /> : null}</SectionHeadline>
                <ControlsWrapper>
                  {renderArray(form, fullForm, schemaPropKey, prop.items as JSONSchemaProp, handleFormChange)}
                </ControlsWrapper>
              </ContentBodyRow>
            );
            break;
          default:
            formProperties.push(
              <ContentBodyRow key={schemaPropKey}>
                {renderFormControl(form, fullForm, schemaPropKey, prop, handleFormChange, (requiredFields?.includes(schemaPropKey) || prop.required_if ? showIf(prop.required_if, form, fullForm) : false))}
              </ContentBodyRow>
            );
        }
      }

    });
  }

  return formProperties;
};

const shouldRenderCustomField = (
  schemaPropKey: string,
  productType?: TQuoteType,
  insurerTag?: string,
  isCustomUIField?: boolean,
) => ((productType === 'travel' && schemaPropKey === INSURED_PERSONS_FIELD_NAME)
    && (insurerTag && INSURED_PERSONS_FIELD_INSURER_TAGS.includes(insurerTag)))
    || schemaPropKey === PAYMENT_METHOD_FIELD_NAME
    || (insurerTag === 'allianz' && schemaPropKey === INSPECTION_FIELD_NAME)
    || isCustomUIField;

const shouldHideInspection = (
  schemaPropKey: string,
  inspectionForm: Partial<Record<string, any>['inspection']>,
  insurerTag?: string,
) => (insurerTag === 'allianz' && schemaPropKey === INSPECTION_FIELD_NAME) && !(inspectionForm?.photoUrl || inspectionForm?.vehicle?.length);

export const renderArray = (
  form: Record<string, any>,
  fullForm: Record<string, any>,
  name: string,
  prop: JSONSchemaProp,
  handleFormChange: (name: string, value: any, parentName?: string) => void,
  parentName?: string,
) => {

  const handleArrayItemChange = (key: string, value: any, index: number) => {
    const touchedForm = { ...form };
    touchedForm[name][index][key] = value;
    handleFormChange(name, touchedForm[name], parentName);
  };

  const handleMultiselectChange = (key: string) => {
    const touchedForm = { ...form };

    if (!Array.isArray(touchedForm[name])) {
      touchedForm[name] = [];
    }

    const itemIndex = touchedForm[name] ? touchedForm[name].findIndex((i: string) => i === key) : -1;

    if (itemIndex === -1) {
      touchedForm[name].push(key);
    } else {
      touchedForm[name].splice(itemIndex, 1);
    }

    handleFormChange(name, touchedForm[name], parentName);
  };

  const handleAddArrayItem = () => {
    const touchedForm = { ...form };
    touchedForm[name].push({});

    handleFormChange(name, touchedForm[name], parentName);
  };

  const handleRemoveArrayItem = (index: number) => {
    const touchedForm = { ...form };
    touchedForm[name].splice(index, 1);

    handleFormChange(name, touchedForm[name], parentName);
  };

  if (prop.enum) {
    return prop.enum.map((item) => (
      <Checkbox
        key={item}
        name={item}
        label={translate({ key: prop.enum_translation_keys ? prop.enum_translation_keys[item] : undefined, fallback: item })}
        checked={form && Array.isArray(form[name]) ? !!form[name].includes(item) : false}
        handleChange={(key) => handleMultiselectChange(key as string)}
      />
    ));
  }

  return (
    <>
      {Array.isArray(form[name]) ? form[name].map((item: Record<string, any>, index: number) => {
        if (prop.object_type) {
          switch (prop.object_type) {
            case 'address':
              return renderArrayItem(renderAddressObject({ form: item, properties: prop.properties as JSONSchema, handleFormChange: (name, value) => handleArrayItemChange(name, value, index) }), () => handleRemoveArrayItem(index));
            case 'participant':
              return renderArrayItem(renderParticipantObject({ form: item, fullForm: fullForm, required: prop.required || [], properties: prop.properties as JSONSchema, handleFormChange: (name, value) => handleArrayItemChange(name, value, index) }), () => handleRemoveArrayItem(index));
            default:
              return <></>;
          }
        } else {
          propTypeSwitch(prop, item, index, form, fullForm, name, handleRemoveArrayItem, handleArrayItemChange);
        }
      }) : []}
      {prop.disable_add ? null : (
        <Button onClick={handleAddArrayItem}>{translate({ key: 'button.add_item' })}</Button>
      )}
    </>
  );

};

export const propTypeSwitch = (
  prop: JSONSchemaProp,
  item: Record<string, any>,
  index: number,
  form: Record<string, any>,
  fullForm: Record<string, any>,
  name: string,
  handleRemoveArrayItem: (index: number) => void,
  handleArrayItemChange: (key: string, value: any, index: number) => void,
) => {
  switch (prop.type) {
    case 'object':
      const requiredFields = prop.required || [];
      const sortedProperties = prop.properties ? sortSchemaPropertiesByPriority(prop.properties) : null;

      return renderArrayItem(sortedProperties ? Object.keys(sortedProperties).map(subPropKey => {
        // @ts-ignore
        const subProp = sortedProperties[subPropKey];
        switch (subProp.object_type) {
          case 'address':
            return (
              <ContentBodyRow key={subPropKey}>
                <SectionHeadline>{translate({ key: subProp.title_translation_key, fallback: subPropKey })} {(requiredFields?.includes(subPropKey) || prop.required_if ? showIf(prop.required_if, form, fullForm) : false) ? <RequiredMark /> : null}</SectionHeadline>
                {renderAddressObject({
                  form: item[subPropKey],
                  properties: subProp.properties as JSONSchema,
                  handleFormChange: (name, value) => handleArrayItemChange(subPropKey, {
                    ...item[subPropKey],
                    [name]: value
                  }, index)
                })}
              </ContentBodyRow>
            );
          case 'participant':
            return (
              <ContentBodyRow key={subPropKey}>
                <SectionHeadline>{translate({ key: subProp.title_translation_key, fallback: subPropKey })} {(requiredFields?.includes(subPropKey) || prop.required_if ? showIf(prop.required_if, form, fullForm) : false) ? <RequiredMark /> : null}</SectionHeadline>
                {renderParticipantObject({
                  form: item,
                  fullForm: fullForm,
                  required: subProp.required || [],
                  properties: subProp.properties as JSONSchema,
                  handleFormChange: (name, value) => handleArrayItemChange(name, {
                    ...item[subPropKey],
                    [name]: value
                  }, index)
                })}
              </ContentBodyRow>
            );
          default:
            if (checkShowIf(subProp, fullForm, item)) {
              return (
                <ContentBodyRow key={subPropKey}>
                  {renderFormControl(item, fullForm, subPropKey, subProp, (name, value) => handleArrayItemChange(name, value, index), requiredFields.includes(subPropKey))}
                </ContentBodyRow>
              );
            } else {
              return <></>;
            }
        }
      }) : [], prop.disable_delete ? undefined : () => handleRemoveArrayItem(index));
    default:
      return renderArrayItem(renderFormControl(item, fullForm, name, prop, (name, value) => handleArrayItemChange(name, value, index)), prop.disable_delete ? undefined : () => handleRemoveArrayItem(index));
  }
};

export const renderObject = (schemaPropKey: string, prop: JSONSchemaProp, isRequired: boolean, form: Record<string, any>, fullForm: Record<string, any>, formChange: (name: string, value: any, parentName?: string) => void, parentName?: string) => (
  <ContentBodyRow key={schemaPropKey}>
    <SectionHeadline>{translate({ key: prop.title_translation_key, fallback: schemaPropKey })} {isRequired ? <RequiredMark /> : null}</SectionHeadline>
    {prop.properties ? Object.keys(sortSchemaPropertiesByPriority(prop.properties)).map(subPropKey => {
      // @ts-ignore
      const subProp = prop.properties[subPropKey];

      const handleFormChange = (name: string, value: any, schemaPropKey?: string) => {
        const touchedForm = { ...form };

        if (parentName && schemaPropKey) {
          if (touchedForm[schemaPropKey]) {
            touchedForm[schemaPropKey][name] = value;
          } else {
            touchedForm[schemaPropKey] = {
              [name]: value
            };
          }
          formChange(parentName, touchedForm);
        } else {
          formChange(name, value, schemaPropKey);
        }

      };

      return renderFormControl(form[schemaPropKey] || {}, fullForm, subPropKey, subProp, handleFormChange, prop.required?.includes(subPropKey), schemaPropKey);
    }) : []}
  </ContentBodyRow>
);

const renderArrayItem = (itemContent: JSX.Element | JSX.Element[], removeArrayItem?: () => void) => (
  <ArrayItemWrapper>
    <ArrayItemContent>
      {itemContent}
    </ArrayItemContent>
    {removeArrayItem ? (
      <ArrayItemControls>
        <DefaultIcon icon="trash" onClick={removeArrayItem} />
      </ArrayItemControls>
    ) : null}
  </ArrayItemWrapper>
);

export const renderFormControl = (
  form: Record<string, any>,
  fullForm: Record<string, any>,
  name: string,
  prop: JSONSchemaProp,
  handleFormChange: (name: string, value: any, parentName?: string) => void,
  required?: boolean,
  parentName?: string,
) => {

  if (!checkShowIf(prop, fullForm, form)) {
    return <></>;
  }

  if (name === DOCUMENTS_STATEMENTS_NAME) {
    return <Documents
      key={prop.title}
      prop={prop}
      parentName={parentName}
      handleFormChange={handleFormChange}
      form={form}
      otherThanMtplProducts={fullForm.otherThanMtplProducts}
    />;
  }

  if (prop.enum) {
    return <Select
      key={name}
      name={name}
      label={translate({ key: prop.title_translation_key, fallback: name })}
      value={form ? form[name] : ''}
      options={getOptionsFromEnum(prop as JSONSchemaProp) || []}
      required={required}
      placeholder={translate({ key: 'common.select' })}
      handleChange={(key, value) => handleFormChange(key as string, value, parentName)}
      searchable={true}
    />;
  }

  switch (prop.type) {
    case 'boolean':
      return <Checkbox
        key={name}
        name={name}
        //Need ability to process HTML from translations as label
        label={<div dangerouslySetInnerHTML={{ __html: translate({ key: prop.title_translation_key, fallback: name }) }} />}
        checked={form ? !!form[name] : false}
        handleChange={(key, value) => handleFormChange(key as string, value, parentName)}
        disabled={prop.is_statement}
        required={required}
      />;
    case 'object':
      return renderObject(name, prop, !!required, form, fullForm, handleFormChange, parentName);
    case 'array':
      return (
        <ContentBodyRow key={name}>
          <SectionHeadline>{translate({ key: prop.title_translation_key, fallback: name })} {required ? <RequiredMark /> : null}</SectionHeadline>
          <ControlsWrapper>
            {renderArray(form, fullForm, name, prop.items as JSONSchemaProp, handleFormChange, parentName)}
          </ControlsWrapper>
        </ContentBodyRow>
      );
    case 'string':
    default:
      return prop.format === 'date' ? (
        <InputDate
          key={name}
          required={required}
          name={name}
          prop={prop}
          value={form ? form[name] : ''}
          handleFormChange={handleFormChange}
          parentName={parentName}
        />
      ) : (
        <Input
          textarea={prop.format === 'textarea'}
          key={name}
          name={name}
          label={translate({ key: prop.title_translation_key, fallback: name })}
          value={form ? form[name] : ''}
          required={required}
          disabled={prop.read_only}
          handleChange={(key, value: string | number | undefined) => {
            let processedValue = value;
            if (value && prop.type && ['number', 'integer'].includes(prop.type) && Number.isInteger(+(value))) {
              processedValue = +(value);
            }
            handleFormChange(key as string, processedValue, parentName);
          }}
        />
      );
  }
};

const renderAddressObject = ({
  form,
  properties,
  handleFormChange,
  parentName,
} : {
  form: Record<string, any>,
  properties: JSONSchema,
  handleFormChange: (name: string, value: any, parentName?: string) => void,
  parentName?: string,
}) => <FormGroup
  disabled
  items={[
    [
      {
        type: 'input',
        options: {
          name: 'postalCode',
          label: translateTitleInScheme(properties.postalCode),
          value: form?.postalCode,
          styles: {
            flexBasis: `33.3%`
          },
          handleChange: (name: string, value: string) => handleFormChange(name, value, parentName),
        }
      },
      {
        type: 'input',
        options: {
          name: 'city',
          applyCustomValueOnSearch: true,
          customValue: form.city,
          searchable: true,
          label: translateTitleInScheme(properties.city),
          value: form?.city,
          styles: {
            flexBasis: '66.6%'
          },
          handleChange: (name: string, value: string) => handleFormChange(name, value, parentName),
        }
      }
    ],
    [
      {
        type: 'input',
        options: {
          name: 'street',
          label: translateTitleInScheme(properties.street),
          value: form?.street,
          searchable: true,
          styles: {
            flexBasis: '66.6%'
          },
          handleChange: (name: string, value: string) => handleFormChange(name, value, parentName),
        }
      },
      {
        type: 'input',
        options: {
          name: 'houseNumber',
          label: translateTitleInScheme(properties.houseNumber),
          value: form?.houseNumber,
          className: 'address-house-nr',
          styles: {
            flexBasis: `${33.3 / 2}%`,
          },
          handleChange: (name: string, value: string) => handleFormChange(name, value, parentName),
        }
      },
      {
        type: 'input',
        options: {
          name: 'flatNumber',
          label: translateTitleInScheme(properties.flatNumber),
          value: form?.flatNumber,
          styles: {
            flexBasis: `${33.3 / 2}%`
          },
          handleChange: (name: string, value: string) => handleFormChange(name, value, parentName),
        }
      },
    ],
  ]}
/>;

const renderParticipantObject = ({
  form,
  fullForm,
  required,
  properties,
  handleFormChange,
  parentName,
} : {
  form: Record<string, any>,
  fullForm: Record<string, any>,
  required: string[],
  properties: JSONSchema,
  handleFormChange: (name: string, value: any, parentName?: string) => void,
  parentName?: string,
}) => <FormGroup
  items={[
    [
      {
        type: 'input',
        options: {
          name: 'phoneNumber',
          label: translateTitleInScheme(properties.phoneNumber),
          required: (required?.includes('phoneNumber') || properties.phoneNumber.required_if ? showIf(properties.phoneNumber.required_if, form, fullForm) : false),
          value: form.phoneNumber,
          searchable: true,
          handleChange: (name: string, value: string) => handleFormChange(name, value, parentName),
        }
      },
      {
        type: 'input',
        options: {
          name: 'email',
          label: translateTitleInScheme(properties.email),
          required: (required?.includes('email') || properties.email.required_if ? showIf(properties.email.required_if, form, fullForm) : false),
          value: form.email,
          handleChange: (name: string, value: string) => handleFormChange(name, value, parentName),
        }
      },
    ],
  ]}
/>;

export const checkShowIf = (prop: JSONSchemaProp, form: Record<string, any>, localForm?: Record<string, any>) => {
  if (prop.show_if) {
    let isPassed = false;
    let passedRules = 0;
    prop.show_if.fields && prop.show_if.fields.forEach(field => {
      let currentForm = field.use_local_form ? localForm : form;
      const value = leaf(currentForm, field.name);

      passedRules += validateShowIfField(field, value);
    });

    if (prop.show_if.dependency_type === 'all') {
      if (prop.show_if.fields?.length === passedRules) {
        isPassed = true;
      }
    } else if (passedRules > 0) {
      isPassed = true;
    }

    return isPassed;

  } else {
    return true;
  }
};

const leaf: any = (obj: Record<string, any>, path: string) => (path.split('.').reduce((value, el) => value && value[el], obj));
