import styled from 'styled-components';
import { InspectionText } from 'app/Pages/QuoteMotor/Profile/body/Issuing/styles';

export const ArrayItemWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const ArrayItemContent = styled.div`
  flex: 1;
`;

export const ArrayItemControls = styled.div`
 padding-left: 10px;
`;

export const RequiredMark = styled.span`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.primary};
  &:after {
    content: '*'
  }
`;

export const SectionHeadline = styled.h3`
  ${({ theme }) => theme.text.headerH3};
  color: #282828;
  margin-bottom: 12px;
`;

export const ControlsWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
    &:empty {
        display: none;
    }
`;

export const DocumentsSectionHeadline = styled(SectionHeadline)`
  margin-bottom: 0;
`;

export const DocumentsSectionList = styled.ol`
    list-style-type: lower-alpha;
    padding-left: 30px;
    
    li {
        padding-bottom: 10px;
    }
`;

export const DocumentsLinkText = styled(InspectionText)`
  
`;
