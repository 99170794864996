import styled from 'styled-components';

export const Message = styled.div`
  display: flex;
  gap: 16px;
  color: ${({ theme }) => theme.colors.black};
  line-height: 25px;
  padding: 15px;
  margin-top: 10px;
  border: 1px solid ${({ theme }) => theme.text.black};
  border-radius: ${({ theme }) => theme.borderRadius};
  ${({ theme }) => theme.text.normal};
`;
